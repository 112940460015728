import { useState, useEffect } from "react";

function getWindowDarkMode() {
  const result =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  return result;
}

export default function useWindowDarkMode() {
  const [darkMode, setDarkMode] = useState(getWindowDarkMode());

  useEffect(() => {
    const handleDarkMode = (event) => {
      const colorScheme = event.matches ? "dark" : "light";
      setDarkMode(colorScheme);
      window.location.reload();
    };

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", handleDarkMode);
    return () => window.removeEventListener("change", handleDarkMode);
  }, []);

  return darkMode;
}
