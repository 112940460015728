import axios from "axios";
import { toast } from "react-toastify";
import { normalizeSearchParams } from "../utils/helpers";
// export const exampleEncrypted = async (req) =>
//   (
//     await axios.post(
//       `${atob(window["webConfig"].urls.someUrl + "==")}`,
//       req
//     )
//   ).data;

const apiUrl = process.env.REACT_APP_apiUrl || "/proxy/dmx";

export const searchReintegrationApp = async (req) =>
  (await axios.post(`${apiUrl}/api/ReintegrationApplication/Search`, req)).data;

export const createReintegrationApp = async (req) =>
  (await axios.post(`${apiUrl}/api/ReintegrationApplication`, req)).data;

export const createCitizenshipApplication = async (req) =>
  (await axios.post(`${apiUrl}/api/CitizenshipApplication`, req)).data;

export const createResidenceApplication = async (req) =>
  (await axios.post(`${apiUrl}/api/ResidenceApplication`, req)).data;

export const sendVideoConferenceApp = async (req) =>
  (await axios.post(`${apiUrl}/api/VideoConferenceParticipant`, req)).data;

export const subscribeEmail = async (req) =>
  (await axios.post(`${apiUrl}/api/Subscriber`, req)).data;

export const checkStatusAcceptedByReintegrationId = async (reintegrationId) =>
  (
    await axios.get(
      `${apiUrl}/api/ReintegrationApplication/CheckStatusAcceptedByReintegrationId/${reintegrationId}`
    )
  ).data;

export const getCountryList = async () =>
  (await axios.get(`${apiUrl}/api/country/getall`)).data;

// CRM API

const options = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "",
  },
};

const crmApiUrl =
  process.env.REACT_APP_crmApiUrl || "https://ipcall-api.reintegration.gov.az";
const getCrmApiUrl = (apiName) => `${crmApiUrl}/${apiName}`;

// PUBLIC METHODS
export const crmPublicApiCreate = async (apiName, request, token) => {
  try {
    const response = await axios.post(getCrmApiUrl(apiName), request, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    if (
      error?.response?.status == 401 &&
      error?.response?.data?.error?.message
    ) {
      console.log("Permission denied: ", error.response.data.error.message);
      toast.error("Permission denied", error.response.data.error.message, {
        timeOut: 15000,
      });
    }
    return error;
  }
};

export const crmPublicApiUpdate = async (apiName, request, token) => {
  try {
    const objId = request?._id || request?.id;

    const response = await axios.put(
      `${getCrmApiUrl(apiName)}/${objId}`,
      request,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (
      error?.response?.status == 401 &&
      error?.response?.data?.error?.message
    ) {
      console.log("Permission denied: ", error.response.data.error.message);
      toast.error("Permission denied", error.response.data.error.message, {
        timeOut: 15000,
      });
    }
    return error;
  }
};

export const crmPublicApiGetById = async (
  apiName = "",
  id = "",
  token,
  params
) => {
  try {
    const response = await axios.get(`${getCrmApiUrl(apiName)}/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: params,
    });
    return response;
  } catch (error) {
    if (
      error?.response?.status == 401 &&
      error?.response?.data?.error?.message
    ) {
      console.log("Permission denied: ", error.response.data.error.message);
      toast.error("Permission denied", error.response.data.error.message, {
        timeOut: 15000,
      });
    }
    return error;
  }
};

export const crmPublicApiGetUrlById = async (apiName = "", urlId = "") => {
  try {
    const response = await axios.get(`${getCrmApiUrl(apiName)}/${urlId}`);
    return response;
  } catch (error) {
    if (
      error?.response?.status == 401 &&
      error?.response?.data?.error?.message
    ) {
      console.log("Permission denied: ", error.response.data.error.message);
      toast.error("Permission denied", error.response.data.error.message, {
        timeOut: 15000,
      });
    }
    return error;
  }
};

// MAIN METHODS
export const crmApiGetList = async (apiName = "", params = {}) => {
  try {
    const resolvedParams = normalizeSearchParams({
      ...params,
    });
    const response = await axios.get(getCrmApiUrl(apiName), {
      headers: options.headers,
      params: resolvedParams,
    });
    return response;
  } catch (error) {
    if (
      error?.response?.status == 401 &&
      error?.response?.data?.error?.message
    ) {
      console.log("Permission denied: ", error.response.data.error.message);
      toast.error("Permission denied: " + error.response.data.error.message);
    }
    return error;
  }
};

export const crmApiCreate = async (apiName, request, option) => {
  try {
    const response = await axios.post(
      getCrmApiUrl(apiName),
      request,
      (option = options)
    );
    return response;
  } catch (error) {
    if (
      error?.response?.status == 401 &&
      error?.response?.data?.error?.message
    ) {
      console.log("Permission denied: ", error.response.data.error.message);
      toast.error("Permission denied: " + error.response.data.error.message);
    }
    return error;
  }
};

export const crmApiUpdate = async (apiName, request, option) => {
  try {
    const objId = request?._id || request?.id;
    const response = await axios.put(
      `${getCrmApiUrl(apiName)}/${objId}`,
      request,
      (option = options)
    );
    return response;
  } catch (error) {
    if (
      error?.response?.status == 401 &&
      error?.response?.data?.error?.message
    ) {
      console.log("Permission denied: ", error.response.data.error.message);
      toast.error("Permission denied: " + error.response.data.error.message);
    }
    return error;
  }
};

export const crmApiGetById = async (apiName = "", id = "", params) => {
  try {
    const response = await axios.get(`${getCrmApiUrl(apiName)}/${id}`, {
      ...options,
      params: params,
    });
    return response;
  } catch (error) {
    if (
      error?.response?.status == 401 &&
      error?.response?.data?.error?.message
    ) {
      console.log("Permission denied: ", error.response.data.error.message);
      toast.error("Permission denied: " + error.response.data.error.message);
    }
    return error;
  }
};

export const crmApiDelete = async (apiName = "", id = "") => {
  try {
    const response = await axios.delete(
      `${getCrmApiUrl(apiName)}/${id}`,
      options
    );
    return response;
  } catch (error) {
    if (
      error?.response?.status == 401 &&
      error?.response?.data?.error?.message
    ) {
      console.log("Permission denied: ", error.response.data.error.message);
      toast.error("Permission denied: " + error.response.data.error.message);
    }
    return error;
  }
};

export const crmApiGetModelSchema = async (modelName) => {
  try {
    const response = await axios.get(`${crmApiUrl}/schema/get/${modelName}`, {
      ...options,
    });
    return response;
  } catch (error) {
    if (
      error?.response?.status == 401 &&
      error?.response?.data?.error?.message
    ) {
      console.log("Permission denied: ", error.response.data.error.message);
      toast.error("Permission denied: " + error.response.data.error.message);
    }
    return error;
  }
};
