import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentLang,
  translate,
  use,
} from "../../services/translation/translation.service";
import { setGlobalLang } from "../../store/chatStore";
import useWindowDarkMode from "../../hooks/useWindowDarkMode";

import phoneIconDark from "../../assets/img/phone.png";
import phoneIconWhite from "../../assets/img/phone-icon-white.png";

import faqIconDark from "../../assets/img/faq2.png";
import faqIconWhite from "../../assets/img/faq-white.png";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function RegistrationHeader({ path }) {
  const darkMode = useWindowDarkMode();
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const { globalLang } = useSelector((state) => state.chat);
  const [lang, setLang] = useState(getCurrentLang() || "az");
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [appForm, setAppForm] = useState("");

  useEffect(() => {
    setLang(globalLang);
  }, [globalLang]);

  useEffect(() => {
    if (darkMode) {
      const htmlList = document.getElementsByClassName("box-left");
      if (htmlList) {
        const arrayList = [...htmlList];
        arrayList.forEach((element) => {
          element.classList.add("custom-dark-style");
        });
      }
    }

    const menuTrigEl = document.getElementById("mobMenu");
    if (menuTrigEl) {
      menuTrigEl.classList.add("reg-menu-trigger-style");
      menuTrigEl.classList.remove("home-menu-trigger-style");
    }
  }, []);

  const toggle = () => setModal(!modal);

  const sendVideoApp = async () => {
    try {
      setLoading(true);
      console.log("appForm", appForm);
      setTimeout(() => {
        toast(translate("Müraciətiniz qeydə alındı"));
        toggle();
        setLoading(false);
      }, 2000);
    } catch (error) {
      setLoading(false);
      console.log("sendVideoApp error", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAppForm({ ...appForm, [name]: value });
  };

  function changeLang(lang) {
    localStorage.removeItem("TRANSLATIONS");
    use(lang);
    setLang(lang);
    dispatch(setGlobalLang(lang));
    window.location.reload();
  }

  const handleMenu = () => {
    let el1 = document.getElementById("mobMenu");
    let el2 = document.getElementById("mnav");

    if (el1 && el2 && el1.classList.contains("active")) {
      el1.classList.remove("active");
      el2.style.display = "none";
      // el2.style.background = "transparent";
    } else {
      el1.classList.add("active");
      el2.style.display = "flex";
      // el2.style.background = "#fff";
    }
  };

  return (
    <div>
      <header
        className="header-area header-sticky wow slideInDown"
        data-wow-duration="0.75s"
        data-wow-delay="0s"
        style={darkMode ? { background: "#232223" } : {}}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="main-nav">
                <Link to={"/"} className="logo intro">
                  <img
                    src="/assets/images/gerb.svg"
                    alt="Mig App"
                    style={{ width: "25%" }}
                  />
                  <div className="content__container">
                    <h5 style={darkMode ? { color: "#fff" } : {}}>
                      {translate("reg_header_title")}
                    </h5>
                  </div>
                  <img
                    src="/assets/images/logo.png"
                    alt="Mig App"
                    className="mig-logo"
                    style={{ width: "100%" }}
                  />
                </Link>
                <ul
                  className="nav topbuttons"
                  id="mnav"
                  style={
                    darkMode
                      ? { background: "#232223" }
                      : { background: width > 992 ? "transparent" : "#fff" }
                  }
                >
                  <li style={darkMode ? { background: "#232223" } : {}}>
                    <a
                      href="tel:+994125260919"
                      // data-bs-toggle="modal"
                      // data-bs-target="#LangModal"
                      // className=""
                    >
                      <img
                        src={darkMode ? phoneIconWhite : phoneIconDark}
                        alt="Mig App"
                        style={{
                          width: darkMode ? 30 : 39,
                          marginRight: 10,
                          marginTop: -5,
                        }}
                      />
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="https://dmx919callcenter.3cx.az:5001/meet/4444"
                      target={"_blank"}
                      className="head-buttons video-button"
                      // onClick={toggle}
                    >
                      <i className="fa fa-video"></i>
                    </a>
                  </li> */}
                  <li style={darkMode ? { background: "#232223" } : {}}>
                    <a
                      href="https://wa.me/+994705270919"
                      className="head-buttons whatsapp-button"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <i className="fab fa-whatsapp"></i>
                    </a>
                  </li>
                  {/* <li>
                    <a href="#" className="head-buttons eye-button">
                      <i className="fa fa-eye"></i>
                    </a>
                  </li> */}
                  <li style={darkMode ? { background: "#232223" } : {}}>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#faqModal"
                    >
                      {/* <i className="fas fa-question"></i> */}
                      <img
                        src={darkMode ? faqIconWhite : faqIconDark}
                        alt="Mig App"
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: 40,
                          marginTop: -8,
                        }}
                      />
                    </a>
                  </li>
                  <li
                    className="lang-li"
                    style={darkMode ? { background: "#232223" } : {}}
                  >
                    <select
                      className="lang-select"
                      data-width="fit"
                      value={lang}
                      onChange={(e) => changeLang(e.target.value)}
                    >
                      <option value={"az"}>{translate("az")}</option>
                      <option value={"en"}>{translate("en")}</option>
                      <option value={"ru"}>{translate("ru")}</option>
                      <option value={"am"}>{translate("am")}</option>
                    </select>
                  </li>
                </ul>
                <a
                  id="mobMenu"
                  className="menu-trigger"
                  onClick={handleMenu}
                  style={darkMode ? { background: "#fff" } : {}}
                >
                  <span>{translate("menu")}</span>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <Modal isOpen={modal} toggle={toggle} backdrop="static">
        <ModalHeader toggle={toggle}>
          {translate("video_app_title")}
        </ModalHeader>
        <ModalBody>
          <div className="container">
            {loading && <progress style={{ width: "100%" }} />}
            <Form>
              <div className="row">
                <div className="col-6">
                  <FormGroup>
                    <Label>{translate("firstName")}</Label>
                    <Input
                      name="firstName"
                      placeholder=""
                      type="text"
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>{translate("email")}</Label>
                    <Input
                      name="email"
                      placeholder=""
                      type="email"
                      onChange={handleChange}
                    />
                  </FormGroup>
                </div>
                <div className="col-6">
                  <FormGroup>
                    <Label>{translate("lastName")}</Label>
                    <Input
                      name="lastName"
                      placeholder=""
                      type="text"
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>{translate("lang")}</Label>
                    <Input name="lang" type="select" onChange={handleChange}>
                      <option>{translate("azerbaijan")}</option>
                      <option>{translate("english")}</option>
                      <option>{translate("russian")}</option>
                      <option>{translate("armenian")}</option>
                    </Input>
                  </FormGroup>
                </div>
              </div>
            </Form>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={sendVideoApp} disabled={loading}>
            {translate("send")}
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            {translate("close")}
          </Button>
        </ModalFooter>
      </Modal>
      {path !== "blog" && (
        <div id="clients" className="the-clients clients-box-asphalt">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 d-flex  align-items-center clients-box">
                <h4>
                  <img
                    src="/assets/images/soft-icon.png"
                    className="img-fluid img-responsive"
                    alt="Mig App"
                  />{" "}
                  {translate("reception_applications")}
                </h4>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RegistrationHeader;
