import React, { useState } from "react";
import { toast } from "react-toastify";
// import useWindowDimensions from "../../hooks/useWindowDimensions";
import { subscribeEmail } from "../../services/api.service";
import { translate } from "../../services/translation/translation.service";
import { validateEmail } from "../../utils/common";

function RegistrationFooter() {
  // const { width } = useWindowDimensions();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const subscribeMethod = async () => {
    try {
      if (!email) {
        toast.info(translate("please_add_valid_email"));
        return;
      } else if (email && !validateEmail(email)) {
        toast.info(translate("please_add_valid_email"));
        return;
      }
      setLoading(true);
      const res = await subscribeEmail({ email });
      if (res?.success) {
        toast.success(translate("successfully_subscribed"));
        setEmail("");
      } else if (res.errors.length) {
        toast.info(
          translate(res.errors[0]?.message || "email_allready_in_use")
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(translate("An error occurred"));
      console.log("subscribeMethod error", error);
    }
  };

  return (
    <footer
      id="newsletter"
      // style={
      //   width > 1500
      //     ? {
      //         position: "absolute",
      //         bottom: 0,
      //       }
      //     : null
      // }
    >
      <div className="container">
        <div className="row d-flex align-items-center bg-footer ">
          <div className="col-lg-12 d-flex justify-content-center bottom-contact text-white pt-2 pb-2">
            <span>
              <a
                style={{ color: "#fff" }}
                href="tel:+994125260919"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-phone" style={{ color: "red" }}></i>{" "}
                (+99412) 526 09 19
              </a>
            </span>
            <span>
              <a
                href="https://wa.me/+994705270919"
                style={{ color: "#fff" }}
                target="_blank"
                rel="noreferrer"
              >
                <i
                  className="fab fa-whatsapp"
                  style={{ color: "greenyellow" }}
                ></i>{" "}
                (+99470) 527 09 19
              </a>
            </span>
            <span>
              <i className="fas fa-map "></i> {translate("reg_footer_address")}
            </span>
            <span>
              <i className="fas fa-envelope"></i> info@reintegration.gov.az
            </span>
          </div>
        </div>

        <div className="row mt-2 ">
          <div className="col-md-6 mb-2">
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://president.az/"
                  className="fontSizeChanger"
                >
                  {translate("reg_footer_menu_president")}
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://mfa.gov.az/az"
                  className="fontSizeChanger"
                >
                  {translate("reg_footer_menu_mfa")}
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://www.migration.gov.az/"
                  className="fontSizeChanger"
                >
                  {" "}
                  {translate("reg_footer_menu_migration")}{" "}
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://migrationto.az/"
                  className="fontSizeChanger"
                >
                  {translate("reg_footer_menu_migrationto")}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-3 offset-md-3">
            <div id="subscription">
              <label className="fontSizeChanger">
                {translate("Reinteqrasiya haqqında məlumatlı olun")}
              </label>
              <div className="input-group input-group-sm box-subs">
                <input
                  disabled={loading}
                  type="email"
                  placeholder={translate("email")}
                  required="required"
                  className="form-control input-sm subsEmail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    className="subsBtn"
                    onClick={subscribeMethod}
                    disabled={loading}
                  >
                    {translate("subscribe")}
                  </button>
                </div>
              </div>
              <small className="text-danger emailAlert"></small>
            </div>
          </div>
        </div>

        <div className="row mt-2 social-footer d-sm-none">
          {/* <div className="col-12">
            <section className="mb-2 mt-2">
              <a className="head-buttons facebook m-1" href="#!" role="button">
                <i className="fab fa-facebook-f"></i>
              </a>

              <a className="head-buttons twitter m-1" href="#!" role="button">
                <i className="fab fa-twitter"></i>
              </a>

              <a className="head-buttons instagram m-1" href="#!" role="button">
                <i className="fab fa-instagram"></i>
              </a>

              <a className="head-buttons whatsapp m-1" href="#!" role="button">
                <i className="fab fa-whatsapp"></i>
              </a>

              <a className="head-buttons youtube m-1" href="#!" role="button">
                <i className="fab fa-youtube"></i>
              </a>
            </section>
          </div> */}
        </div>

        <div className="row">
          <div className="col-lg-12">
            <hr className="hrb" />
            <div className="copyright-text">
              <div
                className="float-start text-left text-muted"
                style={{ float: "left" }}
              >
                <small>
                  © {new Date().getFullYear()} {translate("reg_footer_dmx")}
                </small>
              </div>
              <div
                className="float-end text-right text-muted"
                style={{ float: "left" }}
              >
                {/* <a href="#" className="text-muted">
                  <small>{translate("İstifadəçi qaydaları")}</small>
                </a>
                |
                <a href="#" className="text-muted">
                  <small>{translate("Məxfilik siyasəti")}</small>
                </a>
                | */}

                <small>{translate("Bütün hüquqlar qorunur")}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default RegistrationFooter;
