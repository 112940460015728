import { generateUUID } from "./helpers";

export const weekDays = {
  az: ["B", "BE", "ÇA", "Ç", "CA", "C", "Ş"],
  en: ["SU", "MO", "TU", "WE", "TH", "FR", "SA"],
  ru: ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"],
  am: ["ԿԻՐ", "ԵՐԿ", "ԵՐԵ", "ՉՈՐ", "ՀԻՆ", "ՈՒՐ", "ՇԱԲ"],
};

export const months = {
  az: [
    "Yan",
    "Fev",
    "Mar",
    "Apr",
    "May",
    "İyn",
    "İyl",
    "Avq",
    "Sen",
    "Okt",
    "Noy",
    "Dek",
  ],
  en: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  ru: [
    "Янв",
    "Фев",
    "Maр",
    "Aпр",
    "Maй",
    "Июн",
    "Июл",
    "Авг",
    "Сен",
    "Oкт",
    "Нoя",
    "Дек",
  ],
  am: [
    "Հնվ",
    "Փտրվ",
    "Մրտ",
    "Ապր",
    "Մյս",
    "Հնս",
    "Հուլ",
    "Օգստ",
    "Սեպտ",
    "Հոկ",
    "Նոյ",
    "Դեկ",
  ],
};

export const docTypes = {
  NotSet: "0",
  BirthCertificate: "1",
  Pasport: "3",
  DrivingLicense: "2",
  Other: "4",
  NoDocuments: "5",
};

export const docTypesNumber = {
  0: "NotSet",
  1: "BirthCertificate",
  2: "DrivingLicense",
  3: "Pasport",
  4: "Other",
};

export const contactTypes = {
  NotSet: 0,
  Email: 1,
  Telephone: 2,
  WhatsApp: 3,
};

export const contactTypesNumber = {
  0: "NotSet",
  1: "Email",
  2: "Telephone",
  3: "WhatsApp",
};

export const parentTypesNumber = {
  0: "NotSet",
  1: "Father",
  2: "Mother",
};

export const parentTypes = {
  NotSet: 0,
  Father: 1,
  Mother: 2,
};

export const statuses = {
  InProcess: 1,
  Accepted: 2,
  Declined: 3,
};

export const statusesNumber = {
  1: "app_received",
  2: "app_under_review",
  3: "initial_regstration",
};

// export const responseStatusCodes = [
//   (NotSet = 0),
//   Ok,
//   Error,
//   NoDataFound,
//   TokenExpired,
//   FailedTokenVerification,
//   VerificationNotConfirmed,
//   AccessDenied,
//   InValidRequest,
//   EmailIsAlreadyInUse,
//   InValidCaptcha,
//   DbError,
//   EmailCouldNotBeSent,
//   EmailAlreadyConfirmed,
//   FileError,
//   FileSizeError,
// ];

export const storageKeys = {
  CHAT_MESSAGES_KEY: "CHAT_MESSAGES_KEY",
  MENU_ITEMS_KEY: "MENU_ITEMS_KEY",
  APP_CONFIG_KEY: "APP_CONFIG_KEY",
  LAST_SELECTED_LANGUAGE_KEY: "LAST_SELECTED_LANGUAGE",
  TRANSLATIONS_KEY: "TRANSLATIONS",
};

export const crmApiNames = {
  translation: "translation",
  appSetting: "app-setting",
  chatQueues: "chat-queue",
  chatRates: "chat-rates",
  chatUsers: "chat-users",
  chatHistory: "chat-history",
  chatBotQuestions: "chat-bot-questions",
  reintegrationNews: "reintegration-news",
};

export const socketEvents = {
  createRoom: "createRoom",
  joinRoom: "joinRoom",
  chatMessage: "chatMessage",
  chatHistory: "chatHistory",
  chatMembers: "chatMembers",
  chatCreated: "chatCreated",
  connect: "connect",
  disconnect: "disconnect",
  joinPrivateRoom: "joinPrivateRoom",
  internalUserLogin: "internalUserLogin",
  currentInternalUsers: "currentInternalUsers",
  includeNewChatMembers: "includeNewChatMembers",
  createNewChat: "createNewChat",
  closeChat: "closeChat",
  chatClosed: "chatClosed",
  rateChat: "rateChat",
};

export const emptyUserModel = () => {
  return {
    type: "user",
    userId: generateUUID(),
    name: "web_user_" + generateUUID(),
  };
};
