import React from "react";
import { LoadCanvasTemplate } from "react-simple-captcha";
import { translate } from "../services/translation/translation.service";

function ReactSimpleCaptcha({ id }) {
  return (
    <div className="react-simple-captcha">
      <input
        placeholder={translate("enter_code")}
        id={id || "user_captcha_input"}
        name="user_captcha_input"
        type="text"
        className="form-control rsc-input"
        style={{ maxWidth: 120, minWidth: 120 }}
      />
      <LoadCanvasTemplate reloadText={translate("reload")} />
    </div>
  );
}

export default ReactSimpleCaptcha;
