import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import BlockUi from "react-block-ui";
import { useSelector } from "react-redux";
import DatePicker from "react-multi-date-picker";
// import { loadCaptchaEnginge, validateCaptcha } from "react-simple-captcha";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import {
  months,
  // docTypes,
  // docTypesNumber,
  parentTypes,
  parentTypesNumber,
  weekDays,
} from "../../utils/constants";
import { CitizenshipAppModel } from "../../utils/models";
import {
  getCurrentLang,
  translate,
} from "../../services/translation/translation.service";
import {
  checkStatusAcceptedByReintegrationId,
  createCitizenshipApplication,
} from "../../services/api.service";
// import ReactSimpleCaptcha from "../ReactSimpleCaptcha";
import { convertBase64, getCountryNameByLang } from "../../utils/common";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import DocumentView from "../DocumentView";

function CitizenshipAppForm() {
  const [loading, setLoading] = useState(false);
  const [reintegrationNumber, setReintegrationNumber] = useState("");
  const [reintegrationResult, setReintegrationResult] = useState(false);
  const [notifyModal, setNotifyModal] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [citizenshipsDocs, setCitizenshipsDocs] = useState([]);
  const [citizenshipsDocsError, setCitizenshipsDocsError] = useState("");
  const [otherDocsAttachmentList, setOtherDocsAttachmentList] = useState([]);
  const [otherDocsAttachmentListError, setOtherDocsAttachmentListError] =
    useState("");
  const [arDocsAttachmentList, setArDocsAttachmentList] = useState([]);
  const [arDocsAttachmentListError, setArDocsAttachmentListError] =
    useState("");
  const [
    birthCertificateDocsAttachmentList,
    setBirthCertificateDocsAttachmentList,
  ] = useState([]);
  const [
    birthCertificateDocsAttachmentListError,
    setBirthCertificateDocsAttachmentListError,
  ] = useState("");

  const [parentType, setParentType] = useState("");
  const [parentFirstName, setParentFirstName] = useState("");
  const [parentLastName, setParentLastName] = useState("");
  const [parentDocs, setParentDocs] = useState([]);
  const [parentDocsError, setParentDocsError] = useState("");
  // const [selectedDocType, setsSelectedDocType] = useState(0);
  // const [selectedDocAttachments, setSelectedDocAttachments] = useState([]);

  const { width } = useWindowDimensions();
  const { countries } = useSelector((state) => state.registration);

  // useEffect(() => {
  //   loadCaptchaEnginge(6);
  // }, [reintegrationResult]);

  const validate = (values) => {
    const errors = {};

    if (!values.residenceStartDate) {
      errors.residenceStartDate = translate("required");
    }

    if (!values.correctnessConfirmed) {
      errors.correctnessConfirmed = translate("required");
    }

    // if (!values.docType) {
    //   errors.docType = translate("required");
    // }

    // if (!values.docAttachments || values.docAttachments.length == 0) {
    //   errors.docAttachments = translate("required");
    // }

    if (
      !values.birthCertificateDocAttachment ||
      values.birthCertificateDocAttachment.length == 0
    ) {
      errors.birthCertificateDocAttachment = translate("required");
    }

    // if (!values.arDocAttachment || values.arDocAttachment.length == 0) {
    //   errors.arDocAttachment = translate("required");
    // }

    if (values.hasOtherCitizenships) {
      if (!values.citizenships || values.citizenships.length == 0) {
        errors.citizenships = translate("required");
      }
    }

    if (!values.parents || values.parents.length == 0) {
      errors.parents = translate("required");
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: CitizenshipAppModel,
    validate,
    onSubmit: (values) => {
      console.log("onSubmit values", values);
      save(values);
      // if (checkCaptcha()) {
      //   save(values);
      // }
    },
  });

  const addCitizenshipDoc = async () => {
    if (!selectedCountry) {
      toast(translate("please_select_country"));
      return;
    } else if (!citizenshipsDocs || citizenshipsDocs.length == 0) {
      toast(translate("please_add_value_to_selected_country"));
      return;
    } else {
      const files = [...citizenshipsDocs];
      let supportedFiles = true;
      let fileLimit = true;

      files.forEach((f) => {
        const { type, size } = f;
        const fileSize = size / 1024 / 1024;
        if (type === "application/pdf" || type.startsWith("image/")) {
          supportedFiles = true;
        } else {
          supportedFiles = false;
          setCitizenshipsDocsError(translate("file_not_allowed"));
        }

        if (fileSize > 2) {
          fileLimit = false;
          setCitizenshipsDocsError(translate("file_size_limit"));
        }
      });

      if (supportedFiles && fileLimit) {
        setCitizenshipsDocsError("");

        const promises = files.map(async (f) => {
          const base64 = await convertBase64(f);
          return base64;
        });
        const promiseRes = await Promise.all(promises);

        const citizenshipObj = {
          country: selectedCountry,
          docAttachments: promiseRes,
        };
        const existsDocs = formik.values?.citizenships || [];

        if (existsDocs.find((x) => x && x.country == citizenshipObj.country)) {
          toast(translate("Already exist"));
          return;
        } else {
          existsDocs.push(citizenshipObj);
          formik.setValues({ ...formik.values, citizenships: existsDocs });
          setSelectedCountry("");
          setCitizenshipsDocs([]);
          document.getElementById("citizenshipsDocs").value = "";
        }
      }
    }
  };

  const removeCitizenshipDoc = (index) => {
    const existsDocs = formik.values?.citizenships || [];
    existsDocs.splice(index, 1);
    formik.setValues({ ...formik.values, citizenships: existsDocs });
  };

  const addParent = async () => {
    if (!parentType) {
      toast(translate("please_select_parentType"));
      return;
    } else if (!parentFirstName) {
      toast(translate("please_add_parentFirstName"));
      return;
    } else if (!parentLastName) {
      toast(translate("please_add_parentLastName"));
      return;
    }
    // else if (!parentDocs || parentDocs.length == 0) {
    //   toast(translate("please_add_parentDocs"));
    //   return;
    // }
    else {
      const files = [...parentDocs];
      let supportedFiles = true;
      let fileLimit = true;

      files.forEach((f) => {
        const { type, size } = f;
        const fileSize = size / 1024 / 1024;
        if (type === "application/pdf" || type.startsWith("image/")) {
          supportedFiles = true;
        } else {
          supportedFiles = false;
          setParentDocsError(translate("file_not_allowed"));
        }

        if (fileSize > 2) {
          fileLimit = false;
          setParentDocsError(translate("file_size_limit"));
        }
      });

      if (supportedFiles && fileLimit) {
        setParentDocsError("");

        const promises = files.map(async (f) => {
          const base64 = await convertBase64(f);
          return base64;
        });
        const promiseRes = await Promise.all(promises);

        const obj = {
          parentType: Number(parentType),
          firstName: parentFirstName,
          lastName: parentLastName,
          docAttachments: promiseRes,
        };
        const existsDocs = formik.values?.parents || [];

        if (existsDocs.find((x) => x && x.parentType == obj.parentType)) {
          toast(translate("Already exist"));
          return;
        } else {
          existsDocs.push(obj);
          formik.setValues({ ...formik.values, parents: existsDocs });
          setParentType("");
          setParentFirstName("");
          setParentLastName("");
          setParentDocs([]);
          document.getElementById("parentDocs").value = "";
        }
      }
    }
  };

  const removeParent = (index) => {
    const existsDocs = formik.values?.parents || [];
    existsDocs.splice(index, 1);
    formik.setValues({ ...formik.values, parents: existsDocs });
  };

  const addOtherDocsAttachments = async () => {
    const files = [...otherDocsAttachmentList];
    let supportedFiles = true;
    let fileLimit = true;

    files.forEach((f) => {
      const { type, size } = f;
      const fileSize = size / 1024 / 1024;
      if (type === "application/pdf" || type.startsWith("image/")) {
        supportedFiles = true;
      } else {
        supportedFiles = false;
        setOtherDocsAttachmentListError(translate("file_not_allowed"));
      }

      if (fileSize > 2) {
        fileLimit = false;
        setOtherDocsAttachmentListError(translate("file_size_limit"));
      }
    });

    if (supportedFiles && fileLimit) {
      setOtherDocsAttachmentListError("");

      const promises = files.map(async (f) => {
        const base64 = await convertBase64(f);
        return base64;
      });
      const promiseRes = await Promise.all(promises);

      const fileObj = {
        docAttachments: promiseRes,
      };

      let existsDocs = formik.values?.otherDocsAttachments || [];
      existsDocs.push(fileObj);
      formik.setValues({ ...formik.values, otherDocsAttachments: existsDocs });

      setOtherDocsAttachmentList([]);
      document.getElementById("otherDocsAttachments").value = "";
    }
  };

  const removeOtherDocsAttachments = (index) => {
    const existsDocs = formik.values?.otherDocsAttachments || [];
    existsDocs.splice(index, 1);
    formik.setValues({ ...formik.values, otherDocsAttachments: existsDocs });
  };

  const addArDocsAttachments = async () => {
    const files = [...arDocsAttachmentList];
    let supportedFiles = true;
    let fileLimit = true;

    files.forEach((f) => {
      const { type, size } = f;
      const fileSize = size / 1024 / 1024;
      if (type === "application/pdf" || type.startsWith("image/")) {
        supportedFiles = true;
      } else {
        supportedFiles = false;
        setArDocsAttachmentListError(translate("file_not_allowed"));
      }

      if (fileSize > 2) {
        fileLimit = false;
        setArDocsAttachmentListError(translate("file_size_limit"));
      }
    });

    if (supportedFiles && fileLimit) {
      setArDocsAttachmentListError("");

      const promises = files.map(async (f) => {
        const base64 = await convertBase64(f);
        return base64;
      });
      const promiseRes = await Promise.all(promises);

      const fileObj = {
        docAttachments: promiseRes,
      };

      let existsDocs = formik.values?.arDocAttachment || [];
      existsDocs.push(fileObj);
      formik.setValues({ ...formik.values, arDocAttachment: existsDocs });

      setArDocsAttachmentList([]);
      document.getElementById("arDocAttachment").value = "";
    }
  };

  const removeArDocsAttachments = (index) => {
    const existsDocs = formik.values?.arDocAttachment || [];
    existsDocs.splice(index, 1);
    formik.setValues({ ...formik.values, arDocAttachment: existsDocs });
  };

  const addBirthCertificateDocsAttachments = async () => {
    const files = [...birthCertificateDocsAttachmentList];
    let supportedFiles = true;
    let fileLimit = true;

    files.forEach((f) => {
      const { type, size } = f;
      const fileSize = size / 1024 / 1024;
      if (type === "application/pdf" || type.startsWith("image/")) {
        supportedFiles = true;
      } else {
        supportedFiles = false;
        setBirthCertificateDocsAttachmentListError(
          translate("file_not_allowed")
        );
      }

      if (fileSize > 2) {
        fileLimit = false;
        setBirthCertificateDocsAttachmentListError(
          translate("file_size_limit")
        );
      }
    });

    if (supportedFiles && fileLimit) {
      setBirthCertificateDocsAttachmentListError("");

      const promises = files.map(async (f) => {
        const base64 = await convertBase64(f);
        return base64;
      });
      const promiseRes = await Promise.all(promises);

      const fileObj = {
        docAttachments: promiseRes,
      };

      let existsDocs = formik.values?.birthCertificateDocAttachment || [];
      existsDocs.push(fileObj);
      formik.setValues({
        ...formik.values,
        birthCertificateDocAttachment: existsDocs,
      });

      setBirthCertificateDocsAttachmentList([]);
      document.getElementById("birthCertificateDocAttachment").value = "";
    }
  };

  const removeBirthCertificateDocsAttachments = (index) => {
    const existsDocs = formik.values?.birthCertificateDocAttachment || [];
    existsDocs.splice(index, 1);
    formik.setValues({
      ...formik.values,
      birthCertificateDocAttachment: existsDocs,
    });
  };

  // const addDocAttachments = () => {
  //   {
  //     if (!selectedDocType) {
  //       toast(translate("please_select_doctype"));
  //       return;
  //     } else if (
  //       selectedDocType &&
  //       (!selectedDocAttachments || selectedDocAttachments.length == 0)
  //     ) {
  //       toast(translate("please_add_documents"));
  //       return;
  //     } else {
  //       const obj = {
  //         docType: selectedDocType,
  //         docAttachments: selectedDocAttachments,
  //       };
  //       const existsDocs = formik.values?.docAttachments || [];

  //       existsDocs.push(obj);
  //       formik.setValues({ ...formik.values, docAttachments: existsDocs });
  //       setsSelectedDocType("");
  //       setSelectedDocAttachments([]);
  //       document.getElementById("docAttachments").value = "";
  //     }
  //   }
  // };

  // const removeDocAttachments = (index) => {
  //   const existsDocs = formik.values?.docAttachments || [];
  //   existsDocs.splice(index, 1);
  //   formik.setValues({ ...formik.values, docAttachments: existsDocs });
  // };

  const save = async (dataObj) => {
    try {
      setLoading(true);
      // const {
      //   residenceStartDate,
      //   docType,
      //   docAttachments,
      //   arDocAttachment,
      //   hasOtherCitizenships,
      //   citizenships,
      //   parents,
      //   notes,
      //   otherDocsAttachments,
      // } = dataObj;

      // const formObj = new FormData();
      // formObj.append("reintegrationAppId", reintegrationNumber);
      // formObj.append("residenceStartDate", residenceStartDate);
      // formObj.append("hasOtherCitizenships", hasOtherCitizenships);
      // formObj.append("citizenships", citizenships);
      // formObj.append("parents", parents);
      // formObj.append("docType", docType);
      // formObj.append("notes", notes);

      let reqObj = { ...dataObj };
      reqObj.reintegrationAppId = reintegrationNumber;

      if (reqObj.residenceStartDate) {
        reqObj.residenceStartDate = new Date(reqObj.residenceStartDate);
      }

      // if (reqObj.docType) {
      //   reqObj.docType = Number(reqObj.docType);
      // }

      // if (reqObj.parents) {
      //   reqObj.parents = reqObj.parents.map((x) => ({
      //     ...x,
      //     parentType: Number(x.parentType),
      //     docAttachments: reqObj.docAttachments.map((item) => item.base64),
      //   }));
      // }

      // if (reqObj.citizenships) {
      //   reqObj.citizenships = reqObj.citizenships.map((x) => ({
      //     ...x,
      //     docAttachments: x.docAttachments.map((item) => item.base64),
      //   }));
      // }

      // if (reqObj.docAttachments && Array.isArray(reqObj.docAttachments)) {
      //   reqObj.docAttachments = reqObj.docAttachments.map((x) => ({
      //     docType: Number(x.docType),
      //     base64Contents: x.docAttachments.map((item) => item.base64),
      //   }));
      //   // const list = [];
      //   // const mappedDocs = (reqObj.docAttachments = reqObj.docAttachments.map(
      //   //   (item) => item.base64
      //   // ));
      //   // const obj = { docType: reqObj.docType, base64Content: mappedDocs };
      //   // list.push(obj);
      //   // reqObj.docAttachments = mappedDocs;
      //   // delete reqObj.docType;
      //   // formObj.append("docAttachments", mappedResult);
      //   // for (const file of docAttachments) {
      //   //   formObj.append("docAttachments", file);
      //   // }
      // }

      if (
        reqObj.birthCertificateDocAttachment &&
        Array.isArray(reqObj.birthCertificateDocAttachment)
      ) {
        reqObj.birthCertificateDocAttachment =
          reqObj.birthCertificateDocAttachment
            .map((item) => item.docAttachments)
            .flat(1);
      }

      if (reqObj.arDocAttachment && Array.isArray(reqObj.arDocAttachment)) {
        reqObj.arDocAttachment = reqObj.arDocAttachment
          .map((item) => item.docAttachments)
          .flat(1);
        // formObj.append("arDocAttachment", mappedResult);
        // for (const file of arDocAttachment) {
        //   formObj.append("arDocAttachment", file);
        // }
      }

      if (
        reqObj.otherDocsAttachments &&
        Array.isArray(reqObj.otherDocsAttachments)
      ) {
        reqObj.otherDocsAttachments = reqObj.otherDocsAttachments
          .map((item) => item.docAttachments)
          .flat(1);
        // formObj.append("otherDocsAttachments", mappedResult);
        // for (const file of otherDocsAttachments) {
        //   formObj.append("otherDocsAttachments", file);
        // }
      }

      const res = await createCitizenshipApplication(reqObj);
      setLoading(false);
      toggleNotifyModal();
      console.log("CitizenshipApp res", res);
      clearForm();
    } catch (error) {
      setLoading(false);
      toast.error(translate("An error occurred"));
      console.log("CitizenshipAppForm save error: ", error);
    }
  };

  const toggleNotifyModal = () => setNotifyModal(!notifyModal);

  const checkReintegrationNumber = async () => {
    if (!reintegrationNumber) {
      toast.error(translate("Reintegration number is required"));
      return;
    }
    // if check
    // checkCaptcha()
    try {
      setLoading(true);
      const res = await checkStatusAcceptedByReintegrationId(
        reintegrationNumber
      );
      if (res.success) {
        setReintegrationResult(true);
      } else {
        toast.info(translate("No result found"));
      }
      setLoading(false);
    } catch (error) {
      toast.error(translate("An error occurred"));
      setLoading(false);
    }
  };

  // const checkCaptcha = () => {
  //   let userCaptchaInput = document.getElementById("user_captcha_input");
  //   if (userCaptchaInput) {
  //     let captchaValue = userCaptchaInput.value;
  //     if (captchaValue) {
  //       if (validateCaptcha(captchaValue) === true) {
  //         loadCaptchaEnginge(6);
  //         userCaptchaInput.value = "";
  //         return true;
  //       } else {
  //         toast.error(translate("Captcha Does Not Match"));
  //         userCaptchaInput.value = "";
  //         return false;
  //       }
  //     } else {
  //       toast.error(translate("Captcha is required"));
  //       return false;
  //     }
  //   }
  // };

  const handleFileRead = async (event, target) => {
    const files = [...event.target.files];
    let supportedFiles = true;
    let fileLimit = true;

    files.forEach((f) => {
      const { type, size } = f;
      const fileSize = size / 1024 / 1024;
      if (type === "application/pdf" || type.startsWith("image/")) {
        supportedFiles = true;
      } else {
        supportedFiles = false;
        formik.setErrors({
          ...formik.errors,
          [target]: translate("file_not_allowed"),
        });
      }

      if (fileSize > 2) {
        fileLimit = false;
        formik.setErrors({
          ...formik.errors,
          [target]: translate("file_size_limit"),
        });
      }
    });

    if (supportedFiles && fileLimit) {
      const updatedFormikErrors = { ...formik.errors };
      delete updatedFormikErrors[target];
      formik.setErrors(updatedFormikErrors);

      const promises = files.map(async (f) => {
        // const { type, name } = f;
        const base64 = await convertBase64(f);
        return base64;
      });

      Promise.all(promises).then((res) => {
        if (target === "docAttachments") {
          // setSelectedDocAttachments(res);
        } else {
          formik.setValues({
            ...formik.values,
            [target]: res,
          });
        }
      });
    }
  };

  const clearForm = () => {
    // setsSelectedDocType(0);
    // setSelectedDocAttachments([]);
    setSelectedCountry("");
    setCitizenshipsDocs([]);
    setOtherDocsAttachmentList([]);
    setArDocsAttachmentList([]);
    setParentType("");
    setParentLastName("");
    setParentFirstName("");
    setParentDocs([]);
    formik.handleReset();
    if (document.getElementById("arDocAttachment")) {
      document.getElementById("arDocAttachment").value = "";
    }
    if (document.getElementById("otherDocsAttachments")) {
      document.getElementById("otherDocsAttachments").value = "";
    }
  };

  // function previewDocuments(list) {
  //   if (Array.isArray(list)) {
  //     return list.map((file, i) =>
  //       file.startsWith("data:image/") ? (
  //         <div key={i}>
  //           <img
  //             src={file}
  //             className="img-thumbnail"
  //             style={{ maxWidth: 100, margin: 2 }}
  //           />
  //         </div>
  //       ) : (
  //         <div key={i}>
  //           <a href={file} style={{ margin: 2 }}>
  //             <img
  //               src="../../../public/assets/images/PDF_file_icon.png"
  //               style={{ maxWidth: 100, margin: 2 }}
  //             />
  //           </a>
  //         </div>
  //       )
  //     );
  //   }
  // }

  function renderNotifyModal() {
    return (
      <Modal isOpen={notifyModal} toggle={toggleNotifyModal}>
        <ModalHeader toggle={toggleNotifyModal}>
          {translate("Ətraflı məlumat")}
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-10 offset-lg-1 mt-4 mb-4 ">
                <h4 className="green">
                  <i className="fa fa-exclamation-triangle"></i>{" "}
                  {translate("Müraciətiniz qeydə alındı")}.
                </h4>
                <p className="modal-content-p">
                  {" "}
                  {translate("success_message_desc1")}{" "}
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleNotifyModal}>
            {translate("close")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  return (
    <div>
      <div className="container tab-con-desc">
        <div className="row ">
          <div className="col-lg-12 ">
            {" "}
            <h6>{translate("Fərdi məlumatlarınız qorunur!")} </h6>{" "}
          </div>
        </div>
      </div>
      {!reintegrationResult && (
        <div className="container">
          <BlockUi tag="div" blocking={loading}>
            <div className="row ">
              <div className="col-lg-12 mt-3">
                <div className="row mb-4">
                  <div className="col-lg-8 offset-lg-2 col-md-12 mb-2">
                    <div className="row">
                      <div className="col-sm-12 col-lg-8 mb-2">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="form3Example1">
                            {translate("Reinteqrasiya nömrəsi")}{" "}
                            <span className="red">* </span>{" "}
                          </label>
                          <input
                            type="text"
                            id="form3Example1"
                            className="form-control"
                            name="reintegrationNumber"
                            onChange={(e) =>
                              setReintegrationNumber(e.target.value)
                            }
                            onKeyDown={(e) => {
                              if (e.keyCode == 13 || e.code == "Enter") {
                                checkReintegrationNumber();
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="col-sm-12 col-lg-4 mb-2"
                        style={{
                          display: "flex",
                          alignItems: "end",
                          justifyContent: width < 600 ? "center" : "flex-start",
                        }}
                      >
                        <button
                          onClick={checkReintegrationNumber}
                          className="custom-send-button"
                        >
                          {" "}
                          {translate("Daxil ol")}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-8 offset-lg-2 col-md-12 text-center mb-2">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ReactSimpleCaptcha />
                      &nbsp;&nbsp;

                      <button
                        onClick={checkReintegrationNumber}
                        className="custom-send-button"
                      >
                        {" "}
                        {translate("Daxil ol")}{" "}
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </BlockUi>
        </div>
      )}

      {reintegrationResult && (
        <div className="container">
          <BlockUi tag="div" blocking={loading}>
            <div className="row ">
              <div className="col-lg-12 mt-3 mb-4">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row mb-4">
                    <div className="col-sm-12 col-md-6 col-lg-6 mb-2">
                      <div className="form-outline">
                        <label className="form-label">
                          {translate("Reinteqrantın qeydiyyat nömrəsi")}{" "}
                          <span className="red">* </span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          defaultValue={reintegrationNumber}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 mb-2">
                      <div className="form-outline">
                        <label className="form-label">
                          {translate("Qarabağda məskunlaşdığınız tarix")}{" "}
                          <span className="red">* </span>{" "}
                        </label>
                        <DatePicker
                          weekDays={weekDays[getCurrentLang()]}
                          months={months[getCurrentLang()]}
                          placeholder="__.__.____"
                          className={"form-control"}
                          format="DD.MM.YYYY"
                          value={
                            formik.values.residenceStartDate || "__.__.____"
                          }
                          onChange={(date) => {
                            formik.setValues({
                              ...formik.values,
                              residenceStartDate: date,
                            });
                          }}
                        />
                        {formik.errors.residenceStartDate ? (
                          <small className="red">
                            {formik.errors.residenceStartDate}
                          </small>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-sm-12 col-lg-10 mb-2">
                      <div className="form-outline">
                        <label className="form-label">
                          {" "}
                          {translate(
                            "vətəndaşlığında olmağınızı təsdiq edən sənəd"
                          )}
                        </label>
                        <div className="form-control">
                          <label htmlFor="arDocAttachment">
                            {document.getElementById("arDocAttachment")
                              ?.value ? (
                              <>
                                {translate("selected_files")}
                                {" - "}
                                {
                                  Object.keys(
                                    document.getElementById("arDocAttachment")
                                      ?.files
                                  ).length
                                }
                              </>
                            ) : (
                              <>
                                <i className="fa fa-upload"></i>{" "}
                                {translate("Upload file")}
                              </>
                            )}
                          </label>
                          <input
                            style={{ display: "none" }}
                            className="form-control"
                            type="file"
                            multiple
                            id="arDocAttachment"
                            name="arDocAttachment"
                            onChange={(e) =>
                              setArDocsAttachmentList(e.target.files)
                            }
                            accept="application/pdf,image/png, image/gif, image/jpeg"
                          />
                        </div>
                        {formik.errors.arDocAttachment ? (
                          <small className="red">
                            {formik.errors.arDocAttachment}
                          </small>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="col-sm-12 col-lg-2 col-md-2 mb-2"
                      style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: width < 600 ? "center" : "flex-start",
                      }}
                    >
                      <div
                        className="send-button"
                        onClick={addArDocsAttachments}
                      >
                        <span
                          className="custom-send-button"
                          style={width < 600 ? { width: 120 } : {}}
                        >
                          {translate("add")}
                        </span>
                      </div>
                    </div>
                    <div>
                      {arDocsAttachmentListError ? (
                        <small className="red">
                          {arDocsAttachmentListError}
                        </small>
                      ) : null}
                      <div>
                        {formik.values.arDocAttachment &&
                        Array.isArray(formik.values.arDocAttachment) &&
                        formik.values.arDocAttachment.length ? (
                          <Table size="sm" striped>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>{translate("Documents")}</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {formik.values.arDocAttachment.map((item, i) => (
                                <tr key={i}>
                                  <th scope="row">{i + 1}</th>
                                  <td>
                                    {item.docAttachments && (
                                      <DocumentView
                                        list={item.docAttachments}
                                        style="inline"
                                        fileWidth={50}
                                      />
                                    )}
                                  </td>
                                  <td>
                                    <i
                                      className="fa fa-trash"
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => removeArDocsAttachments(i)}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        ) : null}
                      </div>
                      {/* {formik.values.arDocAttachment && (
                        <DocumentView
                          list={formik.values.arDocAttachment}
                          style="inline"
                          fileWidth={100}
                        />
                      )} */}
                      {/* {previewDocuments(formik.values.arDocAttachment)} */}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-sm-12 col-lg-10 mb-2">
                      <div className="form-outline">
                        <label className="form-label">
                          {" "}
                          {translate("reg_citizenship_birth_certificate")}{" "}
                          <span className="red">* </span>
                        </label>
                        <div className="form-control">
                          <label htmlFor="birthCertificateDocAttachment">
                            {document.getElementById(
                              "birthCertificateDocAttachment"
                            )?.value ? (
                              <>
                                {translate("selected_files")}
                                {" - "}
                                {
                                  Object.keys(
                                    document.getElementById(
                                      "birthCertificateDocAttachment"
                                    )?.files
                                  ).length
                                }
                              </>
                            ) : (
                              <>
                                <i className="fa fa-upload"></i>{" "}
                                {translate("Upload file")}
                              </>
                            )}
                          </label>
                          <input
                            style={{ display: "none" }}
                            className="form-control"
                            type="file"
                            multiple
                            id="birthCertificateDocAttachment"
                            name="birthCertificateDocAttachment"
                            onChange={(e) =>
                              setBirthCertificateDocsAttachmentList(
                                e.target.files
                              )
                            }
                            accept="application/pdf,image/png, image/gif, image/jpeg"
                          />
                        </div>
                        {formik.errors.birthCertificateDocAttachment ? (
                          <small className="red">
                            {formik.errors.birthCertificateDocAttachment}
                          </small>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="col-sm-12 col-lg-2 col-md-2 mb-2"
                      style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: width < 600 ? "center" : "flex-start",
                      }}
                    >
                      <div
                        className="send-button"
                        onClick={addBirthCertificateDocsAttachments}
                      >
                        <span
                          className="custom-send-button"
                          style={width < 600 ? { width: 120 } : {}}
                        >
                          {translate("add")}
                        </span>
                      </div>
                    </div>
                    <div>
                      {birthCertificateDocsAttachmentListError ? (
                        <small className="red">
                          {birthCertificateDocsAttachmentListError}
                        </small>
                      ) : null}
                      <div>
                        {formik.values.birthCertificateDocAttachment &&
                        Array.isArray(
                          formik.values.birthCertificateDocAttachment
                        ) &&
                        formik.values.birthCertificateDocAttachment.length ? (
                          <Table size="sm" striped>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>{translate("Documents")}</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {formik.values.birthCertificateDocAttachment.map(
                                (item, i) => (
                                  <tr key={i}>
                                    <th scope="row">{i + 1}</th>
                                    <td>
                                      {item.docAttachments && (
                                        <DocumentView
                                          list={item.docAttachments}
                                          style="inline"
                                          fileWidth={50}
                                        />
                                      )}
                                    </td>
                                    <td>
                                      <i
                                        className="fa fa-trash"
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          removeBirthCertificateDocsAttachments(
                                            i
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row mb-4">
                      <div className="col mb-2">
                        <label className="form-label" htmlFor="form3Example1">
                          {translate("mənsubiyyətiniz varmı")}
                        </label>{" "}
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="hasOtherCitizenships"
                            checked={
                              formik.values.hasOtherCitizenships ? true : false
                            }
                            onChange={(e) =>
                              formik.setValues({
                                ...formik.values,
                                hasOtherCitizenships: true,
                              })
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            {translate("Bəli")}{" "}
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="hasOtherCitizenships"
                            checked={
                              formik.values.hasOtherCitizenships ? false : true
                            }
                            onChange={(e) =>
                              formik.setValues({
                                ...formik.values,
                                hasOtherCitizenships: false,
                              })
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          >
                            {translate("Xeyr")}
                          </label>
                        </div>
                      </div>
                    </div>
                    {formik.values.hasOtherCitizenships && (
                      <div className="row mb-4">
                        <div className="col-sm-12 col-lg-4 mb-2">
                          <div className="form-outline">
                            <label className="form-label">
                              {translate("Mənsub olduğu dövlətin vətəndaşlığı")}{" "}
                              <span className="red">* </span>{" "}
                            </label>
                            <select
                              className="form-select "
                              id="country"
                              name="country"
                              onChange={(e) =>
                                setSelectedCountry(e.target.value)
                              }
                              value={selectedCountry}
                            >
                              <option value="">
                                {translate("Seçilməyib")}
                              </option>
                              {countries &&
                                countries.map((item) => (
                                  <option value={item.id} key={item.id}>
                                    {getCountryNameByLang(item.id)}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-6 mb-2">
                          <div className="form-outline">
                            <label className="form-label">
                              {translate(
                                "Vətəndaşlıq sənədin surətini əlavə etmək"
                              )}{" "}
                              <span className="red">* </span>
                            </label>
                            <div className="form-control">
                              <label htmlFor="citizenshipsDocs">
                                {document.getElementById("citizenshipsDocs")
                                  ?.value ? (
                                  <>
                                    {translate("selected_files")}
                                    {" - "}
                                    {
                                      Object.keys(
                                        document.getElementById(
                                          "citizenshipsDocs"
                                        )?.files
                                      ).length
                                    }
                                  </>
                                ) : (
                                  <>
                                    <i className="fa fa-upload"></i>{" "}
                                    {translate("Upload file")}
                                  </>
                                )}
                              </label>
                              <input
                                style={{ display: "none" }}
                                className="form-control"
                                type="file"
                                multiple
                                id="citizenshipsDocs"
                                name="citizenshipsDocs"
                                onChange={(e) =>
                                  setCitizenshipsDocs([...e.target.files])
                                }
                                accept="application/pdf,image/png, image/gif, image/jpeg"
                              />
                            </div>
                          </div>
                          {citizenshipsDocsError ? (
                            <small className="red">
                              {citizenshipsDocsError}
                            </small>
                          ) : null}
                        </div>
                        <div
                          className="col-sm-12 col-lg-2 col-md-2 mb-2"
                          style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent:
                              width < 600 ? "center" : "flex-start",
                          }}
                        >
                          <div
                            className="send-button"
                            onClick={addCitizenshipDoc}
                          >
                            <span
                              className="custom-send-button"
                              style={width < 600 ? { width: 120 } : {}}
                            >
                              {translate("add")}
                            </span>
                          </div>
                        </div>
                        {formik.errors.citizenships ? (
                          <small className="red">
                            {formik.errors.citizenships}
                          </small>
                        ) : null}
                        <div className="col-lg-12">
                          {formik.values.citizenships &&
                          Array.isArray(formik.values.citizenships) &&
                          formik.values.citizenships.length ? (
                            <Table size="sm" striped>
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>{translate("Country")}</th>
                                  <th>{translate("Documents")}</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {formik.values.citizenships.map((item, i) => (
                                  <tr key={i}>
                                    <th scope="row">{i + 1}</th>
                                    <td>
                                      {getCountryNameByLang(item.country)}
                                    </td>
                                    <td>
                                      {/* {item.docAttachments &&
                                      item.docAttachments.length} */}
                                      {item.docAttachments && (
                                        <DocumentView
                                          list={item.docAttachments}
                                          style="inline"
                                          fileWidth={50}
                                        />
                                      )}
                                    </td>
                                    <td>
                                      <i
                                        className="fa fa-trash"
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => removeCitizenshipDoc(i)}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </div>
                  <hr />
                  <div>
                    <div className="row mb-4">
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label className="form-label">
                          {translate("Valideynləriniz barədə məlumatlar")}{" "}
                          <span className="red">* </span>
                        </label>
                        <select
                          className="form-select"
                          name="parentType"
                          onChange={(e) => setParentType(e.target.value)}
                          value={parentType}
                        >
                          {Object.entries(parentTypes).map(([key, value]) => (
                            <option key={value} value={value}>
                              {translate(key)}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <div className="form-outline">
                          <label className="form-label">
                            {translate("firstName")}{" "}
                            <span className="red">* </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="parentFirstName"
                            onChange={(e) => setParentFirstName(e.target.value)}
                            value={parentFirstName}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <div className="form-outline">
                          <label className="form-label">
                            {translate("lastName")}{" "}
                            <span className="red">* </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="parentLastName"
                            onChange={(e) => setParentLastName(e.target.value)}
                            value={parentLastName}
                          />
                        </div>
                      </div>
                      {formik.errors.parents ? (
                        <small className="red">{formik.errors.parents}</small>
                      ) : null}
                    </div>
                    <div className="row mb-4">
                      <div className="col-sm-12 col-lg-10 mb-2">
                        <div className="form-outline">
                          <label className="form-label">
                            {translate(
                              "vətəndaşlığında olduqlarını təsdiq edən sənəd"
                            )}
                            {/* <span className="red">* </span> */}
                          </label>
                          <div className="form-control">
                            <label htmlFor="parentDocs">
                              {document.getElementById("parentDocs")?.value ? (
                                <>
                                  {translate("selected_files")}
                                  {" - "}
                                  {
                                    Object.keys(
                                      document.getElementById("parentDocs")
                                        ?.files
                                    ).length
                                  }
                                </>
                              ) : (
                                <>
                                  <i className="fa fa-upload"></i>{" "}
                                  {translate("Upload file")}
                                </>
                              )}
                            </label>
                            <input
                              style={{ display: "none" }}
                              className="form-control"
                              type="file"
                              multiple
                              id="parentDocs"
                              name="parentDocs"
                              onChange={(e) =>
                                setParentDocs([...e.target.files])
                              }
                              accept="application/pdf,image/png, image/gif, image/jpeg"
                            />
                          </div>
                        </div>
                        {parentDocsError ? (
                          <small className="red">{parentDocsError}</small>
                        ) : null}
                      </div>
                      <div
                        className="col-sm-12 col-lg-2 col-md-2 mb-2"
                        style={{
                          display: "flex",
                          alignItems: "end",
                          justifyContent: width < 600 ? "center" : "flex-start",
                        }}
                      >
                        <div className="send-button" onClick={addParent}>
                          <span
                            className="custom-send-button"
                            style={width < 600 ? { width: 120 } : {}}
                          >
                            {translate("add")}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-12 pt-4">
                        <Table size="sm" striped bordered>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{translate("Parent Type")}</th>
                              <th>{translate("firstName")}</th>
                              <th>{translate("lastName")}</th>
                              <th>{translate("Documents")}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {formik.values?.parents &&
                              formik.values.parents.map((item, i) => (
                                <tr key={i}>
                                  <th scope="row">{i + 1}</th>
                                  <td>
                                    {translate(
                                      parentTypesNumber[item.parentType]
                                    )}
                                  </td>
                                  <td>{item.firstName}</td>
                                  <td>{item.lastName}</td>
                                  <td>
                                    {/* {item.docAttachments &&
                                    item.docAttachments.length} */}
                                    {item.docAttachments && (
                                      <DocumentView
                                        list={item.docAttachments}
                                        style="inline"
                                        fileWidth={50}
                                      />
                                    )}
                                  </td>
                                  <td>
                                    <i
                                      className="fa fa-trash"
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => removeParent(i)}
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row mb-4">
                    <div className="col-sm-12 col-lg-10 mb-2">
                      <div className="form-outline">
                        <label className="form-label">
                          {translate(
                            "Təqdim etmək istədiyiniz digər sənədləri əlavə etmək"
                          )}
                        </label>
                        <div className="form-control">
                          <label htmlFor="otherDocsAttachments">
                            {document.getElementById("otherDocsAttachments")
                              ?.value ? (
                              <>
                                {translate("selected_files")}
                                {" - "}
                                {
                                  Object.keys(
                                    document.getElementById(
                                      "otherDocsAttachments"
                                    )?.files
                                  ).length
                                }
                              </>
                            ) : (
                              <>
                                <i className="fa fa-upload"></i>{" "}
                                {translate("Upload file")}
                              </>
                            )}
                          </label>
                          <input
                            style={{ display: "none" }}
                            className="form-control"
                            type="file"
                            multiple
                            id="otherDocsAttachments"
                            name="otherDocsAttachments"
                            onChange={
                              (e) => setOtherDocsAttachmentList(e.target.files)
                              // handleFileRead(e, "otherDocsAttachments")
                            }
                            accept="application/pdf,image/png, image/gif, image/jpeg"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-sm-12 col-lg-2 col-md-2 mb-2"
                      style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: width < 600 ? "center" : "flex-start",
                      }}
                    >
                      <div
                        className="send-button"
                        onClick={addOtherDocsAttachments}
                      >
                        <span
                          className="custom-send-button"
                          style={width < 600 ? { width: 120 } : {}}
                        >
                          {translate("add")}
                        </span>
                      </div>
                    </div>
                    {otherDocsAttachmentListError ? (
                      <small className="red">
                        {otherDocsAttachmentListError}
                      </small>
                    ) : null}
                    <div>
                      {formik.values.otherDocsAttachments &&
                      Array.isArray(formik.values.otherDocsAttachments) &&
                      formik.values.otherDocsAttachments.length ? (
                        <Table size="sm" striped>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{translate("Documents")}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {formik.values.otherDocsAttachments.map(
                              (item, i) => (
                                <tr key={i}>
                                  <th scope="row">{i + 1}</th>
                                  <td>
                                    {item.docAttachments && (
                                      <DocumentView
                                        list={item.docAttachments}
                                        style="inline"
                                        fileWidth={50}
                                      />
                                    )}
                                  </td>
                                  <td>
                                    <i
                                      className="fa fa-trash"
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        removeOtherDocsAttachments(i)
                                      }
                                    />
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col mb-2">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="form3Example1">
                          {translate("Əlavə qeyd etmək istədiyiniz məlumatlar")}
                        </label>
                        <textarea
                          className="form-control"
                          rows="5"
                          {...formik.getFieldProps("notes")}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-4">
                    {/* <div className="col-lg-4 col-md-12 mb-2">
                      <ReactSimpleCaptcha />
                    </div> */}
                    <div className="mb-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={formik.values.correctnessConfirmed}
                          onChange={(e) => {
                            if (e.target.checked) {
                              formik.setValues({
                                ...formik.values,
                                correctnessConfirmed: true,
                              });
                            } else {
                              formik.setValues({
                                ...formik.values,
                                correctnessConfirmed: false,
                              });
                            }
                          }}
                        />
                        <label className="form-check-label">
                          {translate("confirm_the_correctness")}
                        </label>
                      </div>
                      {formik.errors.correctnessConfirmed ? (
                        <small className="red">
                          {formik.errors.correctnessConfirmed}
                        </small>
                      ) : null}
                    </div>
                    <div
                      className="col-lg-8 col-md-12"
                      style={{ alignSelf: "flex-end" }}
                    >
                      <button
                        onClick={clearForm}
                        className="custom-clear-button"
                        style={{ marginRight: 10 }}
                      >
                        {" "}
                        {translate("clear")}{" "}
                      </button>
                      <button
                        type="submit"
                        className="custom-send-button"
                        onClick={() => console.log("clicked")}
                      >
                        {" "}
                        {translate("send")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </BlockUi>
        </div>
      )}
      {renderNotifyModal()}
    </div>
  );
}

export default CitizenshipAppForm;
