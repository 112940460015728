/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useEffect, useRef, useState } from "react";
import { isWidgetOpened, toggleWidget } from "react-chat-widget";
import moment from "moment";
import { List, MessageSquare, Send, XCircle } from "react-feather";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
// CUSTOM IMPORTS
import { checkIsNonWorkingDay, validateEmail } from "../utils/helpers";
import { socketEvents, storageKeys } from "../utils/constants";
import { socketService } from "../services/socket.service";
import useWindowDarkMode from "../hooks/useWindowDarkMode";
import {
  getCurrentLang,
  translate,
  use,
} from "../services/translation/translation.service";
import {
  setMessages,
  setBotMessages,
  setUnSentMessages,
  setLoading,
  setRoomCreated,
  setCreateRoomRequested,
  setShowThanksMessage,
  setRequireForm,
  setChatId,
  setCurrentUser,
  setIsWidgetActive,
  addMessageToList,
  setGlobalLang,
} from "../store/chatStore";
// ASSETS
import "../assets/css/custom.css";
import "react-toastify/dist/ReactToastify.css";
import "react-chat-widget/lib/styles.css";

function ChatWidget() {
  const {
    loading,
    isWidgetActive,
    messages,
    botMessages,
    roomCreated,
    createRoomRequested,
    requireForm,
    unSentMessages,
    chatId,
    showThanksMessage,
    currentUser,
    globalLang,
  } = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  const darkMode = useWindowDarkMode();

  const [lang, setLang] = useState(getCurrentLang() || "az");
  const [userName, setUserName] = useState("");
  const [userSurname, setUserSurname] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userMessage, setUserMessage] = useState("");

  const messageEl = useRef(null);

  useEffect(() => {
    if (messageEl) {
      messageEl.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }

    getMessagesFromAsyncStorage();
  }, []);

  useEffect(() => {
    setUserName("");
    setUserSurname("");
    setUserEmail("");
  }, [requireForm]);

  useEffect(() => {
    setLang(globalLang);
  }, [globalLang]);

  function getMessagesFromAsyncStorage() {
    let localData = localStorage.getItem(storageKeys.CHAT_MESSAGES_KEY);
    let data = localData ? JSON.parse(localData) : undefined;
    if (data?.user && data?.chatId) {
      const localMessages = data?.messages || [];
      dispatch(setMessages([...localMessages]));
      dispatch(setCurrentUser({ ...data.user }));
      dispatch(setChatId(data.chatId));
      dispatch(setRequireForm(false));

      if (!roomCreated) {
        createRoom(data.user);
      }
    }
  }

  async function createRoom(userData) {
    try {
      if (createRoomRequested) return;
      dispatch(setLoading(true));
      await socketService.connect();
      dispatch(setCreateRoomRequested(true));
      if (socketService.socket) {
        const userObj = userData || currentUser;
        setTimeout(() => {
          if (userObj?.userId) {
            socketService.socket.emit(socketEvents.createRoom, {
              ...userObj,
              label: "external",
              subjectType: undefined,
            });

            subscribeMessages();
            dispatch(setRequireForm(false));

            let botMessage = {
              id: Math.random(),
              answer: {
                id: 1,
                az: "Sizə necə köməklik edə bilərik?",
                en: "How can we assist you?",
                ru: "Как мы можем Вам помочь?",
                am: "Ինչպե՞ս կարող ենք օգնել ձեզ?",
              },
            };

            if (checkIsNonWorkingDay()) {
              botMessage = {
                id: Math.random(),
                answer: {
                  id: 1,
                  az: "Hazırda qeyri-iş saatları olduğundan, müraciətiniz növbəti iş günü ərzində cavablandırılacaq.",
                  en: "As it is currently non-business hours, your application will be answered within the next business day.",
                  ru: "Поскольку сейчас нерабочие часы, ответ на Ваше обращение будет дан в течение следующего рабочего дня.",
                  am: "Քանի որ ներկայումս ոչ աշխատանքային ժամեր են, ձեր հարցումը կպատասխանվի հաջորդ աշխատանքային օրվա ընթացքում:",
                },
              };
            }

            dispatch(setBotMessages([...botMessages, botMessage]));
          } else {
            console.log("User id is required!. createRoom failed", userObj);
            toast.error(`${translate("an_error_occurred")}. Error code:91901`);
          }
          dispatch(setLoading(false));
        }, 1000);
      } else {
        dispatch(setLoading(false));
        console.log("socketService.socket not found");
        toast.error(translate("an_error_occurred"));
      }
    } catch (error) {
      dispatch(setLoading(false));
      toast.error(translate("an_error_occurred"));
      console.log("createRoom error", error);
    }
  }

  function subscribeMessages() {
    socketService.socket.on(socketEvents.chatMessage, onChatMessage);
    socketService.socket.on(socketEvents.chatClosed, onChatClose);
  }

  const onChatClose = (_chatId) => {
    localStorage.removeItem(storageKeys.CHAT_MESSAGES_KEY);
    dispatch(setRequireForm(true));
    dispatch(setCurrentUser(undefined));
    dispatch(setBotMessages([]));
    dispatch(setChatId(undefined));
    dispatch(setShowThanksMessage(true));
    dispatch(setMessages([]));

    setTimeout(() => {
      dispatch(setShowThanksMessage(false));
    }, 3000);
  };

  const onChatMessage = async (message) => {
    if (message.messageType === "chatData") {
      onCreateRoomAccept(message);
    } else {
      dispatch(addMessageToList(message));
    }
  };

  const onCreateRoomAccept = (chatData) => {
    dispatch(setChatId(chatData?.chatId));
    dispatch(setRoomCreated(true));
    dispatch(setCreateRoomRequested(false));
    unSentMessages.forEach((data) =>
      socketService.socket.emit(socketEvents.chatMessage, data)
    );
  };

  const sendMessage = (messageData) => {
    let data = {
      ...messageData,
      messageType: messageData?.messageType || "userMessage",
      roomId: currentUser?.userId,
      senderId: currentUser?.userId,
      chatId: chatId,
      label: "external",
    };
    if (!roomCreated) {
      createRoom();
      return dispatch(setUnSentMessages([...unSentMessages, data]));
    }
    socketService.socket.emit(socketEvents.chatMessage, data);
  };

  function changeLang(lang) {
    use(lang);
    setLang(lang);
    dispatch(setGlobalLang(lang));
    // window.location.reload();
  }

  const closeChat = () =>
    socketService.socket
      ? socketService.socket.emit(socketEvents.closeChat, chatId)
      : console.log("Close chat error: Socket not available");

  const handleToggle = () => {
    toggleWidget();

    if (isWidgetOpened()) {
      dispatch(setIsWidgetActive(true));
      document.getElementById("custom-chat-widget-new").style.display = "block";
      document.getElementById("modalChat").classList.add("active");
      document.getElementById("modalChat").classList.remove("ecw-hidden");
    } else {
      dispatch(setIsWidgetActive(false));
      document.getElementById("custom-chat-widget-new").style.display = "none";
      document.getElementById("modalChat").classList.add("ecw-hidden");
      document.getElementById("modalChat").classList.remove("active");
    }
  };

  const handleMenuToggle = () => {
    document.getElementById("menuDropdown").classList.toggle("show");
  };

  const startChatFunc = () => {
    if (!userEmail || !userName) {
      toast.info(translate("Please fill the required fields"));
      return;
    } else if (userEmail && !validateEmail(userEmail)) {
      toast.error(translate("please_add_valid_email"));
      return;
    } else {
      let user = {
        type: "user",
        userId: userEmail,
        name: userName,
        surname: userSurname,
        email: userEmail,
      };
      dispatch(setCurrentUser(user));
      createRoom(user);
    }
  };

  const onKeyPressClick = (e) => {
    if (e.key === "Enter") {
      handleNewUserMessage();
    }
  };

  const handleNewUserMessage = () => {
    if (userMessage) {
      if (!currentUser?.email) {
        dispatch(setRequireForm(true));
      } else {
        sendMessage({ text: userMessage });
        setUserMessage("");
      }
    } else {
      toast.info(translate("enter_message"));
    }
  };

  function renderChatHeader() {
    return (
      <div
        className="rcw-header"
        // style={{ backgroundColor: "#5F259F", color: "#fff" }}
        style={
          darkMode
            ? { background: "#232223" }
            : { backgroundColor: "#5F259F", color: "#fff" }
        }
      >
        {/* <button
          className="rcw-close-button"
          onClick={handleToggle}
          style={{ backgroundColor: "#5F259F" }}
        >
          {translate("close")}
        </button> */}
        <div className="dropdown">{renderChatMenu()}</div>
        <span className="chat-langs">
          <a
            href="#"
            className="text-left chat-lang"
            style={{
              color: lang === "az" ? "#fff" : "lightgrey",
            }}
            onClick={() => {
              changeLang("az");
            }}
          >
            {translate("az")}
          </a>
          <a
            href="#"
            className="text-left chat-lang"
            style={{
              color: lang === "en" ? "#fff" : "lightgrey",
            }}
            onClick={() => {
              changeLang("en");
            }}
          >
            {translate("en")}
          </a>
          <a
            href="#"
            className="text-left chat-lang"
            style={{
              color: lang === "ru" ? "#fff" : "lightgrey",
            }}
            onClick={() => {
              changeLang("ru");
            }}
          >
            {translate("ru")}
          </a>
          <a
            href="#"
            className="text-left chat-lang"
            style={{
              color: lang === "am" ? "#fff" : "lightgrey",
            }}
            onClick={() => {
              changeLang("am");
            }}
          >
            {translate("am")}
          </a>
        </span>

        <h6 className="rcw-title" style={{ fontSize: 18, color: "#fff" }}>
          {translate("live_chat")}
        </h6>

        {/* <span style={{ color: "#fff" }}>
          {translate("live_chat_description")}
        </span> */}
      </div>
    );
  }

  function renderChatMenu() {
    return (
      <>
        <button onClick={handleMenuToggle} className="dropbtn">
          <List style={{ fontSize: 20 }} />
        </button>
        <div id="menuDropdown" className="dropdown-content">
          <div className="list-group">
            {chatId && (
              <a
                href="#"
                className="list-group-item list-group-item-action text-left"
                onClick={() => {
                  closeChat();
                }}
              >
                {translate("close_chat_session")}
              </a>
            )}
          </div>
        </div>
      </>
    );
  }

  function renderChatThanksSection() {
    return (
      <div
        className="form-group container text-center"
        id="rating-ability-wrapper"
        style={{ margin: "15px 0px 20px 0px" }}
      >
        <div
          className="text-center"
          style={{ marginTop: 15, marginBottom: 15 }}
        >
          <h5>{translate("Thank you for contacting us")}</h5>
          {/* <h6 style={{ maxWidth: 400 }}>
            Going forward, don’t think twice before writing to use with any
            ideas or suggestions. We totally want that from you!{" "}
          </h6>
          <p>Thank you once again</p> */}
        </div>
      </div>
    );
  }

  function renderChatForm() {
    return (
      <div className="container" style={{ margin: "15px 0px 20px 0px" }}>
        <div className="form-group" style={{ marginBottom: 10 }}>
          <input
            type="text"
            className="form-control"
            placeholder={"*" + translate("name")}
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <div className="form-group" style={{ marginBottom: 10 }}>
          <input
            type="text"
            className="form-control"
            placeholder={translate("surname")}
            value={userSurname}
            onChange={(e) => setUserSurname(e.target.value)}
          />
        </div>
        <div className="form-group" style={{ marginBottom: 10 }}>
          <input
            type="email"
            className="form-control"
            placeholder={"*" + translate("email")}
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
        </div>
        <div className="text-center" style={{ marginTop: 15 }}>
          <button
            type="button"
            className="btn btn-secondary"
            style={{ backgroundColor: "#5F259F", marginRight: "1%" }}
            onClick={startChatFunc}
          >
            {translate("start_the_chat")}
          </button>
        </div>
      </div>
    );
  }

  const renderBotMessages = (item, i) => {
    return (
      <div key={i}>
        <div className="rcw-message">
          <div className={"rcw-client"}>
            <div
              className="rcw-message-text"
              style={{
                backgroundColor: "#fff",
                padding: "unset",
              }}
            >
              <span>{translate(item.question)}</span>
            </div>
          </div>
        </div>
        <div className="rcw-message">
          <div className={"rcw-response"}>
            <div className="rcw-message-text">
              <p style={{ color: "#000", fontWeight: 400 }}>
                {translate(item.answer)}
              </p>
              <span className="rcw-timestamp">
                {moment(item.createdAt).format("DD.MM.YYYY hh:mm")}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  function renderChatLoading() {
    return (
      <div className="loader active">
        <div className="loader-container">
          <span className="loader-dots"></span>
          <span className="loader-dots"></span>
          <span className="loader-dots"></span>
        </div>
      </div>
    );
  }

  function renderMessageSendElements() {
    return (
      <div className="rcw-sender">
        <input
          type="text"
          className="rcw-new-message"
          name="message"
          style={{ paddingLeft: 15 }}
          placeholder={translate("type_a_message") + "..."}
          autoComplete="off"
          value={userMessage}
          onChange={(event) => setUserMessage(event.target.value)}
          onKeyPress={onKeyPressClick}
        />
        <button
          type="button"
          className="rcw-send msg-send-btn-custom"
          onClick={handleNewUserMessage}
        >
          <Send />
        </button>
      </div>
    );
  }

  function renderOpenCloseChatButton() {
    return (
      <button
        type="button"
        className="rcw-launcher"
        aria-controls="rcw-chat-container"
        onClick={handleToggle}
        style={{
          backgroundColor: "#5F259F",
          position: "fixed",
          right: 20,
          bottom: 20,
          zIndex: 999,
        }}
      >
        {isWidgetActive ? (
          <XCircle color="#fff" />
        ) : (
          <MessageSquare color="#fff" />
        )}
      </button>
    );
  }

  function renderChatMessage(i, item) {
    return (
      <div className="rcw-message" key={i}>
        <div
          className={
            item.messageType !== "operatorAnswer"
              ? "rcw-client"
              : "rcw-response"
          }
        >
          <div
            className="rcw-message-text"
            style={{
              backgroundColor:
                item && (item.attachment || item.options) ? "#fff" : "#a3eaf7",
              padding: item && (item.attachment || item.options) ? "unset" : 15,
            }}
          >
            <p style={{ color: "#000", fontWeight: 400 }}>{item.text}</p>
          </div>
          <span className="rcw-timestamp">
            {moment(item.createdAt).format("DD.MM.YYYY hh:mm")}
          </span>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div id="custom-chat-widget-new">
        <div
          id="modalChat"
          className="rcw-conversation-container ecw-hidden"
          aria-live="polite"
        >
          {renderChatHeader()}
          <div
            id="messages"
            className="rcw-messages-container"
            style={{
              height: chatId ? "50vh" : "auto",
              backgroundColor: darkMode ? "unset" : "#fff",
            }}
            ref={messageEl}
          >
            {!chatId && showThanksMessage && renderChatThanksSection()}
            {!chatId && !showThanksMessage && requireForm && renderChatForm()}
            {!requireForm && botMessages && botMessages.map(renderBotMessages)}
            {chatId &&
              !requireForm &&
              messages.map((item, i) => renderChatMessage(i, item))}
            {loading && !requireForm && renderChatLoading()}
          </div>
          {chatId && !requireForm && renderMessageSendElements()}
        </div>
      </div>
      {renderOpenCloseChatButton()}
    </Fragment>
  );
}

export default ChatWidget;
