export const ReintegrationAppModel = {
  firstName: "",
  lastName: "",
  birthDate: null,
  birthPlace: "",
  email: "",
  telephone: "",
  whatsapp: "",
  docAttachments: null,
  address: "",
  notes: "",
  otherDocsAttachments: null,
  noDocAvailable: false,
  noDocAvailableNote: "",
  correctnessConfirmed: false,
  residenceStartDate: null,
};

export const CitizenshipAppModel = {
  reintegrationAppId: null,
  residenceStartDate: null,
  docAttachments: null,
  arDocAttachment: null,
  birthCertificateDocAttachment: null,
  hasOtherCitizenships: false,
  citizenships: null,
  parents: null,
  notes: "",
  otherDocsAttachments: null,
  correctnessConfirmed: false,
};

export const ResidenceAppModel = {
  reintegrationAppId: null,
  residenceStartDate: null,
  hasOtherCitizenships: false,
  citizenships: null,
  notes: "",
  otherDocsAttachments: null,
  correctnessConfirmed: false,
};
