import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import BlockUi from "react-block-ui";
// import ReCAPTCHA from "react-google-recaptcha";
import DatePicker from "react-multi-date-picker";
import { loadCaptchaEnginge, validateCaptcha } from "react-simple-captcha";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
// CUSTOM IMPORTS
import {
  convertBase64,
  validateEmail,
  // validatePhone,
} from "../../utils/common";
import {
  contactTypes,
  docTypes,
  docTypesNumber,
  months,
  weekDays,
} from "../../utils/constants";
import { ReintegrationAppModel } from "../../utils/models";
import {
  getCurrentLang,
  translate,
} from "../../services/translation/translation.service";
import ReactSimpleCaptcha from "../ReactSimpleCaptcha";
import { createReintegrationApp } from "../../services/api.service";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import DocumentView from "../DocumentView";
// import GenericPdfDownloader from "../../utils/genericPdfDownloader";

function ReintegrationAppForm() {
  // const captchaRegRef = useRef(null);
  const [loading, setLoading] = useState(false);
  // const [contactType, setContactType] = useState("");
  // const [contactValue, setContactValue] = useState("");
  const [selectedDocType, setsSelectedDocType] = useState(0);
  const [selectedDocAttachments, setSelectedDocAttachments] = useState([]);
  const [notifyModal, setNotifyModal] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [otherDocsAttachmentList, setOtherDocsAttachmentList] = useState([]);
  const [otherDocsAttachmentListError, setOtherDocsAttachmentListError] =
    useState("");
  const [reintegrationNumber, setReintegrationNumber] = useState(undefined);
  // const [reintegrationResponse, setReintegrationResponse] = useState("");

  const { width } = useWindowDimensions();

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = translate(translate("required"));
    } else if (values.firstName.length > 50) {
      errors.firstName = translate("validation_text_mustbe_50");
    }

    if (!values.lastName) {
      errors.lastName = translate("required");
    } else if (values.lastName.length > 50) {
      errors.lastName = translate("validation_text_mustbe_50");
    }

    if (!values.birthDate) {
      errors.birthDate = translate("required");
    }

    if (!values.birthPlace) {
      errors.birthPlace = translate("required");
    }

    if (!values.telephone) {
      errors.telephone = translate("required");
    }

    if (!values.email && !values.whatsapp) {
      errors.contacts = translate("Email or WhatsApp number is required");
    } else if (values.email && !validateEmail(values.email)) {
      errors.contacts = translate("please_add_valid_email");
    }

    if (!values.address) {
      errors.address = translate("required");
    } else if (values.address.length > 100) {
      errors.address = translate("validation_text_mustbe_100");
    }

    if (!values.residenceStartDate) {
      errors.residenceStartDate = translate("required");
    }

    if (!values.correctnessConfirmed) {
      errors.correctnessConfirmed = translate("required");
    }

    // if (!values.docType) {
    //   errors.docType = translate("required");
    // }

    if (
      !values.noDocAvailable &&
      (!values.docAttachments || values.docAttachments.length == 0)
    ) {
      errors.docAttachments = translate("required");
    } else if (values.noDocAvailable && !values.noDocAvailableNote) {
      errors.noDocAvailableNote = translate("required");
    }

    // if (!values.contacts || values.contacts.length == 0) {
    //   errors.contacts = translate("required");
    // }

    return errors;
  };

  const formik = useFormik({
    initialValues: ReintegrationAppModel,
    validate,
    onSubmit: (values) => {
      // Validate ReactSimpleCaptcha
      if (checkCaptcha()) {
        save(values);
      }

      // Validate Google ReCaptcha V2
      // const token = captchaRegRef.current.getValue();
      // if (!token) {
      //   toast.error(translate("google_recaptcha_is_required"));
      //   captchaRegRef.current.reset();
      //   return;
      // }
      // save(values, token);
      // captchaRegRef.current.reset();
    },
  });

  const addDocAttachments = () => {
    if (!selectedDocType) {
      toast(translate("please_select_doctype"));
      return;
    } else if (
      selectedDocType &&
      (!selectedDocAttachments || selectedDocAttachments.length == 0)
    ) {
      toast(translate("please_add_documents"));
      return;
    } else {
      const obj = {
        docType: selectedDocType,
        docAttachments: selectedDocAttachments,
      };
      const existsDocs = formik.values?.docAttachments || [];

      existsDocs.push(obj);
      formik.setValues({ ...formik.values, docAttachments: existsDocs });
      setsSelectedDocType("");
      setSelectedDocAttachments([]);
      document.getElementById("docAttachments").value = "";
    }
  };

  const removeDocAttachments = (index) => {
    const existsDocs = formik.values?.docAttachments || [];
    existsDocs.splice(index, 1);
    formik.setValues({ ...formik.values, docAttachments: existsDocs });
  };

  const addOtherDocsAttachments = async () => {
    const files = [...otherDocsAttachmentList];
    let supportedFiles = true;
    let fileLimit = true;

    files.forEach((f) => {
      const { type, size } = f;
      const fileSize = size / 1024 / 1024;
      if (type === "application/pdf" || type.startsWith("image/")) {
        supportedFiles = true;
      } else {
        supportedFiles = false;
        setOtherDocsAttachmentListError(translate("file_not_allowed"));
      }

      if (fileSize > 2) {
        fileLimit = false;
        setOtherDocsAttachmentListError(translate("file_size_limit"));
      }
    });

    if (supportedFiles && fileLimit) {
      setOtherDocsAttachmentListError("");

      const promises = files.map(async (f) => {
        const base64 = await convertBase64(f);
        return base64;
      });
      const promiseRes = await Promise.all(promises);

      const fileObj = {
        docAttachments: promiseRes,
      };

      let existsDocs = formik.values?.otherDocsAttachments || [];
      existsDocs.push(fileObj);
      formik.setValues({ ...formik.values, otherDocsAttachments: existsDocs });

      setOtherDocsAttachmentList([]);
      document.getElementById("otherDocsAttachments").value = "";
    }
  };

  const removeOtherDocsAttachments = (index) => {
    const existsDocs = formik.values?.otherDocsAttachments || [];
    existsDocs.splice(index, 1);
    formik.setValues({ ...formik.values, otherDocsAttachments: existsDocs });
  };

  // const addContact = () => {
  //   {
  //     if (!contactType) {
  //       toast("please_select_contact_type");
  //       return;
  //     } else if (!contactValue) {
  //       toast("please_add_value_to_selected_contact_type");
  //       return;
  //     } else if (contactType == "1" && !validateEmail(contactValue)) {
  //       toast("please_add_valid_email");
  //       return;
  //     } else if (
  //       (contactType == "2" || contactType == "3") &&
  //       !validatePhone(contactValue)
  //     ) {
  //       toast("please_add_valid_number");
  //       return;
  //     } else {
  //       const contactObj = { type: contactType, value: contactValue };
  //       const existsContacts = formik.values?.contacts || [];

  //       if (
  //         existsContacts.find((x) => x && x.type == contactObj.type)?.value ==
  //         contactObj.value
  //       ) {
  //         toast(translate("Already exist"));
  //         return;
  //       } else {
  //         existsContacts.push(contactObj);
  //         formik.setValues({ ...formik.values, contacts: existsContacts });
  //         setContactType("");
  //         setContactValue("");
  //       }
  //     }
  //   }
  // };

  // const removeContact = (index) => {
  //   const existsContacts = formik.values?.contacts || [];
  //   existsContacts.splice(index, 1);
  //   formik.setValues({ ...formik.values, contacts: existsContacts });
  // };

  const checkCaptcha = () => {
    let userCaptchaInput = document.getElementById("rap-captcha");
    if (userCaptchaInput) {
      let captchaValue = userCaptchaInput.value;
      if (captchaValue) {
        if (validateCaptcha(captchaValue) === true) {
          loadCaptchaEnginge(6);
          userCaptchaInput.value = "";
          return true;
        } else {
          toast.error(translate("Captcha Does Not Match"));
          userCaptchaInput.value = "";
          return false;
        }
      } else {
        toast.error(translate("Captcha is required"));
        return false;
      }
    }
  };

  const save = async (dataObj) => {
    try {
      setLoading(true);
      const { telephone, email, whatsapp, ...rest } = dataObj;

      // const formObj = new FormData();

      // formObj.append("firstName", firstName);
      // formObj.append("lastName", lastName);
      // formObj.append("birthDate", birthDate);
      // formObj.append("address", address);
      // formObj.append("contacts", contacts);
      // formObj.append("docType", docType);
      // formObj.append("notes", notes);

      const contacts = [];
      let reqObj = { ...rest };

      if (telephone) {
        contacts.push({
          type: contactTypes.Telephone,
          value: String(telephone),
        });
      }
      if (email) {
        contacts.push({ type: contactTypes.Email, value: String(email) });
      }
      if (whatsapp) {
        contacts.push({ type: contactTypes.WhatsApp, value: String(whatsapp) });
      }

      reqObj.contacts = contacts;

      // if (reqObj.docType) {
      //   reqObj.docType = Number(reqObj.docType);
      // }

      if (reqObj.docAttachments && Array.isArray(reqObj.docAttachments)) {
        reqObj.docAttachments = reqObj.docAttachments.map((x) => ({
          docType: Number(x.docType),
          base64Contents: x.docAttachments.map((item) => item.base64),
        }));
        // const list = [];
        // const mappedDocs = (reqObj.docAttachments = reqObj.docAttachments.map(
        //   (item) => item.base64
        // ));
        // const obj = { docType: reqObj.docType, base64Content: mappedDocs };
        // list.push(obj);
        // reqObj.docAttachments = mappedDocs;
        // delete reqObj.docType;
        // formObj.append("docAttachments", mappedResult);
        // for (const file of docAttachments) {
        //   formObj.append("docAttachments", file);
        // }
      }

      if (
        reqObj.otherDocsAttachments &&
        Array.isArray(reqObj.otherDocsAttachments)
      ) {
        reqObj.otherDocsAttachments = reqObj.otherDocsAttachments
          .map((item) => item.docAttachments)
          .flat(1);
      }

      if (reqObj.birthDate) {
        reqObj.birthDate = new Date(reqObj.birthDate);
      }

      if (reqObj.residenceStartDate) {
        reqObj.residenceStartDate = new Date(reqObj.residenceStartDate);
      }

      const res = await createReintegrationApp(reqObj);

      if (res?.value && res?.success) {
        const reIntNumber = res?.value?.reintegrationNumber;
        setReintegrationNumber(reIntNumber);
        toggleNotifyModal();
        formik.handleReset();
        setsSelectedDocType(0);
        const docHtmlElem = document.getElementById("docAttachments");
        if (docHtmlElem) {
          docHtmlElem.value = "";
        }
      } else if (res?.statusCode == 16 && !res?.success) {
        // setReintegrationResponse(res?.value);
        toast.info(translate("person_already_registered"));
      } else if (res?.statusCode == 10 && !res?.success) {
        toast.info(translate("google_recaptcha_is_invalid"));
      } else {
        toast.error(translate("an_error_occurred"));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(translate("An error occurred"));
      console.log("ReintegrationAppForm save error: ", error);
    }
  };

  const toggleNotifyModal = () => {
    if (notifyModal) {
      setReintegrationNumber(undefined);
      // setReintegrationResponse("");
    }
    setNotifyModal(!notifyModal);
  };

  const handleFileRead = async (event) => {
    const files = [...event.target.files];
    let supportedFiles = true;
    let fileLimit = true;

    files.forEach((f) => {
      const { type, size } = f;
      const fileSize = size / 1024 / 1024;

      if (type === "application/pdf" || type.startsWith("image/")) {
        supportedFiles = true;
      } else {
        supportedFiles = false;
        setFileErrorMessage(translate("file_not_allowed"));
      }

      if (fileSize > 2) {
        fileLimit = false;
        setFileErrorMessage(translate("file_size_limit"));
      }
    });

    if (supportedFiles && fileLimit) {
      setFileErrorMessage("");
      const promises = files.map(async (f) => {
        const { type, name } = f;
        const base64 = await convertBase64(f);
        return { base64, name, type };
      });

      Promise.all(promises).then((res) => {
        setSelectedDocAttachments(res);
        // formik.setValues({
        //   ...formik.values,
        //   docAttachments: res,
        // });
      });
    }
  };

  const clearForm = () => {
    setsSelectedDocType(0);
    setSelectedDocAttachments([]);
    formik.handleReset();
    if (document.getElementById("otherDocsAttachments")) {
      document.getElementById("otherDocsAttachments").value = "";
    }
  };

  // function previewDocuments(list) {
  //   if (Array.isArray(list)) {
  //     return list.map((file, i) =>
  //       file.type.startsWith("image/") ? (
  //         <div key={i}>
  //           <img
  //             src={file.base64}
  //             className="img-thumbnail"
  //             style={{ maxWidth: 100, margin: 2 }}
  //           />
  //         </div>
  //       ) : (
  //         <div key={i}>
  //           <a href={file.base64} style={{ margin: 2 }}>
  //             {file.name}
  //           </a>
  //         </div>
  //       )
  //     );
  //   }
  // }

  function renderNotifyModal() {
    return (
      <Modal isOpen={notifyModal} toggle={toggleNotifyModal} backdrop="static">
        {/* <ModalHeader toggle={toggleNotifyModal}>
          <h3 style={{ color: "red" }}>
            <i className="fa fa-exclamation-triangle"></i>{" "}
            {translate("notification_no")}:{" "}
            <b style={{ color: "#000" }}>{reintegrationNumber || ""}</b>
          </h3>
        </ModalHeader> */}
        <ModalBody>
          <div className="container" id="print-doc">
            <br />
            {/* <h3 style={{ color: "red", textAlign: "center" }}>
              <i className="fa fa-exclamation-triangle"></i>{" "}
              {reintegrationNumber && (
                <>
                  {translate("notification_no")}:{" "}
                  <b style={{ color: "#000" }}>{reintegrationNumber}</b>
                </>
              )}
            </h3>
            <br /> */}
            <div className="row align-items-center">
              {reintegrationNumber && (
                <div className="col-lg-10 offset-lg-1 mt-4 mb-4 ">
                  <h4 className="green mb-2"> {translate("app_sent")}</h4>
                  <p className="modal-content-p">
                    {" "}
                    {translate("success_message_reinteg_app")}{" "}
                  </p>
                </div>
              )}
              {/* {reintegrationResponse && (
                <h4 className="text-center">
                  {translate("person_already_registered")}
                </h4>
              )} */}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {/* {reintegrationNumber && (
            <GenericPdfDownloader
              downloadFileName={`reintegrationNumber_${new Date().toISOString()}`}
              rootElementId="print-doc"
            />
          )} */}
          <Button color="secondary" onClick={toggleNotifyModal}>
            <i className="fa fa-times"></i> {translate("close")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  return (
    <div>
      <div className="container tab-con-desc">
        <div className="row ">
          <div className="col-lg-12 ">
            {" "}
            <h6> {translate("Fərdi məlumatlarınız qorunur!")} </h6>{" "}
          </div>
        </div>
      </div>
      <div className="container">
        <BlockUi tag="div" blocking={loading}>
          <div className="row ">
            <div className="col-lg-12 mt-3">
              <form onSubmit={formik.handleSubmit}>
                <div className="row mb-4">
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form3Example1">
                        {translate("firstName")} <span className="red">* </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...formik.getFieldProps("firstName")}
                      />
                      {formik.errors.firstName ? (
                        <small className="red">{formik.errors.firstName}</small>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form3Example1">
                        {translate("lastName")} <span className="red">* </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...formik.getFieldProps("lastName")}
                      />
                      {formik.errors.lastName ? (
                        <small className="red">{formik.errors.lastName}</small>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form3Example1">
                        {translate("birthDate")} <span className="red">* </span>
                      </label>
                      <DatePicker
                        weekDays={weekDays[getCurrentLang()]}
                        months={months[getCurrentLang()]}
                        placeholder="__.__.____"
                        className={"form-control"}
                        format="DD.MM.YYYY"
                        value={formik.values.birthDate || "__.__.____"}
                        onChange={(date) => {
                          formik.setValues({
                            ...formik.values,
                            birthDate: date,
                          });
                        }}
                      />
                      {formik.errors.birthDate ? (
                        <small className="red">{formik.errors.birthDate}</small>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
                    <div className="form-outline">
                      <label className="form-label">
                        {translate("Ünvan məlumatları")}{" "}
                        <span className="red">* </span>
                      </label>
                      <input
                        type="text"
                        id="form3Example2"
                        className="form-control"
                        {...formik.getFieldProps("address")}
                      />
                      {formik.errors.address ? (
                        <small className="red">{formik.errors.address}</small>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                    <div className="form-outline">
                      <label className="form-label">
                        {translate("Telephone")}
                        <span className="red">* </span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        {...formik.getFieldProps("telephone")}
                      />
                      {/* {formik.errors.telephone ? (
                        <small className="red">{formik.errors.telephone}</small>
                      ) : null} */}
                    </div>
                    {formik.errors.telephone ? (
                      <small className="red">{formik.errors.telephone}</small>
                    ) : null}
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                    <div className="form-outline">
                      <label className="form-label">{translate("email")}</label>
                      <input
                        type="email"
                        className="form-control"
                        {...formik.getFieldProps("email")}
                      />
                      {/* {formik.errors.email ? (
                        <small className="red">{formik.errors.email}</small>
                      ) : null} */}
                    </div>
                    {formik.errors.contacts ? (
                      <small className="red">{formik.errors.contacts}</small>
                    ) : null}
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                    <div className="form-outline">
                      <label className="form-label">
                        {translate("WhatsApp")}
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        {...formik.getFieldProps("whatsapp")}
                      />
                      {/* {formik.errors.whatsapp ? (
                        <small className="red">{formik.errors.whatsapp}</small>
                      ) : null} */}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                    <div
                      className="form-outline"
                      // style={{ marginTop: width > 1000 ? -20 : 0 }}
                    >
                      <label className="form-label">
                        {translate("Qarabağda məskunlaşdığınız tarix")}{" "}
                        <span className="red">* </span>{" "}
                      </label>
                      <DatePicker
                        weekDays={weekDays[getCurrentLang()]}
                        months={months[getCurrentLang()]}
                        placeholder="__.__.____"
                        className={"form-control"}
                        format="DD.MM.YYYY"
                        value={formik.values.residenceStartDate || "__.__.____"}
                        onChange={(date) => {
                          formik.setValues({
                            ...formik.values,
                            residenceStartDate: date,
                          });
                        }}
                      />
                      {formik.errors.residenceStartDate ? (
                        <small className="red">
                          {formik.errors.residenceStartDate}
                        </small>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                    <div className="form-outline">
                      <label className="form-label">
                        {translate("birthplace")}
                        <span className="red">* </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...formik.getFieldProps("birthPlace")}
                      />
                      {formik.errors.birthPlace ? (
                        <small className="red">
                          {formik.errors.birthPlace}
                        </small>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* <div className="row mb-4">
                  <div className="col-lg-5 col-md-5 mb-2">
                    <div className="form-outline">
                      <label className="form-label">
                        {translate("Əlaqə növü")}{" "}
                        <span className="red">* </span>{" "}
                      </label>
                      <select
                        className="form-control "
                        id="contactType"
                        name="contactType"
                        value={contactType || ""}
                        onChange={(e) => {
                          setContactValue("");
                          setContactType(e.target.value);
                        }}
                      >
                        {Object.entries(contactTypes).map(([key, value]) => (
                          <option key={value} value={value}>
                            {translate(key)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 mb-2">
                    <div className="form-outline">
                      <label className="form-label">
                        {contactType == "2" || contactType == "3"
                          ? translate(`Telefon (ev, mobil, iş, digər)`)
                          : contactType == "1"
                          ? translate("Email")
                          : translate("Choose contact type")}
                      </label>

                      {contactType == "2" || contactType == "3" ? (
                        <PhoneInput
                          defaultCountry="AZ"
                          placeholder={translate("Enter phone number")}
                          value={contactValue}
                          onChange={(v) => setContactValue(v)}
                          disabled={contactType != "2" && contactType != "3"}
                        />
                      ) : (
                        <input
                          type="email"
                          disabled={contactType != "1"}
                          className="form-control"
                          name="contactValue"
                          value={contactValue}
                          onChange={(e) => setContactValue(e.target.value)}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-2 mb-2">
                    <label className="form-label"></label>
                    <div onClick={addContact}>
                      <span
                        className="custom-send-button"
                        style={{ width: width < 600 && "30%" }}
                      >
                        {" "}
                        +{" "}
                      </span>
                    </div>
                  </div>
                  {formik.errors.contacts ? (
                    <small className="red">{formik.errors.contacts}</small>
                  ) : null}

                  <div className="col-lg-12">
                    {formik.values.contacts &&
                    Array.isArray(formik.values.contacts) &&
                    formik.values.contacts.length ? (
                      <Table size="sm" striped>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>{translate("Type")}</th>
                            <th>{translate("Value")}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {formik.values.contacts.map((item, i) => (
                            <tr key={i}>
                              <th scope="row">{i + 1}</th>
                              <td>
                                {translate(contactTypesNumber[item.type])}
                              </td>
                              <td>{item.value}</td>
                              <td>
                                <i
                                  className="fa fa-trash"
                                  style={{ color: "red", cursor: "pointer" }}
                                  onClick={() => removeContact(i)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : null}
                  </div>
                </div> */}
                <div className="row mb-4">
                  <div className="col-sm-12 col-lg-4 mb-2">
                    <div className="form-outline">
                      <label className="form-label">
                        {translate("Şəxsiyyəti təsdiq edən sənəd")}{" "}
                        <span className="red">* </span>{" "}
                      </label>
                      <select
                        className="form-control"
                        name="docType"
                        onChange={(e) => {
                          setsSelectedDocType(e.target.value);
                          if (e.target.value == 5) {
                            formik.setValues({
                              ...formik.values,
                              noDocAvailable: true,
                            });
                          } else {
                            formik.setValues({
                              ...formik.values,
                              noDocAvailable: false,
                            });
                          }
                        }}
                        value={selectedDocType}
                      >
                        {Object.entries(docTypes).map(([key, value]) => (
                          <option key={value} value={value}>
                            {translate(key)}
                          </option>
                        ))}
                      </select>
                    </div>
                    {formik.errors.docAttachments ? (
                      <small className="red">
                        {formik.errors.docAttachments}
                      </small>
                    ) : null}
                  </div>
                  {selectedDocType == 5 ? (
                    <div className="col-sm-12 col-lg-8 mb-2">
                      <div className="form-outline">
                        <label className="form-label">
                          {translate("Why are you undocumented")}
                          <span className="red">* </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          {...formik.getFieldProps("noDocAvailableNote")}
                        />
                      </div>
                      {formik.errors.noDocAvailableNote ? (
                        <small className="red">
                          {formik.errors.noDocAvailableNote}
                        </small>
                      ) : null}
                    </div>
                  ) : (
                    <>
                      <div className="col-sm-12 col-lg-6 mb-2">
                        <div className="form-outline">
                          <label className="form-label">
                            {" "}
                            {translate("upload_copy")}
                            <span className="red">* </span>
                          </label>
                          <div className="form-control">
                            <label htmlFor="docAttachments">
                              {document.getElementById("docAttachments")
                                ?.value ? (
                                <>
                                  {translate("selected_files")}
                                  {" - "}
                                  {
                                    Object.keys(
                                      document.getElementById("docAttachments")
                                        ?.files
                                    ).length
                                  }
                                </>
                              ) : (
                                <>
                                  <i className="fa fa-upload"></i>{" "}
                                  <span style={{ color: "lightgray" }}>
                                    (PDF, JPG, PNG)
                                  </span>
                                  {/* {translate("Upload file")} */}
                                </>
                              )}
                            </label>
                            <input
                              style={{ display: "none" }}
                              className="form-control"
                              type="file"
                              multiple
                              id="docAttachments"
                              name="docAttachments"
                              onChange={(e) => handleFileRead(e)}
                              accept="application/pdf,image/png, image/jpeg"
                            />
                          </div>
                        </div>
                        {formik.errors.docAttachments ? (
                          <small className="red">
                            {formik.errors.docAttachments}
                          </small>
                        ) : null}
                        {fileErrorMessage ? (
                          <small className="red">{fileErrorMessage}</small>
                        ) : null}
                      </div>
                      <div
                        className="col-sm-12 col-lg-2 col-md-2 mb-2"
                        style={
                          width < 600
                            ? { paddingTop: "2%" }
                            : {
                                display: "flex",
                                alignItems: "end",
                                // paddingBottom: "1%",
                              }
                        }
                      >
                        <div className="form-outline">
                          <div
                            className="send-button"
                            onClick={addDocAttachments}
                          >
                            <span
                              className="custom-send-button"
                              style={width < 600 ? { width: 120 } : {}}
                            >
                              {translate("add")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {/* {formik.errors.docAttachments ? (
                    <small className="red">
                      {formik.errors.docAttachments}
                    </small>
                  ) : null} */}
                  <div className="col-lg-12">
                    {formik.values.docAttachments &&
                    Array.isArray(formik.values.docAttachments) &&
                    formik.values.docAttachments.length ? (
                      <Table size="sm" striped>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>{translate("docType")}</th>
                            <th>{translate("Documents")}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {formik.values.docAttachments.map((item, i) => (
                            <tr key={i}>
                              <th scope="row">{i + 1}</th>
                              <td>{translate(docTypesNumber[item.docType])}</td>
                              <td>
                                {item.docAttachments && (
                                  <DocumentView
                                    list={item.docAttachments}
                                    style="inline"
                                    fileWidth={50}
                                  />
                                )}
                              </td>
                              <td>
                                <i
                                  className="fa fa-trash"
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => removeDocAttachments(i)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : null}
                  </div>
                </div>
                {/* <div className="row mb-4">
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-2">
                    <div className="form-outline">
                      <label className="form-label">
                        {translate("Şəxsiyyəti təsdiq edən sənəd")}{" "}
                        <span className="red">* </span>{" "}
                      </label>
                      <select
                        className="form-control"
                        {...formik.getFieldProps("docType")}
                      >
                        {Object.entries(docTypes).map(([key, value]) => (
                          <option key={value} value={value}>
                            {translate(key)}
                          </option>
                        ))}
                      </select>
                      {formik.errors.docType ? (
                        <small className="red">{formik.errors.docType}</small>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-2">
                    <div className="form-outline">
                      <label className="form-label">
                        {" "}
                        {translate("Fayl seçin")}
                      </label>
                      <div className="form-control">
                        <label htmlFor="docAttachments">
                          <i className="fa fa-upload"></i>{" "}
                          {translate("Upload file")}
                        </label>
                        <input
                          style={{ display: "none" }}
                          className="form-control"
                          type="file"
                          multiple
                          id="docAttachments"
                          name="docAttachments"
                          onChange={(e) => handleFileRead(e)}
                          accept="application/pdf,image/png, image/gif, image/jpeg"
                        />
                      </div>
                    </div>

                    {formik.errors.docAttachments ? (
                      <small className="red">
                        {formik.errors.docAttachments}
                      </small>
                    ) : null}
                  </div>
                  <div>{previewDocuments(formik.values.docAttachments)}</div>
                </div> */}
                <div className="row mb-4">
                  <div className="col-sm-12 col-lg-10 mb-2">
                    <div className="form-outline">
                      <label className="form-label">
                        {translate("copy_other_docs")}
                      </label>
                      <div className="form-control">
                        <label htmlFor="otherDocsAttachments">
                          {document.getElementById("otherDocsAttachments")
                            ?.value ? (
                            <>
                              {translate("selected_files")}
                              {" - "}
                              {
                                Object.keys(
                                  document.getElementById(
                                    "otherDocsAttachments"
                                  )?.files
                                ).length
                              }
                            </>
                          ) : (
                            <>
                              <i className="fa fa-upload"></i>{" "}
                              <span style={{ color: "lightgray" }}>
                                (PDF, JPG, PNG)
                              </span>
                              {/* {translate("Upload file")} */}
                            </>
                          )}
                        </label>
                        <input
                          style={{ display: "none" }}
                          className="form-control"
                          type="file"
                          multiple
                          id="otherDocsAttachments"
                          name="otherDocsAttachments"
                          onChange={
                            (e) => setOtherDocsAttachmentList(e.target.files)
                            // handleFileRead(e, "otherDocsAttachments")
                          }
                          accept="application/pdf,image/png, image/jpeg"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-lg-2 col-md-2 mb-2"
                    style={{
                      display: "flex",
                      alignItems: "end",
                      justifyContent: width < 600 ? "center" : "flex-start",
                    }}
                  >
                    <div
                      className="send-button"
                      onClick={addOtherDocsAttachments}
                    >
                      <span
                        className="custom-send-button"
                        style={width < 600 ? { width: 120 } : {}}
                      >
                        {translate("add")}
                      </span>
                    </div>
                  </div>
                  {otherDocsAttachmentListError ? (
                    <small className="red">
                      {otherDocsAttachmentListError}
                    </small>
                  ) : null}
                  <div>
                    {formik.values.otherDocsAttachments &&
                    Array.isArray(formik.values.otherDocsAttachments) &&
                    formik.values.otherDocsAttachments.length ? (
                      <Table size="sm" striped>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>{translate("Documents")}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {formik.values.otherDocsAttachments.map((item, i) => (
                            <tr key={i}>
                              <th scope="row">{i + 1}</th>
                              <td>
                                {item.docAttachments && (
                                  <DocumentView
                                    list={item.docAttachments}
                                    style="inline"
                                    fileWidth={50}
                                  />
                                )}
                              </td>
                              <td>
                                <i
                                  className="fa fa-trash"
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => removeOtherDocsAttachments(i)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : null}
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col mb-2">
                    <div className="form-outline">
                      <label className="form-label">
                        {translate("Əlavə qeyd etmək istədiyiniz məlumatlar")}
                      </label>
                      <textarea
                        className="form-control"
                        rows="5"
                        {...formik.getFieldProps("notes")}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="row mb-4">
                  <div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={formik.values.correctnessConfirmed}
                        onChange={(e) => {
                          if (e.target.checked) {
                            formik.setValues({
                              ...formik.values,
                              correctnessConfirmed: true,
                            });
                          } else {
                            formik.setValues({
                              ...formik.values,
                              correctnessConfirmed: false,
                            });
                          }
                        }}
                      />
                      <label className="form-check-label">
                        {translate("confirm_the_correctness")}
                      </label>
                    </div>
                    {formik.errors.correctnessConfirmed ? (
                      <small className="red">
                        {formik.errors.correctnessConfirmed}
                      </small>
                    ) : null}
                  </div>
                  <div className="row mt-4 mb-4">
                    {/* <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY}
                      ref={captchaRegRef}
                    /> */}
                    <div className="col-12 mb-2">
                      <ReactSimpleCaptcha id="rap-captcha" />
                    </div>
                  </div>
                  <div
                    className="col-lg-8 col-md-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: width < 600 ? "center" : "flex-start",
                    }}
                  >
                    <button
                      onClick={clearForm}
                      className="custom-clear-button"
                      style={{ marginRight: 10 }}
                    >
                      {" "}
                      {translate("clear")}{" "}
                    </button>
                    <button type="submit" className="custom-send-button">
                      {" "}
                      {translate("send")}{" "}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </BlockUi>
      </div>
      {renderNotifyModal()}
    </div>
  );
}

export default ReintegrationAppForm;
