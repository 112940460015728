import React, { useState } from "react";
import Lightbox from "react-awesome-lightbox";

import pdfIcon from "../assets/img/PDF_file_icon.png";

import "react-awesome-lightbox/build/style.css";

function DocumentView({ list, style = "inline", fileWidth = 100 }) {
  let mappedList = [];
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");

  const toggleLightBox = () => setLightBoxOpen(!lightBoxOpen);

  const viewFile = (f) => {
    setSelectedFile(f);
    setTimeout(() => {
      toggleLightBox();
    }, 100);
  };

  const firstItem = list[0];

  if (firstItem && typeof firstItem === "object") {
    mappedList = list.map((item) => item.base64);
  } else {
    mappedList = list;
  }

  return (
    <>
      {style === "inline"
        ? mappedList.map((file, i) =>
            file.startsWith("data:image/") ? (
              <img
                key={i}
                src={file}
                onClick={() => viewFile(file)}
                className="img-thumbnail"
                style={{ maxWidth: fileWidth, margin: 2, cursor: "pointer" }}
              />
            ) : (
              <a download key={i} href={file} style={{ margin: 2 }}>
                <img
                  src={pdfIcon}
                  style={{ maxWidth: fileWidth, margin: 2, cursor: "pointer" }}
                />
              </a>
            )
          )
        : mappedList.map((file, i) =>
            file.startsWith("data:image/") ? (
              <div key={i}>
                <img
                  src={file}
                  onClick={() => viewFile(file)}
                  className="img-thumbnail"
                  style={{ maxWidth: fileWidth, margin: 2, cursor: "pointer" }}
                />
              </div>
            ) : (
              <div key={i}>
                <a
                  download
                  href={file}
                  style={{ margin: 2, cursor: "pointer" }}
                >
                  <img
                    src={pdfIcon}
                    style={{
                      maxWidth: fileWidth,
                      margin: 2,
                      cursor: "pointer",
                    }}
                  />
                </a>
              </div>
            )
          )}
      {lightBoxOpen && (
        <Lightbox
          onClose={toggleLightBox}
          image={selectedFile}
          //   images={mappedList.map((item, i) => ({
          //     url: item,
          //     title: `File ${i + 1}`,
          //   }))}
        />
      )}
    </>
  );
}

export default DocumentView;
