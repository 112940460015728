import io from "socket.io-client";
import { crmApiCreate } from "./api.service";

const apiUrl =
  process.env.REACT_APP_socketUrl || "https://test.reintegration.gov.az";
let _socket;

const registerSocketEvents = (socket) => {
  socket.on("connect", () => {
    console.log("socket connected");
  });
  socket.on("disconnect", () => {
    console.log("socket disconnected");
  });
  socket.on("error", (error) => console.log("socket error", error));
  socket.on("connect_error", (err) => {
    console.log("socket connect_error", err.message);
    console.log("socket connect_error", err.data);
  });
};

class SocketService {
  get socket() {
    return _socket;
  }

  connect = async () => {
    try {
      let token = "";
      const sessionToken = sessionStorage.getItem("usr_tkn");
      if (sessionToken) {
        token = sessionToken;
      } else {
        const tokenResponse = await crmApiCreate("get-chat-token");
        token = tokenResponse?.data;
        sessionStorage.setItem("usr_tkn", token);
      }

      if (token) {
        _socket = io(apiUrl, {
          query: `auth_token=${token}`,
        });
        registerSocketEvents(this.socket);
      } else {
        console.log("Socket connect failed. Token not found");
      }
    } catch (error) {
      console.log("Socket connect error", error);
    }
  };
}

export const socketService = new SocketService();
