import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCountryList } from "../../services/api.service";

const initialState = {
  loading: false,
  countries: [],
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchCountries = createAsyncThunk(
  "registration/fetchCountries",
  async () => {
    const response = await getCountryList();
    // The value we return becomes the `fulfilled` action payload
    return response.list;
  }
);

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.loading = false;
        state.countries = action.payload;
      });
  },
});

export const {} = registrationSlice.actions;

export default registrationSlice.reducer;
