import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import Markdown from "react-markdown";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import RegistrationFooter from "../components/Registration/RegistrationFooter";
import RegistrationHeader from "../components/Registration/RegistrationHeader";
// import SocialIcons from "../components/SocialIcons";
import { crmApiGetList } from "../services/api.service";
import { crmApiNames } from "../utils/constants";
import {
  getCurrentLang,
  translate,
} from "../services/translation/translation.service";

// import ChatWidget from "../components/ChatWidget/ChatWidget";
import useWindowDimensions from "../hooks/useWindowDimensions";
// import { news } from "../utils/newsData";
import useWindowDarkMode from "../hooks/useWindowDarkMode";

function BlogPost() {
  const darkMode = useWindowDarkMode();
  const location = useLocation();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const { id } = useParams();
  const [newItemObj, setNewsItemObjObj] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isNaN(Number(id))) {
      navigate("/blog");
    } else {
      fetchNewsById();
    }
  }, []);

  async function fetchNewsById() {
    try {
      setLoading(true);
      const {
        data: {
          data: { list },
        },
      } = await crmApiGetList(crmApiNames.reintegrationNews, {
        newsId: id,
        _limit: 20,
        _sort: "newsDate:desc",
      });
      setNewsItemObjObj(list[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("fetchNews error", error);
    }
  }

  const routerData = location.state;

  window.onpopstate = () => {
    if (routerData?.router) {
      navigate(routerData.router, {
        state: routerData,
      });
    } else {
      navigate(-1);
    }
  };

  function renderModals() {
    return (
      <div>
        {/* faq modal */}
        <div
          className="modal fade"
          id="faqModal"
          tabIndex="-1"
          aria-labelledby="faqModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {translate("Ən çox verilən suallar")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body d-flex  justify-content-center">
                <div className="container">
                  <div className="row">
                    <div className="mt-4 mb-4 ">
                      <div
                        className="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingOne"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseOne"
                              aria-expanded="false"
                              aria-controls="flush-collapseOne"
                            >
                              <b>{translate("faq_1_title")}</b>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseOne"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              {translate("faq_1_desc")}
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingTwo"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseTwo"
                              aria-expanded="false"
                              aria-controls="flush-collapseTwo"
                            >
                              <b>{translate("faq_2_title")}</b>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingTwo"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              {translate("faq_2_desc")}
                            </div>
                          </div>
                        </div>
                        {/* <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingThree"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseThree"
                              aria-expanded="false"
                              aria-controls="flush-collapseThree"
                            >
                              <b>{translate("faq_3_title")}</b>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              {translate("faq_3_desc")}
                            </div>
                          </div>
                        </div> */}
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingFour"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseFour"
                              aria-expanded="false"
                              aria-controls="flush-collapseFour"
                            >
                              <b>{translate("faq_4_title")}</b>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingFour"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              {translate("faq_4_desc")}
                            </div>
                          </div>
                        </div>
                        {/* <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingFive"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseFive"
                              aria-expanded="false"
                              aria-controls="flush-collapseFive"
                              id="flush-collapseFive-btn"
                            >
                              <b>{translate("faq_5_title")}</b>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseFive"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingFive"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: translate("faq_5_desc"),
                                }}
                              />
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="send-button scroll-to-section">
                  <a href="#" data-bs-dismiss="modal" className="active">
                    {" "}
                    {translate("close")}{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* lang modal */}
        <div
          className="modal fade"
          id="LangModal"
          tabIndex="-1"
          aria-labelledby="LangModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel"></h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body d-flex  justify-content-center">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-10 offset-lg-1 mt-4 mb-4 ">
                      <div className="bui-group__item">
                        <ul
                          className="lang-box"
                          style={
                            width < 600
                              ? {
                                  display: "flex",
                                  flexDirection: "column",
                                }
                              : {}
                          }
                        >
                          <li className="">
                            <a href="tel:+994125260919">
                              <div className="lang-item">
                                <div className="lang-img">
                                  <i className="fas fa-phone"></i>
                                </div>
                                <div>{"Azərbaycan"}</div>
                              </div>
                            </a>
                          </li>

                          <li className="">
                            <a href="tel:+994125260919">
                              <div className="lang-item">
                                <div className="lang-img">
                                  <i className="fas fa-phone"></i>
                                </div>
                                <div>{"հայերեն"}</div>
                              </div>
                            </a>
                          </li>
                          <li className="">
                            <a href="tel:+994125260919">
                              <div className="lang-item">
                                <div className="lang-img">
                                  <i className="fas fa-phone"></i>
                                </div>
                                <div>{"English"}</div>
                              </div>
                            </a>
                          </li>
                          <li className="">
                            <a href="tel:+994125260919">
                              <div className="lang-item">
                                <div className="lang-img">
                                  <i className="fas fa-phone"></i>
                                </div>
                                <div>{"Русский"}</div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {/* <div className="send-button scroll-to-section">
                  <a href="#" data-bs-dismiss="modal" className="active">
                    {" "}
                    {translate("close")}{" "}
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* tab info1 modal */}
        <div
          className="modal fade"
          id="tabModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {translate("Ətraflı məlumat")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body d-flex  justify-content-center">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-10 offset-lg-1 mt-4 mb-4 ">
                      <h4> </h4>
                      <p className="modal-content-p">
                        {translate("tab_info_1")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="send-button scroll-to-section">
                  <a href="#" data-bs-dismiss="modal" className="active">
                    {" "}
                    {translate("close")}{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* tab info2 modal */}
        <div
          className="modal fade"
          id="tabModal2"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {translate("Ətraflı məlumat")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body d-flex  justify-content-center">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-10 offset-lg-1 mt-4 mb-4 ">
                      <h4> </h4>

                      <p className="modal-content-p">
                        {translate("tab_info_2")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="send-button scroll-to-section">
                  <a href="#" data-bs-dismiss="modal" className="active">
                    {" "}
                    {translate("close")}{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* tab info3 modal */}
        <div
          className="modal fade"
          id="tabModal3"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {translate("Ətraflı məlumat")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body d-flex  justify-content-center">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-10 offset-lg-1 mt-4 mb-4 ">
                      <h4> </h4>

                      <p className="modal-content-p">
                        {translate("tab_info_3")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="send-button scroll-to-section">
                  <a href="#" data-bs-dismiss="modal" className="active">
                    {" "}
                    {translate("close")}{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* tab info4 modal */}
        <div
          className="modal fade"
          id="tabModal4"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {translate("Ətraflı məlumat")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body d-flex  justify-content-center">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-10 offset-lg-1 mt-4 mb-4 ">
                      <h4> </h4>

                      <p className="modal-content-p">
                        {translate("tab_info_4")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="send-button scroll-to-section">
                  <a href="#" data-bs-dismiss="modal" className="active">
                    {" "}
                    {translate("close")}{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {translate("Ətraflı məlumat")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body d-flex  justify-content-center">
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/40NEHojzadg"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="modal-footer">
                <div className="send-button scroll-to-section">
                  <a href="#" data-bs-dismiss="modal" className="active">
                    {" "}
                    {translate("close")}{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div>
        <div>
          <RegistrationHeader path={"blog"} />

          {/* <SocialIcons /> */}

          <div
            className="custom-main-blog main-banner main-banner-page wow fadeIn mt-4"
            id="top"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            {loading && <progress style={{ width: "100%" }} />}
            {newItemObj && (
              <div className="container">
                <div className="card">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item font-weight-light">
                      <Link to={"/"} state={{ router: "/" }}>
                        {translate("home")}
                      </Link>
                    </li>{" "}
                    <li className="breadcrumb-item font-weight-bold">
                      <Link to={"/blog"} state={{ router: "/blog" }}>
                        {translate("all_news")}
                      </Link>
                    </li>{" "}
                    <li className="breadcrumb-time">
                      <time>
                        {newItemObj?.newsDate
                          ? moment(newItemObj.newsDate).format(
                              "DD.MM.YYYY HH:mm"
                            )
                          : ""}{" "}
                        <i className="far fa-calendar"></i> {newItemObj.date}
                      </time>
                    </li>
                  </ol>
                  <div className="card-body">
                    <p>
                      <img
                        src={
                          newItemObj?.image || "/assets/images/dmx-news1.jpg"
                        }
                        alt={newItemObj.newsId}
                        className="blog-post-image"
                      />
                      <h3
                        style={darkMode ? { color: "#fff" } : { color: "#000" }}
                        className="mb-2"
                      >
                        {newItemObj?.[`title_${getCurrentLang()}`]}
                      </h3>
                      <div id="markdown-area">
                        <Markdown>
                          {newItemObj?.[`content_${getCurrentLang()}`]}
                        </Markdown>
                      </div>
                    </p>
                    <time className="news-time-footer-section">
                      <hr />
                      <i className="far fa-calendar"></i>{" "}
                      {newItemObj?.newsDate
                        ? moment(newItemObj.newsDate).format("DD.MM.YYYY HH:mm")
                        : ""}
                    </time>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <RegistrationFooter />

        {renderModals()}
      </div>
      {/* <ChatWidget page="registration" /> */}
    </Fragment>
  );
}

export default BlogPost;
