import React from "react";

import useWindowDimensions from "../hooks/useWindowDimensions";

import aIcon from "../assets/img/accessibility-icon.jpg";

function AccessibilityWidget() {
  const { width } = useWindowDimensions();
  const toggleWidget = () => {
    if (
      document.querySelector(".ac-widget").classList.contains("ac-widget-aciq")
    ) {
      document.querySelector(".ac-widget").animate({ left: "-180px" }, 500);
      setTimeout(() => {
        document.querySelector(".ac-widget").classList.remove("ac-widget-aciq");
      }, 499);
    } else {
      document.querySelector(".ac-widget").animate({ left: "0" }, 500);
      setTimeout(() => {
        document.querySelector(".ac-widget").classList.add("ac-widget-aciq");
      }, 499);
    }
  };
  return (
    <div
      className="ac-widget"
      id="accessibility-widget"
      style={
        width < 600
          ? {
              bottom: 5,
            }
          : {}
      }
    >
      <a
        className="ac-widget-ac"
        href="#"
        onClick={toggleWidget}
        style={
          width < 600
            ? {
                right: -48,
              }
            : {}
        }
      >
        <img
          src={aIcon}
          alt="AccessibilityWidget"
          style={
            width < 600
              ? {
                  width: 30,
                  height: 30,
                }
              : {}
          }
        />
      </a>
      <div className="reng-btns">
        <button
          id="ac-widget-n"
          type="button"
          className="btn ac-widget-btn-color"
          onClick={() => {
            document.getElementById("bd").style.background = "#ebf0f1";
          }}
        >
          <i className="fa fa-circle" aria-hidden="true"></i>
        </button>
        <button
          id="ac-widget-a"
          type="button"
          className="btn ac-widget-btn-color"
          onClick={() => {
            document.getElementById("bd").style.background = "#000";
          }}
        >
          <i className="fa fa-circle" aria-hidden="true"></i>
        </button>
        <button
          id="ac-widget-q"
          type="button"
          className="btn ac-widget-btn-color"
          onClick={() => {
            document.getElementById("bd").style.background = "#fff";
          }}
        >
          <i
            className="fa fa-circle"
            aria-hidden="true"
            style={{
              color: "#fff",
              border: "1px solid #000",
              borderRadius: "50%",
            }}
          ></i>
        </button>
      </div>
      <div className="srift-btns">
        <button
          id="srift-plus"
          type="button"
          className="btn ac-widget-btn-color"
          onClick={() => {
            const nodeTagList = [
              "span",
              "p",
              "h1",
              "h2",
              "h3",
              "h3",
              "h4",
              "h5",
              "h6",
            ];
            function changeFontSize(element) {
              var currentSize = window
                .getComputedStyle(element, null)
                .getPropertyValue("font-size");
              if (currentSize) {
                currentSize = parseFloat(currentSize.replace("px", ""));
                if (nodeTagList.includes(element.nodeName.toLowerCase())) {
                  element.style.fontSize = `${currentSize + 2}px`;
                }
                for (var i = 0; i < element.children.length; i++) {
                  changeFontSize(element.children[i]);
                }
              }
            }
            changeFontSize(document.body);
          }}
        >
          A+
        </button>
        <button
          id="srift-minus"
          type="button"
          className="btn ac-widget-btn-color"
          onClick={() => {
            const nodeTagList = [
              "span",
              "b",
              "p",
              "h1",
              "h2",
              "h3",
              "h3",
              "h4",
              "h5",
              "h6",
            ];
            function changeFontSize(element) {
              var currentSize = window
                .getComputedStyle(element, null)
                .getPropertyValue("font-size");
              if (currentSize) {
                currentSize = parseFloat(currentSize.replace("px", ""));
                if (nodeTagList.includes(element.nodeName.toLowerCase())) {
                  element.style.fontSize = `${currentSize - 2}px`;
                }
                for (var i = 0; i < element.children.length; i++) {
                  changeFontSize(element.children[i]);
                }
              }
            }
            changeFontSize(document.body);
          }}
        >
          A-
        </button>
        <button
          id="srift-plus"
          type="button"
          className="btn ac-widget-btn-color"
          style={{ marginLeft: 5 }}
          onClick={() => {
            window.location.reload();
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}

export default AccessibilityWidget;
