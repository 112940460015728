import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import BlockUi from "react-block-ui";
import moment from "moment";
import DatePicker from "react-multi-date-picker";
// import ReCAPTCHA from "react-google-recaptcha";
import { loadCaptchaEnginge, validateCaptcha } from "react-simple-captcha";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  getCurrentLang,
  translate,
} from "../../services/translation/translation.service";
import { searchReintegrationApp } from "../../services/api.service";
import ReactSimpleCaptcha from "../ReactSimpleCaptcha";
import { months, statusesNumber, weekDays } from "../../utils/constants";

function RegistrationStatusForm() {
  // const captchaRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [notifyModal, setNotifyModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("1");
  const [appList, setAppList] = useState([]);
  const [searchModel, setSearchModel] = useState({
    firstName: null,
    lastName: null,
    birthDate: null,
    reintegrationNumber: null,
  });

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, [selectedOption]);

  const toggleNotifyModal = () => setNotifyModal(!notifyModal);

  function renderNotifyModal() {
    return (
      <Modal isOpen={notifyModal} toggle={toggleNotifyModal}>
        <ModalHeader toggle={toggleNotifyModal}>
          {translate("Ətraflı məlumat")}
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-10 offset-lg-1 mt-4 mb-4 ">
                <h4 className="green">
                  <i className="fa fa-exclamation-triangle"></i>{" "}
                  {translate("Müraciətiniz qeydə alındı")}
                </h4>
                <p className="modal-content-p">
                  {" "}
                  {translate("success_message_desc1")}{" "}
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleNotifyModal}>
            {translate("close")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSearchModel({ ...searchModel, [name]: value });
  };

  // const handleChangeOption = (option) => {
  //   setSelectedOption(option);
  // };

  const formValidate = () => {
    if (
      !searchModel.firstName ||
      !searchModel.firstName ||
      !searchModel.birthDate ||
      !searchModel.reintegrationNumber
    ) {
      toast.error(translate("Please fill the required fields"));
      return false;
    } else {
      return true;
    }
  };

  // const formValidate = () => {
  //   if (
  //     selectedOption == "1" &&
  //     (!searchModel.firstName ||
  //       !searchModel.firstName ||
  //       !searchModel.birthDate)
  //   ) {
  //     toast.error(translate("Please fill the required fields"));
  //     return false;
  //   } else if (selectedOption == "2" && !searchModel.reintegrationNumber) {
  //     toast.error(translate("Please fill the required field"));
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };

  const checkCaptcha = () => {
    let userCaptchaInput = document.getElementById("rsf-captcha");
    if (userCaptchaInput) {
      let captchaValue = userCaptchaInput.value;
      if (captchaValue) {
        if (validateCaptcha(captchaValue) === true) {
          loadCaptchaEnginge(6);
          userCaptchaInput.value = "";
          return true;
        } else {
          toast.error(translate("Captcha Does Not Match"));
          userCaptchaInput.value = "";
          return false;
        }
      } else {
        toast.error(translate("Captcha is required"));
        return false;
      }
    }
  };

  const search = async () => {
    try {
      // checkCaptcha()
      if (formValidate() && checkCaptcha()) {
        // const token = captchaRef.current.getValue();
        // if (!token) {
        //   toast.error(translate("google_recaptcha_is_required"));
        //   captchaRef.current.reset();
        //   return;
        // }

        setLoading(true);
        const res = await searchReintegrationApp(searchModel);
        // captchaRef.current.reset();
        // if (res?.statusCode == 10 && !res?.success) {
        //   toast.info(translate("google_recaptcha_is_invalid"));
        // } else

        if (res?.value?.data && res.value.data.length) {
          setAppList(res.value.data);
        } else {
          setAppList([]);
          toast.info(translate("No result found"));
        }
        setLoading(false);
      }
    } catch (error) {
      toast.error(translate("An error occurred"));
      console.log("search error", error);
      setLoading(false);
    }
  };

  let normalizedAppList = [];
  let mappedClist = [];
  let mappedRlist = [];
  appList.forEach((element) => {
    const clist = element?.citizenshipApplications || [];
    const rlist = element?.residenceApplications || [];
    mappedClist = clist.map((c) => ({
      id: c.id,
      status: c.status,
      residenceStartDate: c.residenceStartDate,
      firstName: element.reintegrationApplication?.firstName,
      lastName: element.reintegrationApplication?.lastName,
      birthDate: element.reintegrationApplication?.birthDate,
      reintegrationNumber:
        element.reintegrationApplication?.reintegrationNumber,
      reintegrationId: element.reintegrationApplication?.reintegrationId,
      // "Reason for application": translate("Vətəndaşlığın təsdiqi"),
    }));
    mappedRlist = rlist.map((c) => ({
      id: c.id,
      status: c.status,
      residenceStartDate: c.residenceStartDate,
      firstName: element.reintegrationApplication?.firstName,
      lastName: element.reintegrationApplication?.lastName,
      birthDate: element.reintegrationApplication?.birthDate,
      reintegrationNumber:
        element.reintegrationApplication?.reintegrationNumber,
      reintegrationId: element.reintegrationApplication?.reintegrationId,
      // "Reason for application": translate("Yaşama icazəsi"),
    }));

    const mainObj = { ...element.reintegrationApplication };
    // mainObj["Reason for application"] = translate("Reinteqrant qeydiyyatı");
    normalizedAppList.push(mainObj);
  });
  normalizedAppList = [...normalizedAppList, ...mappedClist, ...mappedRlist];

  return (
    <div>
      <div className="container tab-con-desc">
        <div className="row ">
          <div className="col-lg-12 ">
            {" "}
            <h6>{translate("Fərdi məlumatlarınız qorunur!")} </h6>{" "}
          </div>
        </div>
      </div>
      <div className="container" style={{ minHeight: 350 }}>
        <BlockUi tag="div" blocking={loading}>
          {/* <div className="row">
            <div className="col-lg-12 mt-3">
              <div className="row ">
                <div className="col mb-2">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={selectedOption == "1"}
                      onChange={(e) => handleChangeOption("1")}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      {translate("Şəxsi məlumatlar ilə axtar")}
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={selectedOption == "2"}
                      onChange={(e) => handleChangeOption("2")}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      {translate("Reinteqrant nömrəsi ilə axtar")}{" "}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {selectedOption == "1" && (
            <div className="row sexsi">
              <div className="col-lg-12 mt-3">
                <div className="row mb-4">
                  <div className="col-lg-3 col-md-12">
                    <div className="form-outline">
                      <label className="form-label">
                        {translate("firstName")} <span className="red">* </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        value={searchModel?.firstName || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <div className="form-outline">
                      <label className="form-label">
                        {translate("lastName")} <span className="red">* </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        value={searchModel?.lastName || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form3Example1">
                        {translate("Doğum tarixi")}{" "}
                        <span className="red">* </span>
                      </label>
                      <DatePicker
                        weekDays={weekDays[getCurrentLang()]}
                        months={months[getCurrentLang()]}
                        placeholder="__.__.____"
                        className={"form-control"}
                        format="DD.MM.YYYY"
                        value={searchModel?.birthDate || "__.__.____"}
                        onChange={(date) => {
                          setSearchModel({
                            ...searchModel,
                            birthDate: new Date(date),
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <div className="form-outline">
                      <label className="form-label">
                        {translate("notification_no")}{" "}
                        <span className="red">* </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="reintegrationNumber"
                        value={searchModel?.reintegrationNumber || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-sm-12 mt-2 mb-2">
                    {/* <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY}
                      ref={captchaRef}
                    /> */}
                    <ReactSimpleCaptcha id="rsf-captcha" />
                  </div>
                  <div className="col-lg-2 mt-2 mb-2">
                    <button
                      className="custom-send-button"
                      disabled={loading}
                      onClick={search}
                    >
                      {" "}
                      {translate("search")}{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* {selectedOption == "2" && (
            <div className="row Reinteqrant">
              <div className="col-lg-12 mt-3">
                <div className="row mb-4">
                  <div className="col-lg-4 col-md-12 text-center mb-2">
                    <ReactSimpleCaptcha />
                  </div>
                  <div className="col-lg-8 offset-lg-2 col-md-12 mb-2">
                    <div className="row">
                      <div className="col-sm-12 col-lg-8 mb-2">
                        <div className="form-outline">
                          <label className="form-label">
                            {translate("Reinteqrasiya nömrəsi")}{" "}
                            <span className="red">* </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="reintegrationNumber"
                            value={searchModel?.reintegrationNumber || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-4 mb-2">
                        <div className="col">
                          <label
                            className="form-label"
                            htmlFor="form3Example2"
                          ></label>
                          <div className="send-button">
                            <button
                              disabled={loading}
                              onClick={search}
                              className="custom-send-button"
                            >
                              {" "}
                              {translate("search")}{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}

          {normalizedAppList.length > 0 && (
            <div className="row">
              <div className="col-lg-12 mt-3">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>{translate("Status")}</th>
                        <th>{translate("Ad Soyad")}</th>
                        <th style={{ minWidth: 120 }}>
                          {translate("birthDate")}
                        </th>
                        <th>{translate("notification_no")}</th>
                        {normalizedAppList[0]?.status == 3 && (
                          <th style={{ minWidth: 130 }}>
                            {translate("Reinteqrasiya nömrəsi")}
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {normalizedAppList.map((item) => (
                        <tr key={item?.id}>
                          <td>
                            {" "}
                            <span
                              className={`badge rounded-pill d-inline ${
                                item?.status == 1 && "bg-primary"
                              } ${item?.status == 2 && "bg-secondary"} ${
                                item?.status == 3 && "bg-success"
                              }`}
                            >
                              {translate(statusesNumber[item?.status])}
                            </span>
                          </td>
                          <td>{item?.firstName + " " + item?.lastName}</td>
                          <td>
                            {item?.birthDate
                              ? moment(item.birthDate).format("DD.MM.YYYY")
                              : ""}
                          </td>
                          <td>{item?.reintegrationNumber || ""}</td>
                          {item?.status == 3 && (
                            <td>{item?.reintegrationId || "-"}</td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </BlockUi>
      </div>

      {renderNotifyModal()}
    </div>
  );
}

export default RegistrationStatusForm;
