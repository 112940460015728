import { crmApiNames } from "../../utils/constants";
import { crmApiGetList } from "../api.service";
import DICTIONARY_JSON from "./Dictionary";

let currentLang = undefined;
const CURRENT_LANG_TOKEN = "LAST_SELECTED_LANGUAGE";
export const supportedLanguages = ["az", "en", "ru", "am"];
let langChangeEmitters = [];

const TRANSLATIONS_KEY = "TRANSLATIONS";
let DICTIONARY = DICTIONARY_JSON;

const storeDictionaryInAsyncStorage = () =>
  localStorage.setItem(
    TRANSLATIONS_KEY,
    JSON.stringify({ DICTIONARY, storeTime: Date.now() })
  );

const translationUpdateDuration =
  window.webConfig.translationUpdateDuration || 300000; // milliseconds

const getDictionaryFromLocalStorage = () => {
  const localData = localStorage.getItem(TRANSLATIONS_KEY);

  if (localData) {
    const parsed = JSON.parse(localData);
    if (
      parsed &&
      parsed.storeTime &&
      Date.now() - parsed.storeTime < translationUpdateDuration
    ) {
      return parsed.DICTIONARY;
    }
  }
  return undefined;
};

export const initDictionary = async () =>
  new Promise((res) => {
    const response = getDictionaryFromLocalStorage();
    if (response) {
      DICTIONARY = response;
      res(DICTIONARY);
    } else {
      getTranslations()
        .then(() => {
          res(DICTIONARY);
        })
        .catch(() => {
          res(DICTIONARY_JSON);
        });
    }
  });

export const getTranslations = async () => {
  return crmApiGetList(crmApiNames.translation, {
    _limit: 100000,
    _sort: "id:DESC",
    _start: 0,
  })
    .then((response) => {
      const list = response?.data?.data?.list;
      if (list) {
        DICTIONARY = {};
        let extractedColumns = [
          "_id",
          "id",
          "createdAt",
          "updatedAt",
          "key",
          "__v",
        ];
        list.map((row) => {
          let wordData = (DICTIONARY[row.key] = {});
          return Object.keys(row).map((langKey) => {
            if (!extractedColumns.find((x) => x === langKey)) {
              wordData[langKey] = row[langKey];
            }
            return langKey;
          });
        });
        storeDictionaryInAsyncStorage();
        initDictionary();
      } else {
        console.log("Response data not found", response);
      }
    })
    .catch((error) => {
      console.log("initTranslationList error: ", error);
    });
};

export const use = (lang = "") => {
  verifyLanguage(lang);
  lang = lang.toLowerCase();
  currentLang = lang;
  emitLangChange(lang);
  localStorage.setItem(CURRENT_LANG_TOKEN, lang);
};

/**
 * emit all language change callback functions.
 */
const emitLangChange = (lang) =>
  langChangeEmitters.forEach((callback = () => console.log("")) =>
    callback(lang)
  );

/**
 * add language change callback function to call on language change.
 */
export const addLanguageChangeCallback = (callback) =>
  langChangeEmitters.push(callback);

/**
 * Throws if the passed language is not supported.
 */
export const verifyLanguage = (lang = "") => {
  if (!supportedLanguages.find((x) => x === lang.toLowerCase())) {
    console.log(`the language:${lang} is not supported.`);
    // throw (`the language:${lang} is not supported.`);
  }
};

/**
 * Returns the app wide used language.
 */
export const getCurrentLang = () => {
  return currentLang;
};

/**
 * This method must be called only on the opening of the app
 */
export const readAndSetLanguageFromStorage = (callback = () => {}) => {
  if (currentLang)
    //because we want this method to run only on opening
    return;

  const lang = localStorage.getItem(CURRENT_LANG_TOKEN);
  currentLang = lang ? lang : "az";
  callback(currentLang);
};
readAndSetLanguageFromStorage();

// const addNewTranslationKey = (key = "") => {
//   const data = { key, az: "", en: "", ru: "" };
//   apiServicePrivate.save(entityNames.translation, data);
//   DICTIONARY[key] = data;
//   localStorage.removeItem(TRANSLATIONS_KEY);
// };

/**
 * Returns the according value from dictionary for the current language.
 * If the current language is not set, or the according value for the passed key in the current language is not set returns the passed key.
 */
export const translate = (keyOrTranslatableObject) => {
  if (!keyOrTranslatableObject) return "";

  if (typeof keyOrTranslatableObject !== "object")
    return translateFromDictionary(keyOrTranslatableObject);

  let t = translateFromSameTranslatableObject(keyOrTranslatableObject);

  if (t && t.includes("_")) {
    t = t.replaceAll("_", " ").toCapitalise();
  }

  if (t && t.length > 0) {
    t = capitalizeFirstLetter(t);
  }

  return t;
};

const translateFromSameTranslatableObject = (translatableObject) =>
  translatableObject[currentLang];

const translateFromDictionary = (key) => {
  let translation = key;
  if (DICTIONARY[key] && DICTIONARY[key][currentLang]) {
    translation = DICTIONARY[key][currentLang];
  } else if (DICTIONARY[key] && DICTIONARY[key]["az"]) {
    translation = DICTIONARY[key]["az"];
  } else if (DICTIONARY[key] && DICTIONARY[key]["en"]) {
    translation = DICTIONARY[key]["en"];
  }
  // else if (!DICTIONARY[key]) {
  //     if (key && key.toString().length > 0) {
  //         addNewTranslationKey(key);
  //     }
  // }

  if (translation && translation.includes("_")) {
    translation = translation.replaceAll("_", " ");
  }

  if (translation && translation.length > 0) {
    translation = capitalizeFirstLetter(translation);
  }

  return translation;
};

export const translateWithCustomLang = (key, customLang) => {
  let translation = key;
  if (DICTIONARY[key] && DICTIONARY[key][customLang]) {
    translation = DICTIONARY[key][customLang];
  } else if (DICTIONARY[key] && DICTIONARY[key]["az"]) {
    translation = DICTIONARY[key]["az"];
  } else if (DICTIONARY[key] && DICTIONARY[key]["en"]) {
    translation = DICTIONARY[key]["en"];
  }

  return translation;
};

/**
 * Returns the according value in the app wide used language from dictionary for the passed word and language(oldLang).
 * If the passed language is not set default will be selected 'az'.
 * If the current language is not set, or the according value for the passed word in the current language is not set returns the passed word.
 */
export const getWordInCurrentLang = (word, oldLang = "az") => {
  let translation = word;

  DICTIONARY.forEach((key) => {
    if (
      DICTIONARY[key] &&
      DICTIONARY[key][currentLang] &&
      DICTIONARY[key][oldLang] === word
    )
      translation = DICTIONARY[key][currentLang];
  });
  //perform translation
  // for (key in DICTIONARY) {
  //     if (DICTIONARY[key] && DICTIONARY[key][currentLang] && DICTIONARY[key][oldLang] == word)
  //         translation = DICTIONARY[key][currentLang];
  // }

  return translation;
};

export const getFirstUpper = (value, args) => {
  return translate(value, args).replace(
    /(?:^|\s)\S/g,
    function (letter, index) {
      return index !== 0 ? letter.toLowerCase() : letter.toUpperCase();
    }
  );
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getCamelized = (value, args) => {
  return translate(value, args).replace(
    /(^\w|[A-Z]|\b\w)/g,
    function (letter, index) {
      return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
    }
  );
};

export const getUpper = (value, args) => {
  var result = translate(value, args);
  if (currentLang === "az") result = result.replace(/i/g, "İ");

  return result.toUpperCase();
};

export const formatDate = (date) => {
  var dateUtc = new Date(date);
  if (!date.includes("Z")) new Date(date + "Z");
  return dateUtc.toLocaleDateString();
};

export const formatDateTime = (date) => {
  var dateUtc = new Date(date);
  if (!date.includes("Z")) new Date(date + "Z");
  return dateUtc.toLocaleDateString() + " " + dateUtc.toLocaleTimeString();
};

export const formatTime = (time) => {
  // Hours, minutes and seconds
  var hrs = ~~(time / 3600);
  var mins = ~~((time % 3600) / 60);
  var secs = ~~time % 60;

  var ret = "";
  if (hrs > 0) {
    ret += "" + hrs + "h " + (mins < 10 ? "0" : "");
  }
  if (mins > 0) {
    ret += "" + mins + "m " + (secs < 10 ? "0" : "");
  }
  ret += "" + secs + "s";
  return ret;
};
