const DICTIONARY = {
  key: {
    az: "",
    en: "",
    ru: "",
    am: "",
  },
  home_logo_title: {
    az: "Azərbaycan Respublikasının Qarabağ iqtisadi rayonunda yaşayan erməni sakinlərinin reinteqrasiya portalı",
    am: "Ադրբեջանի Հանրապետության Ղարաբաղի տնտեսական տարածաշրջանում բնակվող հայ բնակիչների վերաինտեգրման պորտալ",
    en: "Reintegration portal of Armenian residents living in the Karabakh economic region of the Republic of Azerbaijan",
    ru: "Портал реинтеграции армянских жителей, проживающих в Карабахском экономическом районе Азербайджанской Республики",
  },
  home_slogan_title: {
    az: "Sülh, davamlı inkişaf və birgəyaşayış üçün reinteqrasiya",
    am: "Վերաինտեգրում հանուն խաղաղության, կայուն զարգացման և համակեցության",
    en: "Reintegration for peace, sustainable development and coexistence",
    ru: "Реинтеграция ради мира, устойчивого развития и сосуществования",
  },
  home_menu_title: {
    az: "İlkin qeydiyyat üçün müraciət et",
    am: "Դիմեք նախնական գրանցման համար",
    en: "Apply for initial registration",
    ru: "Подать заявку на первичную регистрацию",
  },
  home_menu_desc: {
    en: "Initial registration is necessary to join the reintegration process.",
    ru: "Для присоединения к процессу реинтеграции первичная регистрация необходима.",
    am: "Վերաինտեգրման գործընթացին մասնակցելու համար անհրաժեշտ է նախնական գրանցում:",
    az: "Reinteqrasiya prosesinə qoşulmaq üçün ilkin qeydiyyat zəruridir.",
  },
  google_recaptcha_is_invalid: {
    az: "Google reCAPTCHA etibarsızdır",
    en: "Google reCAPTCHA is invalid",
    ru: "Google reCAPTCHA недействителен",
    am: "Google reCAPTCHA-ն անվավեր է",
  },
  google_recaptcha_is_required: {
    az: "Google reCAPTCHA tələb olunur",
    en: "Google reCAPTCHA is required",
    ru: "Требуется Google reCAPTCHA",
    am: "Պահանջվում է Google reCAPTCHA",
  },
  chat_non_working_hours: {
    az: "Müraciətiniz qeydə alındı. Hazırda qeyri-iş saatları olduğundan, müraciətiniz növbəti iş günü ərzində cavablandırılacaq.",
    en: "Your application has been received. As it is currently non-business hours, your application will be answered within the next business day.",
    ru: "Ваше обращение зарегистрировано. Поскольку сейчас нерабочие часы, ответ на Ваше обращение будет дан в течение следующего рабочего дня.",
    am: "Ձեր բողոքարկելը գրանցվել է: Քանի որ ներկայումս ոչ աշխատանքային ժամեր են, ձեր հարցումը կպատասխանվի հաջորդ աշխատանքային օրվա ընթացքում:",
  },
  birthplace: {
    az: "Doğum yeri",
    en: "Birthplace",
    ru: "Место рождения",
    am: "Ծննդավայր",
  },
  person_already_registered: {
    az: "Sizin icrada olan müraciətiniz var",
    en: "Your application is in progress",
    ru: "Ваше обращение в процессе рассмотрения",
    am: "Ձեր դիմումը քննարկման փուլում է",
  },
  "Download the document": {
    az: "Sənədi yükləyin",
    en: "Download the document",
    ru: "Скачать документ",
    am: "Ներբեռնեք փաստաթուղթը",
  },
  "Email or WhatsApp number is required": {
    az: "E-poçt və ya WhatsApp nömrəsi tələb olunur",
    en: "Email or WhatsApp number is required",
    ru: "Требуется номер электронной почты или WhatsApp",
    am: "Պահանջում է էլփոստի կամ WhatsApp համարի համար",
  },
  join_reintegration_process: {
    az: "Reinteqrasiya prosesinə qoşulmaq üçün ilkin qeydiyyat zəruridir.",
    en: "Initial registration is necessary to join the reintegration process.",
    ru: "Для присоединения к процессу реинтеграции первичная регистрация необходима.",
    am: "Վերաինտեգրման գործընթացին մասնակցելու համար անհրաժեշտ է նախնական գրանցում.",
  },
  reception_applications: {
    az: "İlkin qeydiyyata alınmaq üçün müraciətlərin qəbulu",
    en: "Reception of applications for initial registration",
    ru: "Прием обращений для постановки на первичный учет",
    am: "Նախնական գրանցման համար դիմումների ընդունում",
  },
  app_check_status: {
    az: "Etdiyiniz müraciətin statusuna ad, soyad, doğum tarixi və bildiriş nömrəsini daxil etməklə baxa bilərsiniz.",
    en: "You can check the status of your application by entering your first name, last name, date of birth and notification number.",
    ru: "Вы можете проверить статус Вашего поданного обращения, введя имя, фамилию, дату рождения и номер уведомления",
    am: "Դուք կարող եք ստուգել Ձեր դիմումի կարգավիճակը՝ մուտքագրելով Ձեր անունը, ազգանունը, ծննդյան ամսաթիվը և ծանուցման համարը.",
  },
  purpose_join_reintegration: {
    az: "İlkin qeydiyyat Qarabağ iqtisadi rayonunda yaşayan erməni əsilli şəxslərin reinteqrasiya prosesinə qoşulması məqsədilə aparılır.",
    en: "Initial registration is carried out for the purpose of joining the reintegration process of the persons of Armenian origin residing in the Karabakh economic region.",
    ru: "Первичная регистрация проводится с целью присоединения лиц армянского происхождения, проживающих в Карабахском экономическом районе, к процессу реинтеграции.",
    am: "Նախնական գրանցումն իրականացվում է Ղարաբաղի տնտեսական տարածաշրջանում բնակվող հայազգի անձանց վերաինտեգրման գործընթացին միանալու նպատակով.",
  },
  upload_copy: {
    az: "Sənədlərin sürətini yükləyin",
    en: "Upload a copy of the documents",
    ru: "Загрузите копию документов",
    am: "Վերբեռնեք փաստաթղթերի պատճենը",
  },
  copy_other_docs: {
    az: "Təqdim etmək istədiyiniz digər sənədlərin surətini yükləyin",
    en: "Upload a copy of any other documents you wish to submit",
    ru: "Загрузите копии других документов, которые Вы хотите предоставить",
    am: "Վերբեռնեք ցանկացած այլ փաստաթղթի պատճենը, որը ցանկանում եք ներկայացնել",
  },
  app_received: {
    az: "Müraciətiniz qəbul edilib",
    en: "Your application has been received",
    ru: "Ваше обращение принято",
    am: "Ձեր հարցումն ընդունվել է",
  },
  app_under_review: {
    az: "Müraciətiniz icradadır",
    en: "Your application is under review",
    ru: "Ваше обращение на исполнении",
    am: "Ձեր դիմումը մշակվում է",
  },
  initial_regstration: {
    az: "İlkin qeydiyyata alınmısınız",
    en: "You have been initially registered",
    ru: "Вы поставлены на первичный учет",
    am: "Դուք նախապես գրանցված եք",
  },
  app_sent: {
    az: "Müraciətiniz göndərildi.",
    en: "Your application has been sent.",
    ru: "Ваше обращение отправлено.",
    am: "Ձեր դիմումը ուղարկվել է։",
  },
  notification_no: {
    az: "Bildiriş nömrəsi",
    en: "Notification number",
    ru: "Номер уведомления",
    am: "Ծանուցում համար",
  },
  assist_you: {
    az: "Sizə necə köməklik edə bilərik?",
    en: "How can we assist you?",
    ru: "Как мы можем Вам помочь?",
    am: "Ինչպե՞ս կարող ենք օգնել ձեզ?",
  },
  close_chat_session: {
    az: "Sessiyanı bağlayın",
    en: "Close the session",
    ru: "Закрыть сессию",
    am: "Փակել նիստը",
  },
  page_not_found: {
    az: "Səhifə tapılmadı",
    en: "Page not found",
    ru: "Страница не найдена",
    am: "Էջը չի գտնվել",
  },
  back_to_home: {
    az: "Əsas səhifəyə qayıt",
    en: "Back to home page",
    ru: "Вернуться на главную страницу",
    am: "Վերադառնալ գլխավոր էջ",
  },
  add: {
    az: "Əlavə et",
    en: "Add",
    ru: "Добавить",
    am: "Ավելացնել",
  },
  more: {
    az: "Ətraflı",
    en: "Detailed",
    ru: "Подробно",
    am: "Մանրամասն",
  },
  az: {
    az: "az",
    en: "az",
    ru: "az",
    am: "Ադ",
  },
  en: {
    az: "en",
    en: "en",
    ru: "ан",
    am: "ան",
  },
  ru: {
    az: "ru",
    en: "ru",
    ru: "ру",
    am: "ռո",
  },
  am: {
    az: "Ադ",
    en: "am",
    ru: "ар",
    am: "հա",
  },
  "Thank you for contacting us": {
    az: "Bizimlə əlaqə saxladığınız üçün təşəkkür edirik",
    en: "Thank you for contacting us",
    ru: "Благодарим Вас за обращение к нам",
    am: "Շնորհակալություն մեզ հետ կապվելու համար",
  },
  enter_message: {
    az: "Mesaj daxil edin",
    en: "Enter message",
    ru: "Введите сообщение",
    am: "Մուտքագրեք հաղորդագրություն",
  },
  start_the_chat: {
    az: "Söhbətə başlayın",
    en: "Start the chat",
    ru: "Начать чат",
    am: "Սկսեք զրույցը",
  },
  surname: {
    az: "Soyad",
    en: "Last name",
    ru: "Фамилия",
    am: "Ազգանունը",
  },
  live_chat: {
    az: "Canlı söhbət",
    en: "Live chat",
    ru: "Чат",
    am: "Ուղիղ զրույց",
  },
  confirm_the_correctness: {
    az: "Daxil edilən məlumatların düzgünlüyünü təsdiq edirəm.",
    en: "I confirm the correctness of the entered data.",
    ru: "Подтверждаю правильность введенных данных.",
    am: "Ես հաստատում եմ, որ մուտքագրված տվյալները ճիշտ են:",
  },
  "Why are you undocumented": {
    az: "Sənədsiz olmağınızın səbəbi nədir?",
    en: "Why are you undocumented?",
    ru: "По какой причине Вы не имеете документов?",
    am: "Ինչո՞ւ եք առանց փաստաթղթերի:",
  },
  NoDocuments: {
    az: "Heç bir sənədim yoxdur",
    en: "I have no documents",
    ru: "У меня нет никаких документов",
    am: "Ես փաստաթղթեր չունեմ",
  },
  news_info: {
    az: "Reinteqrasiya xəbərləri",
    en: "Reintegration news",
    ru: "Новости реинтеграции",
    am: `Նորություններ`,
  },
  news_info_desc: {
    az: "Reinteqrasiya ilə bağlı xəbərlər",
    en: "News on reintegration",
    ru: "Информация о реинтеграции",
    am: "Վերաինտեգրման նորություններ",
  },
  all_news: {
    az: "Bütün xəbərlər",
    en: "All news",
    ru: "Все новости",
    am: "Բոլոր նորությունները",
  },
  // #region HOME PAGE
  Menu: {
    az: "",
    en: "Menu",
    ru: "",
    am: "",
  },
  header_title: {
    az: "Azərbaycan Respublikasının Qarabağ iqtisadi rayonunda yaşayan erməni sakinlərinin reinteqrasiya portalı",
    en: "Reintegration portal of Armenian residents living in the Karabakh economic region of the Republic of Azerbaijan",
    am: "Ադրբեջանի Հանրապետության Ղարաբաղի տնտեսական տարածաշրջանում բնակվող հայ բնակիչների վերաինտեգրման պորտալ",
    ru: "Портал реинтеграции армянских жителей, проживающих в Карабахском экономическом районе Азербайджанской Республики",
  },
  "Ətraflı məlumat": {
    az: "Ətraflı məlumat",
    en: "Detailed information",
    am: "Մանրամասն տեղեկություններ",
    ru: "Подробная информация",
  },
  lang: {
    az: "Dil",
    en: "Lang",
    am: "Լեզու",
    ru: "Язык",
  },
  video_app_title: {
    az: "Video Müraciət",
    en: "Video Appeal",
    am: "Տեսանյութ բողոքարկում",
    ru: "Видео обращение",
  },
  //#endregion
  // #region registration
  InProcess: {
    az: "İcradadır",
    en: "In progress",
    ru: "На исполнении",
    am: "Այն ընթացքի մեջ է",
  },
  Accepted: {
    az: "Qəbul edilib",
    en: "Accepted",
    ru: "Принято",
    am: "Ընդունված է",
  },
  Declined: {
    az: "İmtina edilib",
    en: "Rejected",
    ru: "Отказано",
    am: "Հրաժարվել է",
  },
  reg_header_title: {
    az: "Azərbaycan Respublikasının Qarabağ iqtisadi rayonunda yaşayan erməni sakinlərinin reinteqrasiya portalı",
    en: "Reintegration portal of Armenian residents living in the Karabakh economic region of the Republic of Azerbaijan",
    am: "Ադրբեջանի Հանրապետության Ղարաբաղի տնտեսական տարածաշրջանում բնակվող հայ բնակիչների վերաինտեգրման պորտալ",
    ru: "Портал реинтеграции армянских жителей, проживающих в Карабахском экономическом районе Азербайджанской Республики",
  },
  reg_header_desc: {
    az: "Sənədlərlə təmin edilmək üçün müraciətlərin qəbulu",
    en: "Recieving applications to be provided with documents",
    ru: "Прием обращений для обеспечения документами",
    am: "Փաստաթղթերի տրամադրման հարցումների ընդունում",
  },
  reg_footer_call_center: {
    az: "Çağrı Mərkəzi",
    en: "Call center",
    ru: "Call-центр",
    am: "Հեռախոսակապի կենտրոն",
  },
  reg_footer_address: {
    az: "Ünvan: Bakı şəhəri, Binəqədi rayonu, 3123-cü məhəllə",
    en: "Address: 3123 Block, Binagadi region, Baku city",
    ru: "Адрес: Город Баку, Бинагадинский район, Квартал 3123",
    am: "Հասցե՝ Բաքու քաղաք, Բինագադի շրջան, 3123 թ",
  },
  reg_footer_menu_president: {
    az: "Azərbaycan Respublikasının Prezidenti",
    en: "President of the Republic of Azerbaijan",
    ru: "Президент Азербайджанской Республики ",
    am: "Ադրբեջանի Հանրապետության Նախագահ",
  },
  reg_footer_menu_mfa: {
    az: "Xarici İşlər Nazirliyi",
    en: "Ministry of Foreign Affairs",
    ru: "Министерство Иностранных Дел ",
    am: "Արտաքին գործերի նախարարություն",
  },
  reg_footer_menu_migration: {
    az: "Dövlət Miqrasiya Xidməti",
    en: "State Migration Service",
    ru: "Государственная Миграционная Служба ",
    am: "Պետական միգրացիոն ծառայություն",
  },
  reg_footer_menu_migrationto: {
    az: "Migration to Azerbaijan",
    en: "Migration to Azerbaijan",
    ru: "Миграция в Азербайджан",
    am: "Միգրացիան Ադրբեջան",
  },
  reg_footer_reinteg_info: {
    az: "Reinteqrasiya haqqında məlumatlı olun",
    en: "Be informed about reintegration",
    ru: "Будьте информированы о реинтеграции ",
    am: "Եղեք տեղեկացված վերաինտեգրման մասին",
  },
  reg_footer_dmx: {
    az: "Azərbaycan Respublikası Dövlət Miqrasiya Xidməti",
    en: "State Migration Service of the Republic of Azerbaijan",
    ru: "Государственная Миграционная Служба Азербайджанской Республики",
    am: "ԱՀ պետական միգրացիոն ծառայություն",
  },
  reg_citizenship_birth_certificate: {
    az: "Doğum haqqında şəhadətnamənin surəti (bu sənəd olmadığı təqdirdə, həmin məlumatları özündə əks etdirən müvafiq sənəd)",
    en: "A copy of the birth certificate (if this document is not available, a relevant document containing this information)",
    ru: "Копия свидетельства о рождении (при отсутствии данного документа - соответствующий документ, содержащий эти сведения)",
    am: "Ծննդյան վկայականի պատճենը (եթե այս փաստաթուղթը բացակայում է, համապատասխան փաստաթուղթ, որը պարունակում է այս տեղեկատվությունը)",
  },
  applications_page: {
    az: "Müraciətlərin qəbulu səhifəsi",
    en: "Applications page",
    ru: "Страница приема обращений",
    am: "Դիմումների էջ",
  },
  "İstifadəçi qaydaları": {
    az: "İstifadəçi qaydaları",
    en: "Terms for users",
    ru: "Правила пользователя",
    am: "Օգտագործողի կանոններ",
  },
  "Məxfilik siyasəti": {
    az: "Məxfilik siyasəti",
    en: "Privacy policy",
    ru: "Политика конфиденциальности",
    am: "Գաղտնիության քաղաքականություն",
  },
  "Bütün hüquqlar qorunur": {
    az: "Bütün hüquqlar qorunur",
    en: "All rights reserved ",
    ru: "Все права защищены",
    am: "Բոլոր իրավունքները պաշտպանված են",
  },
  "Ən çox verilən suallar": {
    az: "Tez-tez verilən suallar",
    en: "Frequently asked questions",
    ru: "Часто задаваемые вопросы",
    am: "Հաճախակի տրվող հարցեր",
  },
  faq_1_title: {
    az: "İlkin qeydiyyatın məqsədi nədir?",
    en: "What is the purpose of initial registration?",
    ru: "Какова цель первичной регистрации?",
    am: "Ո՞րն է ինտեգրացիոն գրանցման նպատակը?",
  },
  faq_1_desc: {
    az: "İlkin qeydiyyat Qarabağ iqtisadi rayonunda yaşayan erməni əsilli şəxslərin reinteqrasiya prosesinə qoşulması məqsədilə aparılır.",
    en: "Initial registration is carried out for the purpose of joining the reintegration process of the persons of Armenian origin residing in the Karabakh economic region.",
    ru: "Первичная регистрация проводится с целью присоединения лиц армянского происхождения, проживающих в Карабахском экономическом районе, к процессу реинтеграции.",
    am: "Նախնական գրանցումն իրականացվում է Ղարաբաղի տնտեսական տարածաշրջանում բնակվող հայազգի անձանց վերաինտեգրման գործընթացին միանալու նպատակով.",
  },
  faq_2_title: {
    az: "İlkin qeydiyyat haqqında, müraciətlərlə bağlı və digər əlaqəli məsələlərə dair suallar olduqda, necə cavab almaq olar?",
    en: "How to receive answers about initial registration, applications and other related issues in case there are questions?",
    ru: "Как можно получить ответы при наличии вопросов касательно первичной регистрации, обращений и других связанных вопросов?",
    am: "Ինչպե՞ս ստանալ պատասխաններ վերաինտեգրման, դիմումների և հարակից այլ հարցերի վերաբերյալ հարցերի առկայության դեպքում?",
  },
  faq_2_desc: {
    az: "Saytın Baş səhifəsində olan Çağrı Mərkəzi, “Whatsapp” tətbiqi, telefon nömrəsi, onlayn əlaqə vasitəsi və ya Baş səhifənin “Müraciət et” bölməsinə daxil olduqdan sonra saytın aşağı hissəsində qeyd olunan ünvan və email üzərindən müraciət edə bilərsiniz.",
    en: 'You can apply through the Call Center, “Whatsapp” application, telephone number, online support or the address and email indicated at the bottom of the website after entering the "Apply" section of the Home page.',
    ru: "Вы можете обратиться посредством Call-центра на главной странице сайта, приложения «Whatsapp», номер телефон, онлайн-контакта или после входа в раздел «Подать обращение» на главной странице, по адресу и электронной почте, указанным внизу сайта.",
    am: "Կարող եք դիմել Զանգերի կենտրոնի միջոցով կայքի գլխավոր էջում, Whatsapp հավելվածի, հեռախոսահամար, առցանց կոնտակտի միջոցով կամ գլխավոր էջի «Ներկայացնել բողոքարկում» բաժինը մտնելուց հետո՝ ներքևում նշված հասցեով և էլ կայքից:",
  },
  faq_3_title: {
    az: "Azərbaycan Respublikası vətəndaşlığını təsdiq edən sənədləri və ya yaşama icazəsi vəsiqəsini əldə etmək üçün nə zaman müraciət etmək mümkündür?",
    en: "When it is possible to apply for obtaining documents certifying citizenship of the Republic of Azerbaijan or residence permit?",
    ru: "Когда можно обратиться для приобретения документов, подтверждающих гражданство Азербайджанской Республики или удостоверения разрешения на проживание?",
    am: "Ե՞րբ կարող եմ դիմել Ադրբեջանի Հանրապետության քաղաքացիությունը հաստատող փաստաթղթերի կամ կացության թույլտվության վկայական ստանալու համար?",
  },
  faq_3_desc: {
    az: "İlkin qeydiyyata alındıqdan (ilkin qeydiyyat nömrəsini əldə etdikdən) sonra Azərbaycan Respublikası vətəndaşlığını təsdiq edən sənədləri və ya yaşama icazəsi vəsiqəsini əldə etmək üçün müraciət edilə bilər.",
    en: "The application for obtaining documents certifying citizenship of the Republic of Azerbaijan or residence permit can be made after passing initial registration (receiving initial registration number).",
    ru: "После постановки на первичный учет (после приобретения первичного регистрационного номера), можно обратиться для приобретения документов, подтверждающих гражданство Азербайджанской Республики или удостоверения разрешения на проживание.",
    am: "Վերաինտեգրման գրանցումից հետո (ռեինտեգրատորի գրանցման համարի գնում) վերաինտեգրողը կարող է դիմել Ադրբեջանի Հանրապետության քաղաքացիությունը հաստատող փաստաթղթեր կամ կացության թույլտվության վկայական ձեռք բերելու համար։",
  },
  faq_4_title: {
    az: "Müraciətin icra vəziyyəti barədə məlumatı necə əldə etmək olar?",
    en: "How to get information about processing status of the application?",
    ru: "Как можно получить информацию о статусе исполнения обращения?",
    am: "Ինչպե՞ս կարող եմ տեղեկատվություն ստանալ բողոքի կատարման կարգավիճակի մասին?",
  },
  faq_4_desc: {
    az: "Saytın Baş səhifəsinin “Müraciət et” bölməsinə, daha sonra “Müraciətin statusuna bax” yarım bölməsinə daxil olaraq müvafiq məlumatları daxil etməklə müraciətin icra vəziyyətini izləmək mümkündür.",
    en: `It is possible to track processing status of the application by entering relevant information in the "Apply" section of the Home page and then “Application status” subsection.`,
    ru: "Отследить статус исполнения обращения можно войдя в раздел «Подать обращение» на главной странице сайта, а затем в подраздел «Cтатус обращения», при этом необходимо ввести соответствующие данные.",
    am: "Դուք կարող եք հետևել ձեր հարցման կարգավիճակին՝ մուտքագրելով համապատասխան տեղեկատվությունը, մուտքագրելով կայքի գլխավոր էջի «Դիմել» բաժինը, այնուհետև «Գործի կարգավիճակի դիտում» ենթաբաժինը։",
  },
  faq_5_title: {
    az: "Hansı hallarda əcnəbilərə və vətəndaşlığı olmayan şəxslərə Azərbaycan Respublikasının ərazisində müvəqqəti yaşamaq üçün icazə verilir?",
    en: "In which cases permit for temporary residence in the territory of the Republic of Azerbaijan is issued to foreigners and stateless persons?",
    ru: "В каких случаях иностранцам и лицам без гражданства предоставляется разрешение на временное проживание на территории Азербайджанской Республики?",
    am: "Ո՞ր դեպքերում է թույլատրվում օտարերկրացիներին և քաղաքացիություն չունեցող անձանց ժամանակավոր բնակվել Ադրբեջանի Հանրապետության տարածքում:",
  },
  faq_5_desc: {
    az: `Əcnəbilərə və vətəndaşlığı olmayan şəxslərə Azərbaycan Respublikasının ərazisində müvəqqəti yaşamaq üçün icazənin verildiyi (müddətinin uzadıldığı) hallar: 
   <br /><br />
    1. Azərbaycan Respublikasının vətəndaşı ilə yaxın qohumluq münasibətlərində olduqda; 
    <br />
    2. 3-5-ci bəndlərdə nəzərdə tutulmuş hallar da nəzərə alınmaqla, ölkə iqtisadiyyatına qoyduğu investisiya məbləğinin cəmi azı 500.000 manat olduqda; 
    <br />
    3. Azərbaycan Respublikasının ərazisində azı 100.000 manat dəyərində daşınmaz əmlakı olduqda; 
    <br />
    4. Azərbaycan Respublikasında fəaliyyət göstərən banklara müddətli əmanət müqaviləsi əsasında azı 100.000 manat məbləğində pul vəsaiti qoyduqda;
    <br />
    5. nominal dəyəri azı 100.000 manat olan dövlət qiymətli kağızlarına və ya eyni həcmdə paylarının (səhmlərinin) 51 və daha artıq faizi dövlətə məxsus olan hüquqi şəxslərin investisiya qiymətli kağızlarına malik olduqda; 
    <br />
    6. iqtisadiyyat, sənaye, hərbi, elm, mədəniyyət, idman və digər sahələr üzrə yüksək səviyyəli mütəxəssis olduqda; 
    <br />
    7. Miqrasiya Məcəlləsinin 64.0.8-1-ci maddəsində nəzərdə tutulmuş mütəxəssis olduqda; 
    <br />
    8. Azərbaycan Respublikasının ərazisində müvəqqəti və ya daimi yaşayan əcnəbi və ya vətəndaşlığı olmayan şəxslərin ailə üzvləri olduqda; 
    <br />
    9. xarici hüquqi şəxsin Azərbaycan Respublikasındakı filial və nümayəndəliyinin rəhbəri və onun müavini vəzifəsini tutduqda; 
    <br />
    10. Azərbaycan Respublikasında təsis edilmiş, ödənilmiş nizamnamə kapitalı müvafiq icra hakimiyyəti orqanı tərəfindən təsdiq edilmiş miqdardan az olmayan, təsisçisi və ya təsisçilərdən ən azı biri xarici hüquqi və ya fiziki şəxs olan hüquqi şəxsin rəhbəri vəzifəsini tutduqda və ya həmin hüquqi şəxsin paylarının (səhmlərinin) azı 51 faizinə sahib olduqda; 
    <br />
    11. Azərbaycan Respublikasında sahibkarlıq fəaliyyəti ilə məşğul olduqda; 
    <br />
    12. Miqrasiya Məcəlləsinin 64.0.6-cı, 64.0.6-1-ci, 64.0.7-ci, 64.0.9-cu, 64.0.10-cu, 64.0.11-ci, 64.0.17-ci, 64.0.18-ci və 64.0.19-cu maddələrində nəzərdə tutulan hallarda; 
    <br />
    13. Azərbaycan Respublikasının ərazisində haqqı ödənilən əmək fəaliyyəti ilə məşğul olmaq üçün bu Məcəllə ilə müəyyən edilmiş qaydada iş icazəsi aldıqda; 
    <br />
    14. Azərbaycan Respublikasının ali və orta ixtisas təhsili müəssisələrində əyani formada, habelə peşə təhsili və ümumi təhsil müəssisələrində təhsil aldıqda;  
    <br />
    15. dövlət qeydiyyatına alınmış dini qurumlarda peşəkar dini fəaliyyətlə məşğul olduqda; 
    <br />
    16. insan alverindən zərər çəkmiş şəxs hesab olunduqda; 
    <br />
    17. cinayət təqibi orqanlarına yardım göstərdikdə; 
    <br />
    18. Azərbaycan Respublikasının beynəlxalq müqavilələrində nəzərdə tutulmuş əsaslar olduqda. `,
    en: `Cases when permit for temporary residence in the territory of the Republic of Azerbaijan is issued (extended) to foreigners and stateless persons: 
<br /><br />
    1. In case they are in close relationship with a citizen of the Republic of Azerbaijan; 
    <br />
    2. In case they invest at least 500 000 manats in the economy of the country, taking into account cases envisaged in the items 3-5; 
    <br />
    3. In case they possess real estate which is worth at least 100 000 manats on the territory of the Republic of Azerbaijan 
    <br />
    4. In case they deposit money in the amount of at least 100.000 manats into the banks operating in the Republic of Azerbaijan on the basis of a term deposit agreement; 
    <br />
    5. in case they own state securities with a nominal value of at least 100,000 manats or the same amount of shares (stock) of investment securities of legal entities in which 51 percent or more of their shares belong to the state; 
    <br />
    6. In case they are highly qualified specialists in the spheres of economy, industry, military, science, culture, sports, etc; 
    <br />
    7. In case they are specialists envisaged in 64.0.8-1 of this Migration Code; 
    <br />
    8. In case they are family members of foreigners and stateless persons temporarily or permanently residing on the territory of the Republic of Azerbaijan; 
    <br />
    9. In case they hold positions of head or deputy head in the branch or representation of a foreign juridical person in the Republic of Azerbaijan; 
    <br />
    10. In case he/she holds the position of the head of a legal entity established in the Republic of Azerbaijan, whose paid-up charter capital is not less than the amount approved by the relevant executive authority, and whose founder or at least one of the founders is a foreign legal or physical person, or in case he/she owns at least 51 percent of the shares (stock) of that legal entity; 
    <br />
    11. In case they engage in entrepreneurship activity in the Republic of Azerbaijan; 
    <br />
    12. In cases envisaged in Articles 64.0.6, 64.0.6-1, 64.0.7, 64.0.9, 64.0.10, 64.0.11, 64.0.17, 64.0.18-ci and 64.0.19 of the Migration Code; 
    <br />
    13. In case they obtain work permit in a way envisaged by this Code to carry out paid labor activity on the territory of the Republic of Azerbaijan; 
    <br />
    14. In case they get full-time education at institutions of higher education and technical schools and in case they get education at secondary schools in the Republic of Azerbaijan 
    <br />
    15. In case they are engaged in professional religious activity at officially registered religious institutions; 
    <br />
    16. In case they are considered being a victim of human trafficking; 
    <br />
    17.  In case they assist criminal prosecution authorities; 
    <br />
    18. In case there are grounds envisaged by the international agreements of the Republic of Azerbaijan. `,
    ru: `
    Случаи предоставления (продления срока) разрешения на временное проживание иностранцам и лицам без гражданства на территории Азербайджанской Республики: 
<br /><br />
1. наличие близкой родственной связи с гражданином Азербайджанской Республики; 
<br />
2. с учетом обстоятельств, предусмотренных в 3-5 пунктах, если общая сумма инвестиций в экономику страны составляет не менее 500 000 манатов; 
<br />
3. наличие на территории Азербайджанской Республики недвижимости на сумму не менее 100.000 манатов; 
<br />
4. при вложении денег на сумму не менее 100.000 манатов в банки, действующие в Азербайджанской Республике, на основании договора срочного вклада; 
<br />
5. владение государственными ценными бумагами номинальной стоимостью не менее 100 000 манатов или в том же объеме инвестиционными ценными бумагами юридических лиц, в которых 51 и более процентов их долей (акций) принадлежат государству; 
<br />
6. будучи высококвалифицированным специалистом в экономической, промышленной, военной, научной, культурной, спортивной и прочих областях; 
<br />
7. будучи специалистом, предусмотренным статьей 64.0.8-1 Миграционного Кодекса; 
<br />
8. будучи членами семьи иностранцев или лиц без гражданства, временно или постоянно проживающих на территории Азербайджанской Республики; 
<br />
9. занятие должности руководителя филиала и представительства иностранного юридического лица в Азербайджанской Республике и должности его заместителя; 
<br />
10. занятие должности руководителя учрежденного в Азербайджане юридического лица, оплаченный уставный капитал которого не менее суммы, утвержденной соответствующим органом исполнительной власти и учредитель или как минимум один из учредителей которого является иностранным юридическим или физическим лицом, или владение не менее чем 51 процентом долей (акций) данного юридического лица; 
<br />
11. занятие предпринимательской деятельностью в Азербайджанской Республике; 
<br />
12. В случаях, предусмотренных статьями 64.0.6, 64.0.6-1, 64.0.7, 64.0.9, 64.0.10, 64.0.11, 64.0.17, 64.0.18 и 64.0.19 Миграционного Кодекса; 
<br />
13. при получении в установленном настоящим Кодексом порядке разрешения на работу для занятия оплачиваемой трудовой деятельностью на территории Азербайджанской Республики; 
<br />
14. очное обучение в высших и среднеспециальных учебных заведениях, а также обучение в учреждениях профессионального образования и общеобразовательных учреждениях Азербайджанской Республики; 
<br />
15. занятие профессиональной религиозной деятельностью в религиозных структурах, прошедших государственную регистрацию; 
<br />
16. если они cчитаются лицами, пострадавшими от торговли людьми; 
<br />
17. если они оказывают помощь органам уголовного преследования; 
<br />
18. наличие оснований, предусмотренных международными договорами Азербайджанской Республики. 
`,
    am: `Ո՞ր դեպքերում է թույլատրվում օտարերկրացիներին և քաղաքացիություն չունեցող անձանց ժամանակավոր բնակվել Ադրբեջանի Հանրապետության տարածքում:
<br /><br />
    1.Ո՞ր դեպքերում է թույլատրվում օտարերկրացիներին և քաղաքացիություն չունեցող անձանց ժամանակավոր բնակվել Ադրբեջանի Հանրապետության տարածքում:
    <br />
    2.հաշվի առնելով 3-5-րդ կետերով նախատեսված հանգամանքները, եթե երկրի տնտեսության մեջ կատարված ներդրումների ընդհանուր գումարը կազմում է առնվազն 500 000 մանաթ:
    <br />
    3.Ադրբեջանի Հանրապետության տարածքում առնվազն 100.000 մանաթի անշարժ գույքի առկայությունը:
    <br />
    4.ԱՀ-ում գործող բանկերում ժամկետային ավանդի պայմանագրի հիման վրա առնվազն 100 000 մանաթի գումարներ ներդնելիս:
    <br />
    5.առնվազն 100 000 մանաթ անվանական արժեքով պետական արժեթղթերի կամ իրավաբանական անձանց նույն չափով ներդրումային արժեթղթերի սեփականությունը, որոնց բաժնետոմսերի (բաժնետոմսերի) 51 և ավելի տոկոսը պատկանում է պետությանը:
    <br />
    6.լինել տնտեսական, արդյունաբերական, ռազմական, գիտական, մշակութային, սպորտային և այլ ոլորտներում բարձր որակավորում ունեցող մասնագետ:
    <br />
    7.լինելով միգրացիոն օրենսգրքի 64.0.8-1 հոդվածով նախատեսված մասնագետ:
    <br />
    8.Ադրբեջանի Հանրապետության տարածքում ժամանակավոր կամ մշտապես բնակվող օտարերկրացիների կամ քաղաքացիություն չունեցող անձանց ընտանիքի անդամներ լինելը:
    <br />
    9. զբաղեցնելով Ադրբեջանի Հանրապետությունում օտարերկրյա իրավաբանական անձի մասնաճյուղի և ներկայացուցչության ղեկավարի պաշտոնը և նրա տեղակալի պաշտոնը:
    <br />
    10. զբաղեցնելով Ադրբեջանում հիմնադրված իրավաբանական անձի ղեկավարի պաշտոն, որի վճարված կանոնադրական կապիտալը ոչ պակաս է համապատասխան գործադիր մարմնի կողմից հաստատված գումարից, և որի հիմնադիրը կամ հիմնադիրներից առնվազն մեկը օտարերկրացի է. իրավաբանական կամ ֆիզիկական անձ կամ բաժնետոմսերի (բաժնետոմսերի) առնվազն 51 տոկոսի սեփականություն այս իրավաբանական անձի նկատմամբ:
    <br />
    11. Ադրբեջանի Հանրապետությունում ձեռնարկատիրական գործունեությամբ զբաղվելը:
    <br />
    12. Միգրացիոն օրենսգրքի 64.0.6, 64.0.6-1, 64.0.7, 64.0.9, 64.0.10, 64.0.11, 64.0.17, 64.0.18 եւ 64.0.19 հոդվածներով նախատեսված դեպքերում:
    <br />
    13.սույն օրենսգրքով սահմանված կարգով Ադրբեջանի Հանրապետության տարածքում վճարովի աշխատանքային գործունեությամբ զբաղվելու աշխատանքի թույլտվություն ստանալուց հետո:
    <br />
    14.լրիվ դրույքով վերապատրաստում բարձրագույն և միջին մասնագիտական ուսումնական հաստատություններում, ինչպես նաև վերապատրաստում Ադրբեջանի Հանրապետության մասնագիտական ուսումնական հաստատություններում և հանրակրթական հաստատություններում:
    <br />
    15.պետական գրանցում անցած կրոնական կառույցներում մասնագիտական կրոնական գործունեությամբ զբաղվելը:
    <br />
    16. եթե նրանք համարվում են մարդկանց թրաֆիքինգի զոհ:
    <br />
    17. քրեական հետապնդման մարմիններին օգնություն ցուցաբերելիս:
    <br />
    18. Ադրբեջանի Հանրապետության միջազգային պայմանագրերով նախատեսված հիմքերի առկայությունը:`,
  },
  tab_info_1: {
    az: `İlkin qeydiyyat Qarabağ iqtisadi rayonunda yaşayan erməni sakinlərinin reinteqrasiya prosesinə qoşulması məqsədilə aparılır. Ad, soyad, doğum tarixi, ünvan, əlaqə məlumatları və şəxsiyyəti təsdiq edən hər hansı sənədin surətini əlavə etməklə qısa müddətdə ilkin qeydiyyata alınmaq mümkündür. Müraciətinizi göndərdikdən sonra müraciət zamanı daxil etdiyiniz əlaqə vasitələrilə sizə əlavə məlumat veriləcək. “Müraciətin statusuna bax” bölməsi vasitəsilə müraciətinizin statusunu izləyə bilərsiniz.`,
    en: "Initial registration is carried out for the purpose of joining the reintegration process of Armenian residents living in the Karabakh economic region. It is possible to pass initial registration in a short time by adding first name, last name, date of birth, address, contact information and a copy of any identity document. You will be provided with additional information through the means of contact you mentioned in the application after the application has been sent. You can check the status of your application through “Application status” section.",
    ru: "Первичная регистрация проводится с целью присоединения армянских жителей, проживающих в Карабахском экономическом районе, к процессу реинтеграции.  Добавив имя, фамилию, дату рождения, адрес, контактную информацию и копию подтверждающего личность любого документа, можно в короткий срок встать на первичный учёт. После отправки обращения, Вам будет предоставлена дополнительная информация через контактные данные, которые Вы ввели при подаче обращения. Вы можете отслеживать статус своего обращения посредством раздела «Cтатус обращения»",
    am: "Նախնական գրանցումն իրականացվում է Ղարաբաղի տնտեսական տարածաշրջանում բնակվող հայ բնակիչների վերաինտեգրման գործընթացին միանալու նպատակով։ Ավելացնելով ձեր անունը, ազգանունը, ծննդյան ամսաթիվը, հասցեն, կոնտակտային տվյալները և ձեր ինքնությունը հաստատող ցանկացած փաստաթղթի պատճենը, դուք կարող եք արագ գրանցվել առաջին անգամ: Դիմումը ուղարկելուց հետո ձեզ լրացուցիչ տեղեկատվություն կտրամադրվի դիմումում ձեր նշած կապի միջոցների միջոցով: Ձեր դիմումի կարգավիճակը կարող եք ստուգել «Դիմումի կարգավիճակ» բաժնի միջոցով:",
  },
  tab_info_2: {
    az: `İlkin qeydiyyata alındıqdan sonra Azərbaycan
    Respublikası vətəndaşlığını təsdiq edən sənədləri əldə
    etmək üçün müraciət etmək mümkündür. Qarabağ iqtisadi rayonunda
    məskunlaşdığınız tarixi, vətəndaşlıq mənsubiyyətinizi
    təsdiq edən sənədləri, valideynləriniz barədə
    məlumatları təqdim etməklə müraciətinizi tamamlaya
    bilərsiniz. Vətəndaşlığı təsdiq edən sənədləri əldə edən
    şəxslər Azərbaycan Respublikası vətəndaşları ilə bərabər
    hüquqlara malik olur.`,
    en: "It is possible to apply for obtaining the documents confirming the citizenship of the Republic of Azerbaijan after the initial registration. You can complete your application by submitting the date of your settling in Karabakh economic region, documents confirming your citizenship affiliation, information on your parents. Persons who obtain documents confirming citizenship have equal rights with citizens of the Republic of Azerbaijan.",
    ru: " После постановки на первичный учет можно обратиться для приобретения документов, подтверждающих гражданство Азербайджанской Республики. Вы можете завершить обращение предоставив дату заселения в Карабахе, документы, подтверждающие гражданскую принадлежность, информацию о родителях. Лица, приобретшие подтверждающие гражданство документы, имеют равные права с гражданами Азербайджанской Республики.",
    am: "Վերաինտեգրման գրանցումից հետո կարող եք դիմել Ադրբեջանի Հանրապետության քաղաքացիությունը հաստատող փաստաթղթերի ձեռքբերման համար։ Դուք կարող եք լրացնել Ձեր դիմումը՝ ներկայացնելով Ղարաբաղի տնտեսական տարածաշրջանում հաստատվելու ամսաթիվը, քաղաքացիությունը հաստատող փաստաթղթեր և Ձեր ծնողների մասին տեղեկություններ։ Քաղաքացիությունը հաստատող փաստաթղթեր ձեռք բերած անձինք ունեն հավասար իրավունքներ Ադրբեջանի Հանրապետության քաղաքացիների հետ:",
  },
  tab_info_3: {
    az: `İlkin qeydiyyata alındıqdan sonra yaşama icazəsi vəsiqəsi əldə etmək üçün müraciət edə biləsiniz. Bu vəsiqə dövlət xidmətlərindən daha rahat istifadə etmək, iqtisadi-sosial dəstək proqramlarından yararlanmaq üçün zəruridir. Qarabağ iqtisadi rayonunda məskunlaşdığınız tarixi və vətəndaşlıq mənsubiyyətinizi təsdiq edən sənədləri əlavə etməklə müraciəti tamamlamaq mümkündür. Azərbaycan Respublikasının Miqrasiya Məcəlləsinin 45-ci maddəsində nəzərdə tutulan hallardan azı birinə sahib olduqda, şəxs müvəqqəti yaşama icazəsi əldə edə bilər. Bu hallara Azərbaycan Respublikası vətəndaşı ilə yaxın qohumluq münasibətlərində olma, daşınmaz əmlakı və ya banklarda müddətli əmanət müqaviləsi əsasında pul vəsaiti olma, əmək fəaliyyəti ilə məşğul olmaq üçün iş icazəsi alma, təhsil alma, xarici hüquqi şəxsin Azərbaycan Respublikasındakı filial və nümayəndəliyinin rəhbəri və onun müavini vəzifəsini tutma, sahibkarlıq fəaliyyəti ilə məşğul olma və s. daxildir.`,
    en: `You can apply for a residence permit card after the initial registration. This card is necessary for using government services, benefiting from economic and social support programs more conveniently. Application can be completed by adding the date of your settling in Karabakh economic region and the documents confirming your citizenship.Person can obtain temporary residence permit if he/she is eligible for at least one of the grounds envisaged in the article 45 of the Migration Code of the Republic of Azerbaijan. These cases include being in close relationship with a citizen of the Republic of Azerbaijan, possessing real estate or having funds in banks on the basis of a term deposit agreement, obtaining a work permit to engage in labor activity, studying, holding positions of head or deputy head in the branch or representation of a foreign juridical person in the Republic of Azerbaijan and etc.`,
    ru: `После постановки на первичный учет Вы можете обратиться для приобретения удостоверения разрешения на проживание. Это удостоверение необходимо для более удобного пользования государственными услугами и для того, чтобы воспользоваться программами социально-экономической поддержки. Обращение можно завершить добавив дату заселения в Карабахе и документы, подтверждающие гражданскую принадлежность. Лицо может получить разрешение на временное проживание, если у него имеется хотя бы один из случаев, предусмотренных статьей 45 Миграционного Кодекса Азербайджанской Республики. К этим случаям относятся наличие близкой родственной связи с гражданином Азербайджанской Республики, недвижимости или денежных средств в банках на основании договора срочного вклада, получение разрешения на работу на осуществление трудовой деятельности, обучение, занятие должности руководителя филиала и представительства иностранного юридического лица в Азербайджанской Республике и должности его заместителя, занятие предпринимательской деятельностью и т.д.`,
    am: `Նախնական գրանցումից հետո կարող եք դիմել բնակության թույլտվության վկայական ձեռք բերելու համար: Այս վկայականն անհրաժեշտ է պետական ծառայություններից առավել հարմարավետ օգտվելու և սոցիալ-տնտեսական աջակցության ծրագրերից օգտվելու համար: Դիմումը կարող է լրացվել՝ ավելացնելով Ղարաբաղում բնակության ամսաթիվը և քաղաքացիությունը հաստատող փաստաթղթերը։ Անձը կարող է ստանալ ժամանակավոր կացության թույլտվություն, եթե ունի ԱՀ միգրացիոն օրենսգրքի 45-րդ հոդվածով նախատեսված պայմաններից գոնե մեկը։ Այդ դեպքերը ներառում են ԱՀ քաղաքացու հետ ընտանեկան սերտ կապի առկայություն, ժամկետային ավանդի պայմանագրի հիման վրա բանկերում անշարժ գույք կամ դրամական միջոցներ, աշխատանքային գործունեության թույլտվություն ստանալը, վերապատրաստումը, ղեկավարի պաշտոնը զբաղեցնելը։ Ադրբեջանական Հանրապետությունում օտարերկրյա իրավաբանական անձի մասնաճյուղի և ներկայացուցչության պաշտոնը և նրա տեղակալի պաշտոնը, ձեռնարկատիրական գործունեությամբ զբաղվելը և այլն մանրամասն:`,
  },
  tab_info_4: {
    az: `Etdiyiniz müraciətlərin statusuna ilkin
    qeydiyyat nömrəsi və ya ad, soyad, doğum tarixinizi
    daxil etməklə baxa bilərsiniz.`,
    en: "You can check the status of your application by entering initial registration number or first name, last name and date of birth.",
    ru: "Вы можете проверить статус поданных обращений введя первичный регистрационный номер или своё имя, фамилию, дату рождения.",
    am: "Ներկայացված հայտերի կարգավիճակը կարող եք ստուգել՝ մուտքագրելով վերաինտեգրողի գրանցման համարը կամ ձեր անունը, ազգանունը, ծննդյան ամսաթիվը:",
  },
  "Reinteqrant qeydiyyatı": {
    az: "İlkin qeydiyyat",
    en: "Initial registration",
    ru: "Первичная регистрация",
    am: "Նախնական գրանցում",
  },
  "Vətəndaşlığın təsdiqi": {
    az: "Vətəndaşlıq",
    en: "Citizenship",
    ru: "Гражданство",
    am: "Քաղաքացիություն",
  },
  "Yaşama icazəsi": {
    az: "Yaşama icazəsi",
    en: "Residence permit",
    ru: "Разрешение на проживание",
    am: "Բնակության թույլտվություն",
  },
  "Müraciətin statusu": {
    az: "Müraciətin statusu",
    en: "Application status",
    ru: "Статус обращения",
    am: "Դիմումի կարգավիճակը",
  },
  tab_name_1_desc: {
    az: "İlkin qeydiyyata alınmaq üçün",
    en: "For initial registration",
    ru: "Для постановки на первичный учет",
    am: "Վերաինտեգրման գրանցման համար",
  },
  tab_name_2_desc: {
    az: `Azərbaycan Respublikası vətəndaşlığının təsdiqi üçün`,
    en: "For confirmation of the citizenship of the Republic of Azerbaijan",
    ru: "Для подтверждения гражданства Азербайджанской Республики",
    am: "Ադրբեջանի Հանրապետության քաղաքացիությունը հաստատելու համար",
  },
  tab_name_3_desc: {
    az: "Yaşama icazəsi vəsiqəsi əldə etmək üçün",
    en: "For obtaining permission card for residence",
    ru: "Для получения удостоверения разрешения на проживание",
    am: "Բնակության թույլտվության վկայական ստանալու համար",
  },
  tab_name_4_desc: {
    az: "Müraciətin statusuna bax",
    en: "Check the application status ",
    ru: "Просмотр статуса обращения",
    am: "Գործի կարգավիճակի դիտում",
  },
  validation_text_mustbe_50: {
    az: "50 və ya daha az simvol olmalıdır",
    en: "Must be 50 characters or less",
    ru: "Должно быть не более 50 символов.",
    am: "Պետք է լինի 50 նիշ կամ պակաս",
  },
  please_select_contact_type: {
    az: "Əlaqə növünü seçin",
    en: "Select the contact type",
    ru: "Выберите тип контакта",
    am: "Ընտրեք կոնտակտի տեսակը",
  },
  please_add_value_to_selected_contact_type: {
    az: "seçilmiş əlaqə növünə uyğun məlumat əlavə edin",
    en: "please add information relevant to selected contact type",
    ru: "пожалуйста, добавьте информацию в соответствии с выбранным типом контакта",
    am: "խնդրում ենք արժեք ավելացնել ընտրված կոնտակտի տեսակին",
  },
  please_add_valid_email: {
    az: "etibarlı e-poçt əlavə edin",
    en: "please add valid email",
    ru: "пожалуйста, добавьте действующий адрес электронной почты",
    am: "խնդրում ենք ավելացնել վավեր էլփոստի հասցե",
  },
  validation_text_mustbe_100: {
    az: "100 və ya daha az simvol olmalıdır",
    en: "Must be 100 characters or less",
    ru: "Должно быть не более 100 символов.",
    am: "Պետք է լինի ոչ ավելի, քան 100 նիշ",
  },

  "Already exist": {
    az: "Mövcuddur",
    en: "Already exist",
    ru: "Уже существует",
    am: "Արդեն գոյություն ունի",
  },
  "Captcha is required": {
    az: "Məxfi kod tələb olunur",
    en: "Captcha is required",
    ru: "Требуется секретный код",
    am: "Պահանջվում է գաղտնի ծածկագիր",
  },
  "Captcha Does Not Match": {
    az: "Məxfi kod eyni deyil",
    en: "Captcha Does Not Match",
    ru: "Секретный код  не соответствует",
    am: "Գաղտնի կոդը նույնը չէ",
  },
  reload: {
    az: "Yenilə",
    en: "Reload",
    ru: "Перезагрузить",
    am: "Վերբեռնել",
  },
  "Müraciətiniz qeydə alındı": {
    az: "Müraciətiniz qeydə alındı",
    en: "Your application has been registered.",
    ru: "Ваше обращение зарегистрировано.",
    am: "Ձեր հարցումը գրանցվել է:",
  },
  success_message_reinteg_app: {
    az: `Müraciətiniz yoxlanıldıqdan sonra qeyd etdiyiniz əlaqə vasitələri ilə sizə məlumat veriləcək. Müraciətinizə fərdi məlumatların işlənilməsi ilə bağlı qanunvericiliyin tələblərinə uyğun olaraq baxılacaqdır.`,
    en: "You will be informed via contact details provided by you after review of your application. Your application will be reviewed in accordance with the requirements of legislation on processing personal information.",
    ru: "После проверки вашего обращения, вы будете проинформированы с помощью контактных данных, указанных вами. Ваше обращение будет рассмотрено в соответствии с требованиями законодательства об обработке персональных данных.",
    am: "Ձեր դիմումը ստուգելուց հետո դուք կտեղեկացվեք ձեր տրամադրած կապի միջոցներով:Ձեր դիմումը կմշակվի անձնական տվյալների մշակման մասին օրենսդրության պահանջներին համապատասխան։",
  },
  success_message_desc1: {
    az: `Müraciətinizi göndərdikdən sonra müraciət zamanı daxil etdiyiniz əlaqə vasitələrilə sizə əlavə məlumat veriləcək. “Müraciətin statusuna bax” bölməsi vasitəsilə müraciətinizin statusunu izləyə bilərsiniz. Müraciətiniz fərdi məlumatların işlənilməsi ilə bağlı qanunvericiliyin tələblərinə uyğun icra ediləcəkdir.`,
    en: "You will be provided with additional information through the means of contact you mentioned in the application after the application has been sent. You can check the status of your application through “Application status” section. Your application will be processed according to requirements of the legislation on use of personal data. ",
    ru: "После отправки обращения, Вам будет предоставлена дополнительная информация через контактные данные, которые Вы ввели при подаче обращения. Вы можете отслеживать статус своего обращения посредством раздела «Cтатус обращения». Ваше обращение будет исполнено в соответствии с требованиями законодательства об обработке персональных данных.",
    am: "Դիմումը ուղարկելուց հետո ձեզ լրացուցիչ տեղեկատվություն կտրամադրվի դիմումում ձեր նշած կապի միջոցների միջոցով: Ձեր դիմումի կարգավիճակը կարող եք ստուգել «Դիմումի կարգավիճակ» բաժնի միջոցով: Ձեր դիմումը կմշակվի անձնական տվյալների օգտագործման մասին օրենսդրության պահանջներին համապատասխան:",
  },
  "Fərdi məlumatlarınız qorunur!": {
    az: "Fərdi məlumatlarınız qorunur! Bildiriş nömrəsi və müraciətinizin icrası ilə bağlı məlumatların göndərilməsi üçün əlaqə məlumatlarınızı dəqiq qeyd etməyiniz zəruridir!",
    en: "Your personal information is protected! It is necessary to provide accurate contact information to receive the notification number and information about the processing of your application!",
    ru: "Ваши персональные данные защищаются! Просьба точно указать контактные данные для отправки номера уведомления и информации об исполнении Вашего обращения!",
    am: "Ձեր անձնական տվյալներըպաշտպանված են! Խնդրումենք տրամադրել ճշգրիտ կոնտակտային տվյալներ՝ ծանուցման համարը և ձեր բողոքարկման քննարկման մասին տեղեկություններ ուղարկելու համար:",
  },
  "Doğum tarixi": {
    az: "Doğum tarixi",
    en: "Date of birth",
    ru: "Дата рождения",
    am: "Ծննդյան ամսաթիվ",
  },
  birthDate: {
    az: "Doğum tarixi",
    en: "Date of birth",
    ru: "Дата рождения",
    am: "Ծննդյան ամսաթիվ",
  },
  "Ünvan məlumatları": {
    az: "Ünvan (rayon, şəhər, kənd)",
    en: "Address (region, city, village)",
    ru: "Адрес (район, город, село)",
    am: "Հասցե (շրջան, քաղաք, գյուղ)",
  },
  "Əlaqə növü": {
    az: "Əlaqə növü",
    en: "Means of contact",
    ru: "Тип контакта",
    am: "Կապի տեսակը",
  },
  NotSet: {
    az: "Seçilməyib",
    en: "Not set",
    ru: "Не выбрано",
    am: "Ընտրված չէ",
  },
  Email: {
    az: "Elektron poçt",
    en: "Email",
    ru: "Электронная почта",
    am: "Էլ",
  },
  Telephone: {
    az: "Telefon",
    en: "Telephone",
    ru: "Телефон",
    am: "Հեռախոս",
  },
  WhatsApp: {
    az: "WhatsApp nömrəsi",
    en: "WhatsApp number",
    ru: "Номер WhatsApp",
    am: "whatsapp համարը",
  },
  "Choose contact type": {
    az: "Əlaqə növünü seçin",
    en: "Choose contact type",
    ru: "Выберите тип контакта",
    am: "Ընտրեք կոնտակտի տեսակը",
  },
  "Telefon (ev, mobil, iş, digər)": {
    az: "Telefon (ev, mobil, iş, digər)",
    en: "Phone number (home, mobile, work, other)",
    ru: "Номер телефона (домашний, мобильный, рабочий, другой)",
    am: "Հեռախոսահամար (տուն, բջջային, աշխատանք, այլ)",
  },
  "Enter phone number": {
    az: "Nömrənizi daxil edin",
    en: "Enter phone number",
    ru: "Введите номер телефона",
    am: "Մուտքագրեք ձեր համարը",
  },
  "Şəxsiyyəti təsdiq edən sənəd": {
    az: "Şəxsiyyəti təsdiq edən sənəd",
    en: "Document confirming identity",
    ru: "Документ, удостоверяющий личность",
    am: "Անձը հաստատող փաստաթուղթ",
  },
  BirthCertificate: {
    az: "Doğum haqqında şəhadətnamə",
    en: "Birth Certificate",
    ru: "Свидетельство о рождении",
    am: "Ծննդյան վկայական",
  },
  DrivingLicense: {
    az: "Sürücülük vəsiqəsi",
    en: "Driving License",
    ru: "Водительское удостоверение",
    am: "Վարորդական իրավունք",
  },
  Pasport: {
    az: "Pasport",
    en: "Passport",
    ru: "Паспорт",
    am: "Անձնագիր",
  },
  Other: {
    az: "Digər",
    en: "Other",
    ru: "Другое",
    am: "Այլ",
  },
  "Fayl seçin": {
    az: "Fayl seçin",
    en: "Choose file",
    ru: "Выбрать файл",
    am: "Ընտրել ֆայլը",
  },
  "Upload file": {
    az: "Fayl yükləyin",
    en: "Upload file",
    ru: "Загрузить файл",
    am: "Ներբեռնել ֆայլ",
  },
  Qeyd: {
    az: "Qeyd",
    en: "Note",
    ru: "Примечание",
    am: "Նշում",
  },
  please_select_country: {
    az: "Ölkə seçin",
    en: "Please select country",
    ru: "Пожалуйста, выберите страну",
    am: "Խնդրում ենք ընտրել երկիրը",
  },
  please_add_value_to_selected_country: {
    az: "Ölkəni seçin",
    en: "Please select country",
    ru: "Пожалуйста, выберите страну",
    am: "Խնդրում ենք ընտրել երկիրը",
  },
  please_select_parentType: {
    az: "Valideynləriniz barədə məlumatları seçin",
    en: "Please select information on your parents",
    ru: "Пожалуйста, выберите информацию о ваших родителях",
    am: "Խնդրում ենք ընտրել ձեր ծնողների մասին տեղեկությունները",
  },
  please_add_parentFirstName: {
    az: "Valideyinizin adını daxil edin",
    en: "Please add parent’s first name",
    ru: "Пожалуйста, введите имя Вашего родителя",
    am: "Խնդրում ենք ավելացնել ծնողի անունը",
  },
  please_add_parentDocs: {
    az: "Sənədi yükləyin",
    en: "Please add file",
    ru: "Пожалуйста, загрузите документ",
    am: "Խնդրում ենք ավելացնել ֆայլ",
  },
  "Reintegration number is required": {
    az: "İlkin qeydiyyat nömrəsi vacibdir",
    en: "Initial registration number is required",
    ru: "Первичный регистрационный номер важен",
    am: "Պահանջվում է ռեինտեգրանտի գրանցման համարը",
  },
  "Reinteqrasiya nömrəsi": {
    az: "İlkin qeydiyyat nömrəsi",
    en: "Initial registration number",
    ru: "Первичный регистрационный номер",
    am: "Ռեինտեգրատորի գրանցման համարը",
  },
  "Daxil ol": {
    az: "Daxil ol",
    en: "Sign in",
    ru: "Войти",
    am: "Ներս մտնել",
  },
  "Reinteqrantın qeydiyyat nömrəsi": {
    az: "İlkin qeydiyyat nömrəsi",
    en: "Initial registration number",
    ru: "Первичный регистрационный номер",
    am: "Վերաինտեգրվողի գրանցման համարը",
  },
  "Qarabağda məskunlaşdığınız tarix": {
    az: "Qarabağ iqtisadi rayonunda məskunlaşdığınız tarix",
    en: "Date of your settling in Karabakh economic region",
    ru: "Дата заселения в Карабахе",
    am: "Ղարաբաղի տնտեսական տարածաշրջանում ձեր հաստատվելու ամսաթիվը",
  },
  "vətəndaşlığında olmağınızı təsdiq edən sənəd": {
    az: `Azərbaycan Respublikasının (və ya Azərbaycan SSR-in) vətəndaşlığında olmağınızı təsdiq edən sənəd (olduğu təqdirdə)`,
    en: "A document confirming your citizenship of the Republic of Azerbaijan (or Azerbaijan SSR) (if applicable)",
    ru: "Документ, подтверждающий гражданство Азербайджанской Республики (или Азербайджанской ССР) (при наличии)",
    am: "Ադրբեջանի Հանրապետության (կամ Ադրբեջանական ԽՍՀ) քաղաքացիությունը հաստատող փաստաթուղթ (եթե այդպիսիք կան)",
  },
  "mənsubiyyətiniz varmı": {
    az: `Hazırda hər hansı dövlətin vətəndaşlığına mənsubiyyətiniz varmı?`,
    en: "Do you currently hold citizenship of any country?",
    ru: "Имеете ли Вы в настоящее время гражданство какой-либо страны?",
    am: "Դուք ներկայումս ունե՞ք որևէ երկրի քաղաքացիություն:",
  },
  Bəli: {
    az: "Bəli",
    en: "Yes",
    ru: "Да",
    am: "Այո՛",
  },
  Xeyr: {
    az: "Xeyr",
    en: "No",
    ru: "Нет",
    am: "Ոչ",
  },
  "Mənsub olduğu dövlətin vətəndaşlığı": {
    az: "Mənsub olduğu dövlətin vətəndaşlığı",
    en: "Citizenship of corresponding state",
    ru: "Гражданство государства принадлежности ",
    am: "Ծագման պետության քաղաքացիություն",
  },
  Seçilməyib: {
    az: "Seçilməyib",
    en: "Not selected",
    ru: "Не выбрано",
    am: "Ընտրված չէ",
  },
  "Vətəndaşlıq sənədin surətini əlavə etmək": {
    az: "Vətəndaşlıq mənsubiyyətinizi təsdiq edən sənədin surətini əlavə etmək",
    en: "Upload the copy of the document confirming your citizenship",
    ru: "Добавить копию документа, подтверждающего Вашу гражданскую принадлежность",
    am: "Ավելացրեք քաղաքացիությունը հաստատող փաստաթղթի պատճենը",
  },
  Country: {
    az: "Ölkə",
    en: "Country",
    ru: "Страна",
    am: "Երկիր",
  },
  Documents: {
    az: "Sənədlər",
    en: "Documents",
    ru: "Документы",
    am: "Փաստաթղթեր",
  },
  "Valideynləriniz barədə məlumatlar": {
    az: "Valideynləriniz barədə məlumatlar",
    en: "Information on your parents",
    ru: "Информация о Ваших родителях",
    am: "Տեղեկություններ ձեր ծնողների մասին",
  },
  Mother: {
    az: "Ana",
    en: "Mother",
    ru: "Мать",
    am: "Մայրիկ",
  },
  Father: {
    az: "Ata",
    en: "Father",
    ru: "Отец",
    am: "Հայրիկ",
  },
  "vətəndaşlığında olduqlarını təsdiq edən sənəd": {
    az: `Azərbaycan Respublikasının (və ya Azərbaycan SSR-in) vətəndaşlığında olduqlarını təsdiq edən sənəd`,
    en: "A document confirming their citizenship of the Republic of Azerbaijan (or Azerbaijan SSR)",
    ru: "Документ, подтверждающий  гражданство Азербайджанской Республики (или Азербайджанской ССР)",
    am: "Ադրբեջանի Հանրապետության (կամ Ադրբեջանական ԽՍՀ) քաղաքացիությունը հաստատող փաստաթուղթ",
  },
  "Parent Type": {
    az: "Valideyn",
    en: "Parent",
    ru: "Родитель",
    am: "Ծնող",
  },
  "Təqdim etmək istədiyiniz digər sənədləri əlavə etmək": {
    az: "Təqdim etmək istədiyiniz digər sənədləri əlavə etmək",
    en: "Add any other documents you would like to submit",
    ru: "Прикрепить другие документы, которые Вы хотите предоставить",
    am: "Ներկայացրեք ցանկացած այլ փաստաթուղթ, որը ցանկանում եք ներկայացնել",
  },
  "Əlavə qeyd etmək istədiyiniz məlumatlar": {
    az: "Əlavə qeyd etmək istədiyiniz məlumatlar",
    en: "Any additional information you would like to add",
    ru: "Дополнительная информация, которую Вы хотели бы указать",
    am: "Ցանկացած լրացուցիչ տեղեկություն, որը կցանկանայիք ավելացնել»",
  },
  "Please fill the required fields": {
    az: "Zəhmət olmasa tələb olunan sahələri doldurun",
    en: "Please fill the required fields",
    ru: "Пожалуйста, заполните обязательные поля",
    am: "Խնդրում ենք լրացնել պահանջվող դաշտերը",
  },
  "Please fill the required field": {
    az: "Vacib xanalar doldurulmalıdır!",
    en: "Required fields must be filled!",
    ru: "Важные поля должны быть заполнены!",
    am: "Կարևոր դաշտերը պետք է լրացվեն:",
  },
  "No result found": {
    az: "Nəticə tapılmadı",
    en: "No result found",
    ru: "Результатов не найдено",
    am: "Ոչ մի արդյունք չի գտնվել",
  },
  "Şəxsi məlumatlar ilə axtar": {
    az: "Şəxsi məlumatlar ilə axtar",
    en: "Search by the personal data",
    ru: "Поиск по личным данным",
    am: "Որոնել ըստ անձնական տվյալների",
  },
  "Reinteqrant nömrəsi ilə axtar": {
    az: "İlkin qeydiyyat nömrəsi ilə axtar",
    en: "Search by the initial registration number",
    ru: "Поиск по первичному регистрационному номеру",
    am: "Որոնեք վերաինտեգրվողի գրանցման համարով",
  },
  search: {
    az: "Axtar",
    en: "Search",
    ru: "Поиск",
    am: "Որոնում",
  },
  "Ad Soyad": {
    az: "Ad Soyad",
    en: "Full name",
    ru: "Имя фамилия",
    am: "Անուն և ազգանուն",
  },
  Tarix: {
    az: "Tarix",
    en: "Date",
    ru: "Дата",
    am: "Ամսաթիվը",
  },
  Status: {
    az: "Status",
    en: "Status",
    ru: "Статус",
    am: "Կարգավիճակ",
  },
  "Reinteqrasiya haqqında məlumatlı olun": {
    az: "Reinteqrasiya haqqında məlumatlı olun",
    en: "Be informed about reintegration",
    ru: "Будьте информированы о реинтеграции ",
    am: "Տեղեկացված եղեք վերաինտեգրման մասին",
  },

  // #endregion
  subscribe: {
    az: "Abunə ol",
    en: "Subscribe",
    ru: "Подпишись",
    am: "Բաժանորդագրվել",
  },
  email: {
    az: "Elektron poçt",
    en: "Email",
    am: "Էլ",
    ru: "Электронная почта",
  },
  firstName: {
    az: "Ad",
    en: "First name",
    am: "Անուն",
    ru: "Имя",
  },
  lastName: {
    az: "Soyad",
    en: "Last name",
    am: "Ազգանունը",
    ru: "Фамилия",
  },
  an_error_occurred: {
    az: "Xəta baş verdi",
    en: "An error occurred",
    am: "Սխալ է տեղի ունեցել",
    ru: "Произошла ошибка",
  },
  "An error occurred": {
    az: "Xəta baş verdi",
    en: "An error occurred",
    am: "Սխալ է տեղի ունեցել",
    ru: "Произошла ошибка",
  },
  send: {
    az: "Göndər",
    en: "Send",
    am: "Ուղարկել",
    ru: "Отправить",
  },
  close: {
    az: "Bağla",
    en: "Close",
    am: "փակել",
    ru: "Закрыть",
  },
  required: {
    az: "Tələb olunur",
    en: "Required",
    am: "Պահանջվում է",
    ru: "Обязательно для заполнения",
  },
  azerbaijan: {
    az: "Azərbaycan",
    en: "Azerbaijan",
    am: "Ադրբեջան",
    ru: "Азербайджан",
  },
  armenian: {
    az: "Ադրբեջան",
    en: "անգլերեն",
    am: "Հայերեն",
    ru: "Հայաստան",
  },
  english: {
    az: "İngilis",
    en: "English",
    am: "Անգլերեն",
    ru: "Английский",
  },
  russian: {
    az: "Rus",
    en: "Russian",
    am: "ռուսերեն",
    ru: "Русский",
  },
  no_data: {
    az: "Məlumat tapılmadı",
    en: "No data found",
    am: "Տվյալներ չեն գտնվել",
    ru: "Информация не найдена",
  },
  privacy: {
    az: "Gizlilik",
    en: "Privacy",
    am: "Գաղտնիություն",
    ru: "Конфиденциальность",
  },
  language: {
    az: "Dil",
    en: "Language",
    am: "Լեզու",
    ru: "Язык",
  },
  home: {
    az: "Ana səhifə",
    en: "Home",
    am: "Տուն",
    ru: "Главная страница",
  },
  settings: {
    az: "Parametrlər",
    en: "Settings",
    am: "Կարգավորումներ",
    ru: "Настройки",
  },
  done: {
    az: "Tamamla",
    en: "Done",
    am: "Կատարած",
    ru: "Завершить",
  },
  cancel: {
    az: "Ləğv et",
    en: "Cancel",
    am: "Չեղարկել",
    ru: "Отменить",
  },
  open_link: {
    az: "Bağlantını aç",
    en: "Open link",
    am: "Բացել հղումը",
    ru: "Открыть ссылку",
  },
  clear: {
    az: "Təmizlə",
    en: "Clear",
    am: "Մաքրել",
    ru: "Очистить",
  },
  name: {
    az: "Ad",
    en: "First name",
    am: "Անուն",
    ru: "Имя",
  },
  username: {
    az: "İstifadəçi adı",
    en: "Username",
    am: "Օգտագործողի անունը",
    ru: "Имя пользователя",
  },
  password: {
    az: "Şifrə",
    en: "Password",
    am: "Գաղտնաբառ",
    ru: "Пароль",
  },
  security: {
    az: "Təhlükəsizlik",
    en: "Security",
    am: "Անվտանգություն",
    ru: "Безопасность",
  },
  loading: {
    az: "Yüklənir",
    en: "Loading",
    am: "Բեռնվում է",
    ru: "Загрузка",
  },
  delete_item: {
    az: "Məlumatı sil",
    en: "Delete item",
    am: "Ջնջել տեղեկատվությունը",
    ru: "Удалить информацию",
  },

  min_one_contact_required: {
    az: "Əlaqə vasitələrindən birinin seçilməsi mütləqdir",
    en: "Selection of one of the means of communication is mandatory",
    am: "Կապի միջոցներից մեկի ընտրությունը պարտադիր է",
    ru: "Выбор одного из средств связи обязателен",
  },
  please_select_doctype: {
    az: "Sənəd növünü seçin",
    en: "Select document type",
    am: "Ընտրեք փաստաթղթի տեսակը",
    ru: "Выберите тип документа",
  },
  please_add_documents: {
    az: "Sənəd əlavə edin",
    en: "Add Document",
    am: "Ավելացնել փաստաթղթեր",
    ru: "Добавить документ",
  },
  docType: {
    az: "Sənəd növü",
    en: "Document type",
    am: "Փաստաթղթի տեսակը",
    ru: "Тип документа",
  },
  file_not_allowed: {
    az: "İcazə verilməyən fayl, tələb olunan fayl format PDF və ya JPG",
    en: "File not allowed, required file format PDF or JPG",
    am: "Ֆայլը չի թույլատրվում, ֆայլի պահանջվող ձևաչափը PDF կամ JPG է",
    ru: "Файл не разрешен, требуемый формат файла PDF или JPG",
  },
  file_size_limit: {
    az: "Faylın həcmi 2MB artıq olmamalıdır",
    en: "The file size must not exceed 2 MB",
    am: "Ֆայլի չափը չպետք է գերազանցի 2 ՄԲ",
    ru: "Размер файла не должен превышать 2 МБ",
  },
  selected_files: {
    az: "Seçilmiş fayllar",
    en: "Selected files",
    am: "Ընտրված ֆայլեր",
    ru: "Выбранные файлы",
  },
  email_allready_in_use: {
    az: "Artıq abunə olmusunuz!",
    en: "You have already subscribed",
    am: "Դուք արդեն բաժանորդագրված եք!",
    ru: "Вы уже подписаны!",
  },
  successfully_subscribed: {
    az: "Abunə olundu!",
    en: "Subscribed!",
    am: "Դուք բաժանորդագրված եք!",
    ru: "Вы подписаны!",
  },
  delete: {
    az: "Sil",
    en: "Delete",
    am: "Ջնջել",
    ru: "Удалить",
  },
  "Reason for application": {
    az: "Müraciət səbəbi",
    en: "Reason for application",
    am: "Դիմելու պատճառը",
    ru: "Причина подачи обращения",
  },
  chat_header_title: {
    az: "Zəhmət olmasa, əlaqə vasitələri və sualınızla bağlı mümkün qədər ətraflı məlumat daxil edərdiniz.",
    en: "Please include contact details and as much detail as possible regarding your question",
    am: "Խնդրում ենք ներառել կոնտակտային տվյալները և հնարավորինս շատ մանրամասներ ձեր հարցի վերաբերյալ:",
    ru: "Пожалуйста, укажите контактные данные и как можно более подробную информацию о Вашем вопросе.",
  },
  chat: {
    az: "İsmarıc",
    en: "Chat",
    am: "Զրուցարան",
    ru: "Чат",
  },
  chat_text_placeholder: {
    az: "Mesajınızı bura yazın...",
    en: "Type your message here...",
    am: "Մուտքագրեք ձեր հաղորդագրությունը այստեղ...",
    ru: "Напишите Ваше сообщение здесь...",
  },
};

export default DICTIONARY;
