import React, { Fragment } from "react";
import { translate } from "../services/translation/translation.service";

function NotFound() {
  return (
    <Fragment>
      <h1 className="center text-center" style={{ paddingTop: "10%" }}>
        404
      </h1>
      <h3 className="center text-center mb-2">{translate("page_not_found")}</h3>
      <p className="center text-center">
        <a href="/" style={{ fontSize: 20, fontWeight: 400 }}>
          {translate("back_to_home")}
        </a>
      </p>
    </Fragment>
  );
}

export default NotFound;
