export function normalizeSearchParams(params) {
  const resolvedParams = {};
  Object.entries(params).forEach(([key, val]) => {
    if (val) resolvedParams[key] = val;
  });
  return resolvedParams;
}

export const generateUUID = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const validateEmail = (email) => {
  let regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let result = regex.exec(email);
  return result;
};

export function checkIsNonWorkingDay() {
  const workingDays = [1, 2, 3, 4, 5];
  const currentDate = new Date();

  const currentHours = currentDate.getHours();
  const currentWeekDay = currentDate.getDay();

  const isWorkingHours = currentHours >= 9 && currentHours < 18;
  const isWorkingDay = workingDays.includes(currentWeekDay);

  return !Boolean(isWorkingHours && isWorkingDay);
}
