import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import OwlCarousel from "react-owl-carousel";
import {
  getCurrentLang,
  translate,
} from "../services/translation/translation.service";
// import { news } from "../utils/newsData";
import useWindowDarkMode from "../hooks/useWindowDarkMode";
import { crmApiGetList } from "../services/api.service";
import { crmApiNames } from "../utils/constants";

const owlCarouselOptions = {
  margin: 10,
  responsiveClass: true,
  loop: true,
  nav: true,
  dots: false,
  autoplay: true,
  navText: [
    '<i className="fas fa-chevron-left" style="padding: 5px"></i>',
    '<i className="fas fa-chevron-right" style="padding: 5px"></i>',
  ],
  navElement: "button",
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 2,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  },
};

function NewsComponent() {
  const darkMode = useWindowDarkMode();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchNews();
  }, []);

  async function fetchNews() {
    try {
      setLoading(true);
      let newsList = [];
      const localNews = localStorage.getItem("news");
      if (localNews) {
        const parsedData = JSON.parse(localNews);
        if (Date.now() - parsedData.time > 300000) {
          const {
            data: {
              data: { list },
            },
          } = await crmApiGetList(crmApiNames.reintegrationNews, {
            _limit: 20,
            _sort: "newsDate:desc",
          });
          newsList = list;
          localStorage.setItem(
            "news",
            JSON.stringify({ list: newsList, time: Date.now() })
          );
        } else {
          newsList = parsedData.list;
        }
      } else {
        const {
          data: {
            data: { list },
          },
        } = await crmApiGetList(crmApiNames.reintegrationNews, {
          _limit: 20,
          _sort: "newsDate:desc",
        });
        newsList = list;
        localStorage.setItem(
          "news",
          JSON.stringify({ list: newsList, time: Date.now() })
        );
      }
      setNews(newsList);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error) {
      setLoading(false);
      console.log("fetchNews error", error);
    }
  }

  const filteredNewsByLang = news.filter((item) =>
    item.supportedLangs.includes(getCurrentLang())
  );

  return (
    <Fragment>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <OwlCarousel className="owl-theme" {...owlCarouselOptions}>
            {filteredNewsByLang.map((item) => (
              <div className="item owl-item-c" key={item.newsId}>
                <div className="card h-100">
                  <Link
                    to={`/blog-post/${item.newsId}`}
                    state={{ router: "/registration", activeTab: 5 }}
                  >
                    <img
                      src={item?.image || "/assets/images/dmx-news1.jpg"}
                      className="card-img-top"
                      alt={item.title}
                    />
                  </Link>
                  <div className="card-body">
                    <p className="card-news-text">
                      <Link
                        style={darkMode ? { color: "#fff" } : {}}
                        to={`/blog-post/${item.newsId}`}
                        state={{ router: "/registration", activeTab: 5 }}
                      >
                        {item?.[`title_${getCurrentLang()}`]}
                      </Link>
                    </p>
                  </div>
                  <div className="card-footer">
                    <small className="text-muted">
                      {item?.newsDate
                        ? moment(item.newsDate).format("DD.MM.YYYY HH:mm")
                        : ""}
                    </small>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
          <div className="owl-nav">
            <div className="allNews">
              <Link to={"/blog"}>
                {translate("all_news")} <i className="icofont-arrow-right"></i>
              </Link>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
}

export default NewsComponent;
