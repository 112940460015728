import React, { Fragment, useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import CitizenshipAppForm from "../components/Registration/CitizenshipAppForm";
import RegistrationFooter from "../components/Registration/RegistrationFooter";
import RegistrationHeader from "../components/Registration/RegistrationHeader";
import RegistrationStatusForm from "../components/Registration/RegistrationStatusForm";
import ReintegrationAppForm from "../components/Registration/ReintegrationAppForm";
import ResidenceAppForm from "../components/Registration/ResidenceAppForm";
// import SocialIcons from "../components/SocialIcons";

// import { fetchCountries } from "../store/slices/registrationSlice";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { translate } from "../services/translation/translation.service";

import infoIcon from "../assets/img/info-icon.png";
// import ChatWidget from "../components/ChatWidget/ChatWidget";
import NewsComponent from "../components/NewsComponent";
import { useLocation } from "react-router-dom";

function Registration() {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("1");

  const { width } = useWindowDimensions();
  const routerData = location.state;

  // const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(fetchCountries());
  }, []);

  useEffect(() => {
    checkRouterForTabs();
  }, [routerData]);

  function checkRouterForTabs() {
    if (routerData?.activeTab) {
      let el = document.getElementById("news-section-tab");
      let el1 = document.getElementById("pills-home-tab");
      let el2 = document.getElementById("pills-home");
      let el3 = document.getElementById("news-section");

      // ACTIVATE NEWS TAB
      if (el) {
        setActiveTab(String(routerData.activeTab));
        el.classList.add("active");
      }
      if (el3) {
        el3.classList.add("active");
        el3.classList.add("show");
      }

      // DEACTIVATE CURRENT ACTIVE TAB
      if (el1) {
        el1.classList.remove("active");
      }
      if (el2) {
        el2.classList.remove("active");
        el2.classList.remove("show");
      }
    }
  }

  const openFaqLastSection = () => {
    const el = document.getElementById("faqModal");
    if (el) {
      el.classList.add("show");
      el.style.display = "block";
      el.setAttribute("aria-modal", "true");
      el.setAttribute("role", "dialog");
      el.removeAttribute("aria-hidden");

      setTimeout(() => {
        const accBtnEl = document.getElementById("flush-collapseFive-btn");
        const accDivEl = document.getElementById("flush-collapseFive");

        if (accBtnEl) {
          accBtnEl.setAttribute("aria-expanded", "true");
          accBtnEl.classList.remove("collapsed");
        }
        if (accDivEl) {
          accDivEl.classList.add("show");
        }
      }, 100);
    }
  };

  const closeFaqModal = () => {
    const el = document.getElementById("faqModal");
    if (el) {
      el.classList.remove("show");
      el.style.display = "none";
      el.setAttribute("aria-modal", "false");
      el.removeAttribute("role");
      el.setAttribute("aria-hidden", "true");

      const accBtnEl = document.getElementById("flush-collapseFive-btn");
      const accDivEl = document.getElementById("flush-collapseFive");

      if (accBtnEl) {
        accBtnEl.setAttribute("aria-expanded", "false");
        accBtnEl.classList.add("collapsed");
      }
      if (accDivEl) {
        accDivEl.classList.remove("show");
      }
    }
  };

  function renderModals() {
    return (
      <div>
        {/* faq modal */}
        <div
          className="modal fade"
          id="faqModal"
          tabIndex="-1"
          aria-labelledby="faqModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {translate("Ən çox verilən suallar")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeFaqModal}
                ></button>
              </div>
              <div className="modal-body d-flex  justify-content-center">
                <div className="container">
                  <div className="row">
                    <div className="mt-4 mb-4 ">
                      <div
                        className="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingOne"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseOne"
                              aria-expanded="false"
                              aria-controls="flush-collapseOne"
                            >
                              <b>{translate("faq_1_title")}</b>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseOne"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              {translate("faq_1_desc")}
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingTwo"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseTwo"
                              aria-expanded="false"
                              aria-controls="flush-collapseTwo"
                            >
                              <b>{translate("faq_2_title")}</b>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingTwo"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              {translate("faq_2_desc")}
                            </div>
                          </div>
                        </div>
                        {/* <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingThree"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseThree"
                              aria-expanded="false"
                              aria-controls="flush-collapseThree"
                            >
                              <b>{translate("faq_3_title")}</b>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              {translate("faq_3_desc")}
                            </div>
                          </div>
                        </div> */}
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingFour"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseFour"
                              aria-expanded="false"
                              aria-controls="flush-collapseFour"
                            >
                              <b>{translate("faq_4_title")}</b>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingFour"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              {translate("faq_4_desc")}
                            </div>
                          </div>
                        </div>
                        {/* <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingFive"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseFive"
                              aria-expanded="false"
                              aria-controls="flush-collapseFive"
                              id="flush-collapseFive-btn"
                            >
                              <b>{translate("faq_5_title")}</b>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseFive"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingFive"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: translate("faq_5_desc"),
                                }}
                              />
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div
                  className="send-button scroll-to-section"
                  onClick={closeFaqModal}
                >
                  <a href="#" data-bs-dismiss="modal" className="active">
                    {" "}
                    {translate("close")}{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* lang modal */}
        <div
          className="modal fade"
          id="LangModal"
          tabIndex="-1"
          aria-labelledby="LangModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel"></h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body d-flex  justify-content-center">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-10 offset-lg-1 mt-4 mb-4 ">
                      <div className="bui-group__item">
                        <ul
                          className="lang-box"
                          style={
                            width < 600
                              ? {
                                  display: "flex",
                                  flexDirection: "column",
                                }
                              : {}
                          }
                        >
                          <li className="">
                            <a href="tel:+994125260919">
                              <div className="lang-item">
                                <div className="lang-img">
                                  <i className="fas fa-phone"></i>
                                </div>
                                <div>{"Azərbaycan"}</div>
                              </div>
                            </a>
                          </li>

                          <li className="">
                            <a href="tel:+994125260919">
                              <div className="lang-item">
                                <div className="lang-img">
                                  <i className="fas fa-phone"></i>
                                </div>
                                <div>{"հայերեն"}</div>
                              </div>
                            </a>
                          </li>
                          <li className="">
                            <a href="tel:+994125260919">
                              <div className="lang-item">
                                <div className="lang-img">
                                  <i className="fas fa-phone"></i>
                                </div>
                                <div>{"English"}</div>
                              </div>
                            </a>
                          </li>
                          <li className="">
                            <a href="tel:+994125260919">
                              <div className="lang-item">
                                <div className="lang-img">
                                  <i className="fas fa-phone"></i>
                                </div>
                                <div>{"Русский"}</div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {/* <div className="send-button scroll-to-section">
                  <a href="#" data-bs-dismiss="modal" className="active">
                    {" "}
                    {translate("close")}{" "}
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* tab info1 modal */}
        <div
          className="modal fade"
          id="tabModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {translate("Ətraflı məlumat")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body d-flex  justify-content-center">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-10 offset-lg-1 mt-4 mb-4 ">
                      <h4> </h4>
                      <p className="modal-content-p">
                        {translate("tab_info_1")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="send-button scroll-to-section">
                  <a href="#" data-bs-dismiss="modal" className="active">
                    {" "}
                    {translate("close")}{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* tab info2 modal */}
        <div
          className="modal fade"
          id="tabModal2"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {translate("Ətraflı məlumat")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body d-flex  justify-content-center">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-10 offset-lg-1 mt-4 mb-4 ">
                      <h4> </h4>

                      <p className="modal-content-p">
                        {translate("tab_info_2")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="send-button scroll-to-section">
                  <a href="#" data-bs-dismiss="modal" className="active">
                    {" "}
                    {translate("close")}{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* tab info3 modal */}
        <div
          className="modal fade"
          id="tabModal3"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {translate("Ətraflı məlumat")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body d-flex  justify-content-center">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-10 offset-lg-1 mt-4 mb-4 ">
                      <h4> </h4>

                      <p className="modal-content-p">
                        {translate("tab_info_3")}{" "}
                        <a
                          href="#"
                          data-bs-dismiss="modal"
                          onClick={openFaqLastSection}
                        >
                          {translate("more")}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="send-button scroll-to-section">
                  <a href="#" data-bs-dismiss="modal" className="active">
                    {" "}
                    {translate("close")}{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* tab info4 modal */}
        <div
          className="modal fade"
          id="tabModal4"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {translate("Ətraflı məlumat")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body d-flex  justify-content-center">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-10 offset-lg-1 mt-4 mb-4 ">
                      <h4> </h4>

                      <p className="modal-content-p">
                        {translate("app_check_status")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="send-button scroll-to-section">
                  <a href="#" data-bs-dismiss="modal" className="active">
                    {" "}
                    {translate("close")}{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {translate("Ətraflı məlumat")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body d-flex  justify-content-center">
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/40NEHojzadg"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="modal-footer">
                <div className="send-button scroll-to-section">
                  <a href="#" data-bs-dismiss="modal" className="active">
                    {" "}
                    {translate("close")}{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div>
        <div>
          <RegistrationHeader />

          {/* <SocialIcons /> */}

          <div
            className="main-banner  main-banner-page wow fadeIn"
            id="top"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  {width > 600 ? (
                    <>
                      <ul
                        className="nav nav-pills navtab"
                        id="pills-tab"
                        role="tablist"
                        style={{ display: width < 600 && "none" }}
                      >
                        <li
                          className="nav-item col-lg col-md-6 col-sm-12"
                          role="presentation"
                        >
                          <button
                            className="nav-link active"
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-home"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                            style={{ width: "100%" }}
                            onClick={() => setActiveTab("1")}
                          >
                            <div className="row tab1row tab-item-pro">
                              <div className="col-lg-12 d-flex  align-items-center justify-content-end mb-3"></div>
                              <div className="col-lg-12 d-flex  align-items-center tabicon mb-2">
                                <img
                                  src="assets/images/icon1.png"
                                  alt="Mig App"
                                  className="img-fluid"
                                />{" "}
                                <span>
                                  {" "}
                                  {translate("Reinteqrant qeydiyyatı")}{" "}
                                </span>
                              </div>
                            </div>
                          </button>
                        </li>
                        {/* <li
                          className="nav-item col-lg col-md-6 col-sm-12"
                          role="presentation"
                        >
                          <button
                            className="nav-link "
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-profile"
                            type="button"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                            style={{ width: "100%" }}
                            onClick={() => setActiveTab("2")}
                          >
                            <div className="row tab2row tab-item-pro">
                              <div className="col-lg-12 d-flex  align-items-center justify-content-end mb-3">
                                <div>
                                </div>
                              </div>
                              <div className="col-lg-12 d-flex  align-items-center justify-content-start tabicon mb-2">
                                <img
                                  src="assets/images/icon2.png"
                                  alt="Mig App"
                                />{" "}
                                <span>
                                  {" "}
                                  {translate("Vətəndaşlığın təsdiqi")}{" "}
                                </span>
                              </div>
                            </div>
                          </button>
                        </li>
                        <li
                          className="nav-item col-lg col-md-6 col-sm-12"
                          role="presentation"
                        >
                          <button
                            className="nav-link "
                            id="pills-live-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-live"
                            type="button"
                            role="tab"
                            aria-controls="pills-live"
                            aria-selected="false"
                            style={{ width: "100%" }}
                            onClick={() => setActiveTab("3")}
                          >
                            <div className="row tab3row tab-item-pro">
                              <div className="col-lg-12 d-flex  align-items-center justify-content-end mb-3">
                                <div>
                                </div>
                              </div>
                              <div className="col-lg-12 d-flex  align-items-center justify-content-start tabicon mb-2">
                                <img
                                  src="assets/images/icon3.png"
                                  alt="Mig App"
                                />{" "}
                                <span> {translate("Yaşama icazəsi")}</span>
                              </div>
                            </div>
                          </button>
                        </li> */}
                        <li
                          className="nav-item col-lg col-md-6 col-sm-12"
                          role="presentation"
                        >
                          <button
                            className="nav-link "
                            id="pills-contact-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-contact"
                            type="button"
                            role="tab"
                            aria-controls="pills-contact"
                            aria-selected="false"
                            style={{ width: "100%" }}
                            onClick={() => setActiveTab("4")}
                          >
                            <div className="row tab4row tab-item-pro">
                              <div className="col-lg-12 d-flex  align-items-center justify-content-end mb-3">
                                <div></div>
                              </div>
                              <div className="col-lg-12 d-flex  align-items-center justify-content-start tabicon mb-2">
                                <img
                                  src="assets/images/icon4.png"
                                  alt="Mig App"
                                />{" "}
                                <span>{translate("Müraciətin statusu")}</span>
                              </div>
                            </div>
                          </button>
                        </li>
                        <li
                          className="nav-item col-lg col-md-6 col-sm-12"
                          role="presentation"
                        >
                          <button
                            className="nav-link "
                            id="news-section-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#news-section"
                            type="button"
                            role="tab"
                            aria-controls="news-section"
                            aria-selected="false"
                            style={{ width: "100%" }}
                            onClick={() => setActiveTab("5")}
                          >
                            <div className="row tab4row tab-item-pro">
                              <div className="col-lg-12 d-flex  align-items-center justify-content-end mb-3">
                                <div></div>
                              </div>
                              <div className="col-lg-12 d-flex  align-items-center justify-content-start tabicon mb-2">
                                <img
                                  src="assets/images/news.png"
                                  alt="Mig App"
                                />{" "}
                                <span>{translate("news_info")}</span>
                              </div>
                            </div>
                          </button>
                        </li>
                      </ul>
                      <div
                        className="tab-content"
                        id="pills-tabContent"
                        style={{ display: width < 600 && "none" }}
                      >
                        <div
                          className="tab-pane fade show active "
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <div style={{ background: "#fff" }}>
                            <h5 className="text-center p-4">
                              {translate("tab_name_1_desc")}
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#tabModal1"
                                className="tinfo float-end"
                              >
                                <img src={infoIcon} alt="Mig App" />
                              </a>
                            </h5>
                          </div>
                          {activeTab === "1" && <ReintegrationAppForm />}
                        </div>
                        <div
                          className="tab-pane fade "
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <div style={{ background: "#fff" }}>
                            <h5 className="text-center p-4">
                              {translate("tab_name_2_desc")}
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#tabModal2"
                                className="tinfo float-end"
                              >
                                <img src={infoIcon} alt="Mig App" />
                              </a>
                            </h5>
                          </div>
                          {activeTab === "2" && <CitizenshipAppForm />}
                        </div>
                        <div
                          className="tab-pane fade "
                          id="pills-live"
                          role="tabpanel"
                          aria-labelledby="pills-live-tab"
                        >
                          <div style={{ background: "#fff" }}>
                            <h5 className="text-center p-4">
                              {translate("tab_name_3_desc")}
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#tabModal3"
                                className="tinfo float-end"
                              >
                                <img src={infoIcon} alt="Mig App" />
                              </a>
                            </h5>
                          </div>
                          {activeTab === "3" && <ResidenceAppForm />}
                        </div>
                        <div
                          className="tab-pane fade "
                          id="pills-contact"
                          role="tabpanel"
                          aria-labelledby="pills-contact-tab"
                        >
                          <div style={{ background: "#fff" }}>
                            <h5 className="text-center p-4">
                              {translate("tab_name_4_desc")}
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#tabModal4"
                                className="tinfo float-end"
                              >
                                <img src={infoIcon} alt="Mig App" />
                              </a>
                            </h5>
                          </div>
                          {activeTab === "4" && <RegistrationStatusForm />}
                        </div>
                        <div
                          className="tab-pane fade "
                          id="news-section"
                          role="tabpanel"
                          aria-labelledby="news-section-tab"
                        >
                          <div style={{ background: "#fff" }}>
                            <h5 className="text-center p-4">
                              {translate("news_info_desc")}
                            </h5>
                          </div>
                          <div id="news-section-content">
                            {activeTab === "5" && <NewsComponent />}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="accordion"
                        id="accordionTab"
                        style={{ display: width > 600 && "none" }}
                      >
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="false"
                              aria-controls="collapseOne"
                              onClick={() => setActiveTab("1")}
                            >
                              <div className="row tab1row tab-item-pro">
                                <div className="col-lg-12 d-flex  align-items-center justify-content-end mb-3">
                                  <div>
                                    {/* <a
                                      href="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#tabModal1"
                                      className="tinfo"
                                    >
                                      <img src={infoIcon} alt="Mig App" />
                                    </a> */}
                                  </div>
                                </div>
                                <div className="col-lg-12 d-flex  align-items-center tabicon mb-2">
                                  <img
                                    src="assets/images/icon1.png"
                                    alt="Mig App"
                                  />{" "}
                                  <span>
                                    {" "}
                                    {translate("Reinteqrant qeydiyyatı")}{" "}
                                  </span>
                                </div>
                              </div>
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionTab"
                          >
                            <div className="accordion-body">
                              <div style={{ background: "#fff" }}>
                                <h5 className="text-center p-4">
                                  {translate("tab_name_1_desc")}
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#tabModal1"
                                    className="tinfo float-end"
                                  >
                                    <img src={infoIcon} alt="Mig App" />
                                  </a>
                                </h5>
                              </div>
                              {activeTab === "1" && <ReintegrationAppForm />}
                            </div>
                          </div>
                        </div>
                        {/* <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                              onClick={() => setActiveTab("2")}
                            >
                              <div className="row tab1row tab-item-pro">
                                <div className="col-lg-12 d-flex  align-items-center justify-content-end mb-3">
                                  <div>
                                  </div>
                                </div>
                                <div className="col-lg-12 d-flex  align-items-center justify-content-start tabicon mb-2">
                                  <img
                                    src="assets/images/icon2.png"
                                    alt="Mig App"
                                  />{" "}
                                  <span>
                                    {" "}
                                    {translate("Vətəndaşlığın təsdiqi")}{" "}
                                  </span>
                                </div>
                              </div>
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionTab"
                          >
                            <div className="accordion-body">
                              <div style={{ background: "#fff" }}>
                                <h5 className="text-center p-4">
                                  {translate("tab_name_2_desc")}
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#tabModal2"
                                    className="tinfo float-end"
                                  >
                                    <img src={infoIcon} alt="Mig App" />
                                  </a>
                                </h5>
                              </div>
                              {activeTab === "2" && <CitizenshipAppForm />}
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                              onClick={() => setActiveTab("3")}
                            >
                              <div className="row tab1row tab-item-pro">
                                <div className="col-lg-12 d-flex  align-items-center justify-content-end mb-3">
                                  <div>
                                  </div>
                                </div>
                                <div className="col-lg-12 d-flex  align-items-center justify-content-start tabicon mb-2">
                                  <img
                                    src="assets/images/icon3.png"
                                    alt="Mig App"
                                  />{" "}
                                  <span> {translate("Yaşama icazəsi")}</span>
                                </div>
                              </div>
                            </button>
                          </h2>
                          <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionTab"
                          >
                            <div className="accordion-body">
                              <div style={{ background: "#fff" }}>
                                <h5 className="text-center p-4">
                                  {translate("tab_name_3_desc")}
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#tabModal3"
                                    className="tinfo float-end"
                                  >
                                    <img src={infoIcon} alt="Mig App" />
                                  </a>
                                </h5>
                              </div>
                              {activeTab === "3" && <ResidenceAppForm />}
                            </div>
                          </div>
                        </div> */}
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                              onClick={() => setActiveTab("4")}
                            >
                              <div className="row tab1row tab-item-pro">
                                <div className="col-lg-12 d-flex  align-items-center justify-content-end mb-3">
                                  <div>
                                    {/* <a
                                      href="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#tabModal1"
                                      className="tinfo"
                                    >
                                      <img src={infoIcon} alt="Mig App" />
                                    </a> */}
                                  </div>
                                </div>
                                <div className="col-lg-12 d-flex  align-items-center justify-content-start tabicon mb-2">
                                  <img
                                    src="assets/images/icon4.png"
                                    alt="Mig App"
                                  />{" "}
                                  <span>
                                    {" "}
                                    {translate("Müraciətin statusu")}
                                  </span>
                                </div>
                              </div>
                            </button>
                          </h2>
                          <div
                            id="collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionTab"
                          >
                            <div className="accordion-body">
                              <div style={{ background: "#fff" }}>
                                <h5 className="text-center p-4">
                                  {translate("tab_name_4_desc")}
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#tabModal4"
                                    className="tinfo float-end"
                                  >
                                    <img src={infoIcon} alt="Mig App" />
                                  </a>
                                </h5>
                              </div>
                              {activeTab === "4" && <RegistrationStatusForm />}
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingFive">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFive"
                              aria-expanded="false"
                              aria-controls="collapseFive"
                              onClick={() => setActiveTab("5")}
                            >
                              <div className="row tab1row tab-item-pro">
                                <div className="col-lg-12 d-flex  align-items-center justify-content-end mb-3">
                                  <div>
                                    {/* <a
                                      href="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#tabModal1"
                                      className="tinfo"
                                    >
                                      <img src={infoIcon} alt="Mig App" />
                                    </a> */}
                                  </div>
                                </div>
                                <div className="col-lg-12 d-flex  align-items-center justify-content-start tabicon mb-2">
                                  <img
                                    src="assets/images/news.png"
                                    alt="Mig App"
                                  />{" "}
                                  <span> {translate("news_info")}</span>
                                </div>
                              </div>
                            </button>
                          </h2>
                          <div
                            id="collapseFive"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFive"
                            data-bs-parent="#accordionTab"
                          >
                            <div className="accordion-body">
                              <div style={{ background: "#fff" }}>
                                <h5 className="text-center p-4">
                                  {translate("news_info_desc")}
                                  {/* <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#tabModal5"
                                    className="tinfo float-end"
                                  >
                                    <img src={infoIcon} alt="Mig App" />
                                  </a> */}
                                </h5>
                              </div>
                              {activeTab === "5" && <NewsComponent />}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <RegistrationFooter />

        {/* <div
          id="lz_overlay_wm"
          style={{
            display: "block",
            position: "fixed",
            width: 57,
            margin: "0px 40px 30px 0px",
            inset: "auto 0px 0px auto",
            zIndex: 20000000,
            height: 57,
          }}
        >
          <div
            id="livezilla_wm"
            className="lz_chat_unselectable lz_overlay_wm_button lz_overlay_wm_sh_act lz_anim_slide_in"
            style={{
              background: "rgb(101, 138, 179)",
              height: 57,
              width: 57,
              zIndex: 20000200,
              borderColor: "rgb(81, 121, 164)",
              borderRadius: "50%",
              right: 0,
              position: "relative",
              display: "block",
              top: 0,
              visibility: "visible",
            }}
            data-tid="wm"
          >
            <svg
              className="lz_chat_unselectable lz_overlay_wm_icon"
              width="57"
              height="57"
              style={{
                width: "57px !important",
                height: "57px !important",
                fill: "white",
              }}
              viewBox="-15 -13.799999999999999 57 57"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                transform="scale(.015)"
                style={{
                  transform: "scale(0.015)",
                  color: "aqua",
                }}
                d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"
              ></path>
            </svg>
          </div>
        </div> */}

        {renderModals()}
      </div>
      {/* <ChatWidget page="registration" /> */}
    </Fragment>
  );
}

export default Registration;
