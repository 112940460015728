import { createSlice } from "@reduxjs/toolkit";
import { storageKeys } from "../utils/constants";
import { getCurrentLang } from "../services/translation/translation.service";

const initialState = {
  globalLang: getCurrentLang(),
  isWidgetActive: false,
  loading: false,
  roomCreated: false,
  createRoomRequested: false,
  showThanksMessage: false,
  messages: [],
  botMessages: [],
  unSentMessages: [],
  requireForm: true,
  chatId: undefined,
  currentUser: undefined,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setGlobalLang: (state, action) => {
      state.globalLang = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setBotMessages: (state, action) => {
      state.botMessages = action.payload;
    },
    setUnSentMessages: (state, action) => {
      state.unSentMessages = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setRoomCreated: (state, action) => {
      state.roomCreated = action.payload;
    },
    setCreateRoomRequested: (state, action) => {
      state.createRoomRequested = action.payload;
    },
    setShowThanksMessage: (state, action) => {
      state.showThanksMessage = action.payload;
    },
    setRequireForm: (state, action) => {
      state.requireForm = action.payload;
    },
    setChatId: (state, action) => {
      state.chatId = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setIsWidgetActive: (state, action) => {
      state.isWidgetActive = action.payload;
    },
    addMessageToList: (state, action) => {
      const msg = action.payload;
      if (msg?.text) {
        state.messages.push(msg);
      }
      state.chatId = msg?.chatId;

      localStorage.setItem(
        storageKeys.CHAT_MESSAGES_KEY,
        JSON.stringify({
          messages: state.messages,
          user: state.currentUser,
          chatId: msg?.chatId,
          time: Date.now(),
        })
      );
    },
  },
});

export const {
  setMessages,
  setBotMessages,
  setUnSentMessages,
  setLoading,
  setRoomCreated,
  setCreateRoomRequested,
  setShowThanksMessage,
  setRequireForm,
  setChatId,
  setCurrentUser,
  setIsWidgetActive,
  addMessageToList,
  setGlobalLang,
} = chatSlice.actions;

export default chatSlice.reducer;
