import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
// import ChatWidget from "../components/ChatWidget/ChatWidget";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { sendVideoConferenceApp } from "../services/api.service";
import {
  translate,
  translateWithCustomLang,
  use,
} from "../services/translation/translation.service";
import { setGlobalLang } from "../store/chatStore";
import useWindowDarkMode from "../hooks/useWindowDarkMode";

import phoneIconDark from "../assets/img/phone.png";
import phoneIconWhite from "../assets/img/phone-icon-white.png";
import youtubeLogo from "../assets/img/youtube-logo-transparetn.png";

// import twitterIconDark from "../assets/img/x-twitter.svg";
// import twitterIconWhite from "../assets/img/twitter-x-white.webp";

function Home() {
  const darkMode = useWindowDarkMode();
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const [appForm, setAppForm] = useState("");

  useEffect(() => {
    if (darkMode) {
      const htmlList = document.getElementsByClassName("box-left");
      if (htmlList) {
        const arrayList = [...htmlList];
        arrayList.forEach((element) => {
          element.classList.add("custom-dark-style");
        });
      }
    }

    const menuTrigEl = document.getElementById("mobMenu");
    if (menuTrigEl) {
      menuTrigEl.classList.add("home-menu-trigger-style");
      menuTrigEl.classList.remove("reg-menu-trigger-style");
    }
  }, []);

  const toggle = () => setModal(!modal);

  const toggleVideoModal = () => {
    if (videoModal) {
      var iframe = document.getElementById("video-yt");
      iframe.src = iframe.src;
    }
    setVideoModal(!videoModal);
  };

  const sendVideoApp = async () => {
    try {
      setLoading(true);
      console.log("appForm", appForm);
      const res = await sendVideoConferenceApp(appForm);
      console.log("sendVideoConferenceApp res", res);
      toast(translate("Müraciətiniz qeydə alındı"));
      toggle();
      setLoading(false);
    } catch (error) {
      toast.error(translate("an_error_occurred"));
      setLoading(false);
      console.log("sendVideoApp error", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAppForm({ ...appForm, [name]: value });
  };

  const handleMenu = () => {
    let el1 = document.getElementById("mobMenu");
    let el2 = document.getElementById("mnav");

    if (el1 && el2 && el1.classList.contains("active")) {
      el1.classList.remove("active");
      el2.style.display = "none";
      // el2.style.background = "transparent";
    } else {
      el1.classList.add("active");
      el2.style.display = "flex";
      // el2.style.background = "#fff";
    }
  };

  function changeLang(lang) {
    use(lang);
    dispatch(setGlobalLang(lang));
  }

  return (
    <Fragment>
      <div>
        {/* <!-- ***** Sosial Share Start ***** --> */}
        {/* <ul
          id="slbuttons"
          style={
            width < 600
              ? {
                  bottom: 25,
                  display: "flex",
                  transform: "unset",
                  left: "unset",
                  top: "unset",
                  zIndex: 50,
                }
              : {}
          }
        >
          <li
            className="white-bg"
            style={
              width < 600
                ? {
                    minWidth: 45,
                    margin: 2,
                  }
                : {}
            }
          >
            <i className="fab fa-facebook-f fb-color"></i>
          </li>
          <li
            className="white-bg"
            style={
              width < 600
                ? {
                    minWidth: 45,
                    margin: 2,
                  }
                : {}
            }
          >
            <img
              src={darkMode ? twitterIconWhite : twitterIconDark}
              alt="x-twitter"
              style={{ width: 20 }}
            />
          </li>
          <li
            className="white-bg"
            style={
              width < 600
                ? {
                    minWidth: 45,
                    margin: 2,
                  }
                : {}
            }
          >
            <i className="fab fa-youtube ytb-color"></i>
          </li>
        </ul> */}

        <Modal isOpen={modal} toggle={toggle} backdrop="static">
          <ModalHeader toggle={toggle}>
            {translate("video_app_title")}
          </ModalHeader>
          <ModalBody>
            <div className="container">
              {loading && <progress style={{ width: "100%" }} />}
              <Form>
                <div className="row">
                  <div className="col-6">
                    <FormGroup>
                      <Label>{translate("firstName")}</Label>
                      <Input
                        name="firstName"
                        placeholder=""
                        type="text"
                        onChange={handleChange}
                        value={appForm?.firstName || ""}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>{translate("email")}</Label>
                      <Input
                        name="email"
                        placeholder=""
                        type="email"
                        onChange={handleChange}
                        value={appForm?.email || ""}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-6">
                    <FormGroup>
                      <Label>{translate("lastName")}</Label>
                      <Input
                        name="lastName"
                        placeholder=""
                        type="text"
                        onChange={handleChange}
                        value={appForm?.lastName || ""}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>{translate("lang")}</Label>
                      <Input
                        name="applicationLanguage"
                        type="select"
                        onChange={handleChange}
                        value={appForm?.applicationLanguage || ""}
                      >
                        <option value={"az"}>{translate("azerbaijan")}</option>
                        <option value={"en"}>{translate("english")}</option>
                        <option value={"ru"}>{translate("russian")}</option>
                        <option value={"am"}>{translate("armenian")}</option>
                      </Input>
                    </FormGroup>
                  </div>
                </div>
              </Form>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={sendVideoApp} disabled={loading}>
              {translate("send")}
            </Button>{" "}
            <Button color="secondary" onClick={toggle}>
              {translate("close")}
            </Button>
          </ModalFooter>
        </Modal>

        {/* <!-- Modal Video Lang--> */}
        <div
          className="modal fade"
          id="LangModal"
          tabIndex="-1"
          aria-labelledby="LangModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title" id="exampleModalLabel"></div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body d-flex  justify-content-center">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-10 offset-lg-1 mt-4 mb-4 ">
                      <div className="bui-group__item">
                        <ul
                          className="lang-box"
                          style={
                            width < 600
                              ? {
                                  display: "flex",
                                  flexDirection: "column",
                                }
                              : {}
                          }
                        >
                          <li className="">
                            <a href="tel:+994125260919">
                              <div className="lang-item">
                                <div className="lang-img">
                                  <i className="fas fa-phone"></i>
                                </div>
                                <div>{"Azərbaycan"}</div>
                              </div>
                            </a>
                          </li>

                          <li className="">
                            <a href="tel:+994125260919">
                              <div className="lang-item">
                                <div className="lang-img">
                                  <i className="fas fa-phone"></i>
                                </div>
                                <div>{"հայերեն"}</div>
                              </div>
                            </a>
                          </li>
                          <li className="">
                            <a href="tel:+994125260919">
                              <div className="lang-item">
                                <div className="lang-img">
                                  <i className="fas fa-phone"></i>
                                </div>
                                <div>{"English"}</div>
                              </div>
                            </a>
                          </li>
                          <li className="">
                            <a href="tel:+994125260919">
                              <div className="lang-item">
                                <div className="lang-img">
                                  <i className="fas fa-phone"></i>
                                </div>
                                <div>{"Русский"}</div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {/* <div className="send-button scroll-to-section">
                  <a href="#" data-bs-dismiss="modal" className="active">
                    {" "}
                    {translate("close")}{" "}
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Modal --> */}
        <Modal isOpen={videoModal} toggle={toggleVideoModal} size="lg">
          <ModalHeader toggle={toggleVideoModal}></ModalHeader>
          <ModalBody>
            <div className="container">
              <iframe
                id="video-yt"
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/40NEHojzadg"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </ModalBody>
          <ModalFooter>
            {/* <Button color="secondary" onClick={toggleVideoModal}>
              {translate("close")}
            </Button> */}
          </ModalFooter>
        </Modal>
        {/* <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {translate("Ətraflı məlumat")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body d-flex  justify-content-center">
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/40NEHojzadg"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="modal-footer">
                <div className="send-button scroll-to-section">
                  <a href="#" data-bs-dismiss="modal" className="active">
                    {" "}
                    {translate("close")}{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <!-- ***** Header Area Start ***** --> */}
        <header
          className="header-area header-sticky wow slideInDown"
          data-wow-duration="0.75s"
          data-wow-delay="0s"
          style={darkMode ? { background: "#232223" } : {}}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <nav className="main-nav">
                  {/* <!-- ***** Logo Start ***** --> */}
                  <a href="/" className="logo intro">
                    <img src="assets/images/gerb.svg" alt="Mig App" />
                    <div className="content__container">
                      <div className="changing-keywords" id="change">
                        <strong>
                          <b className="hidden">
                            <span>
                              {translateWithCustomLang("home_logo_title", "az")}
                            </span>
                          </b>
                          <br />
                          <b className="hidden">
                            <span>
                              {translateWithCustomLang("home_logo_title", "am")}
                            </span>
                          </b>
                          <br />
                          <b className="hidden">
                            <span>
                              {translateWithCustomLang("home_logo_title", "en")}
                            </span>
                          </b>
                          <br />
                          <b className="hidden">
                            <span>
                              {translateWithCustomLang("home_logo_title", "ru")}
                            </span>
                          </b>
                        </strong>
                      </div>
                    </div>
                    <img
                      src="assets/images/logo.png"
                      alt="Mig App"
                      className="mig-logo"
                    />
                  </a>

                  {/* <!-- ***** Logo End ***** -->
            <!-- ***** Menu Start ***** --> */}
                  <ul
                    className="nav topbuttons"
                    id="mnav"
                    style={
                      darkMode
                        ? { background: "#232223" }
                        : { background: width > 992 ? "transparent" : "#fff" }
                    }
                  >
                    <li style={darkMode ? { background: "#232223" } : {}}>
                      <a
                        href="tel:+994125260919"
                        // data-bs-toggle="modal"
                        // data-bs-target="#LangModal"
                        // className=""
                      >
                        <img
                          src={darkMode ? phoneIconWhite : phoneIconDark}
                          alt="Mig App"
                          style={{
                            width: darkMode ? 30 : 39,
                            marginRight: 10,
                            marginTop: -5,
                          }}
                        />
                      </a>
                    </li>
                    {/* <li>
                      <a
                        className="head-buttons video-button"
                        target={"_blank"}
                        // onClick={toggle}
                      >
                        <i className="fa fa-video"></i>
                      </a>
                    </li> */}
                    <li style={darkMode ? { background: "#232223" } : {}}>
                      <a
                        href="https://wa.me/+994705270919"
                        className="head-buttons whatsapp-button"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <i className="fab fa-whatsapp"></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href="#" className="head-buttons eye-button">
                        <i className="fa fa-eye"></i>
                      </a>
                    </li> */}
                  </ul>
                  <a
                    id="mobMenu"
                    className="menu-trigger"
                    onClick={handleMenu}
                    style={darkMode ? { background: "#fff" } : {}}
                  >
                    <span>{translate("Menu")}</span>
                  </a>
                  {/* <!-- ***** Menu End ***** --> */}
                </nav>
              </div>
            </div>
          </div>
        </header>
        {/* <!-- ***** Header Area End ***** --> */}

        <div id="clients" className="the-clients ">
          <div className="container">
            <div className="row align-items-center justify-content-end">
              <div className="col-lg-5 d-flex  align-items-center justify-content-end green fst-italic">
                {/* <!-- <h5>Sülh, davamlı inkişaf və birgəyaşayış üçün Reinteqrasiya</h5>--> */}
                <div className="content1">
                  <div className="content__container">
                    <div className="changing-keywords" id="change">
                      <strong>
                        <b className="hidden green text-end">
                          <span>
                            {translateWithCustomLang("home_slogan_title", "az")}
                          </span>
                        </b>
                        <br />
                        <b className="hidden green text-end">
                          <span>
                            {translateWithCustomLang("home_slogan_title", "am")}
                          </span>
                        </b>
                        <br />
                        <b className="hidden green text-end">
                          <span>
                            {translateWithCustomLang("home_slogan_title", "en")}
                          </span>
                        </b>
                        <br />
                        <b className="hidden green text-end">
                          <span>
                            {translateWithCustomLang("home_slogan_title", "ru")}
                          </span>
                        </b>
                      </strong>
                    </div>
                    {/* <ul className="content__container__list">
                      <li className="content__container__list__item">
                        <h5>
                          Regionda sülh, davamlı inkişaf və birgəyaşayış üçün
                          Reinteqrasiya
                        </h5>
                      </li>
                      <li className="content__container__list__item">
                        <h5>
                          Վերաինտեգրում հանուն խաղաղության, կայուն զարգացման և
                          համակեցության
                        </h5>
                      </li>
                      <li className="content__container__list__item">
                        <h5>
                          Reintegration for peace, sustainable development and
                          coexistence
                        </h5>
                      </li>
                      <li className="content__container__list__item">
                        <h5>
                          Реинтеграция ради мира, устойчивого развития и
                          сосуществования
                        </h5>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="main-banner wow fadeIn"
          id="top"
          data-wow-duration="1s"
          data-wow-delay="0.5s"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6 align-self-center">
                    <div
                      className="left-content show-up header-text wow fadeInLeft"
                      data-wow-duration="1s"
                      data-wow-delay="1s"
                    >
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row showp">
                            <div className="col-lg-12">
                              <div
                                className="box-left"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <h5
                                  onClick={() => changeLang("az")}
                                  style={{ color: darkMode ? "#fff" : "#000" }}
                                >
                                  <Link
                                    style={
                                      darkMode
                                        ? {
                                            color: "#fff",
                                          }
                                        : {}
                                    }
                                    to={"/registration"}
                                  >
                                    {translateWithCustomLang(
                                      "home_menu_title",
                                      "az"
                                    )}
                                  </Link>
                                </h5>
                                <div
                                  className="head-buttons"
                                  style={{ width: 60 }}
                                >
                                  {/* <i className="fa fa-play"></i> */}
                                  <a
                                    href="https://www.youtube.com/watch?v=U8jP-yXclxg"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      className="youtube-logo"
                                      src={youtubeLogo}
                                      alt="youtubeLogo"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-lg-4 col-4">
                              <div className="box-right">
                                <a
                                  href="#"
                                  onClick={toggleVideoModal}
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#exampleModal"
                                >
                                  <div className="head-buttons youtube-button">
                                    <i className="fa fa-play"></i>
                                  </div>
                                </a>
                              </div>
                            </div> */}
                            <span>
                              {translateWithCustomLang("home_menu_desc", "az")}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="row showp">
                            <div className="col-lg-12">
                              <div
                                className="box-left"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <h5 onClick={() => changeLang("am")}>
                                  <Link
                                    to={"/registration"}
                                    style={
                                      darkMode
                                        ? {
                                            color: "#fff",
                                          }
                                        : {}
                                    }
                                  >
                                    {translateWithCustomLang(
                                      "home_menu_title",
                                      "am"
                                    )}
                                  </Link>
                                </h5>
                                <div
                                  className="head-buttons"
                                  style={{ width: 60 }}
                                >
                                  {/* <i className="fa fa-play"></i> */}
                                  <a
                                    href="https://www.youtube.com/watch?v=U4u9n6MieJE"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      className="youtube-logo"
                                      src={youtubeLogo}
                                      alt="youtubeLogo"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-lg-4 col-4">
                              <div className="box-right">
                                <a
                                  href="#"
                                  onClick={toggleVideoModal}
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#exampleModal"
                                >
                                  <div className="head-buttons youtube-button">
                                    <i className="fa fa-play"></i>
                                  </div>
                                </a>
                              </div>
                            </div> */}
                            <span>
                              {translateWithCustomLang("home_menu_desc", "am")}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="row showp">
                            <div className="col-lg-12">
                              <div
                                className="box-left"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <h5 onClick={() => changeLang("en")}>
                                  <Link
                                    to={"/registration"}
                                    style={
                                      darkMode
                                        ? {
                                            color: "#fff",
                                          }
                                        : {}
                                    }
                                  >
                                    {translateWithCustomLang(
                                      "home_menu_title",
                                      "en"
                                    )}
                                  </Link>
                                </h5>
                                <div
                                  className="head-buttons"
                                  style={{ width: 60 }}
                                >
                                  {/* <i className="fa fa-play"></i> */}
                                  <a
                                    href="https://www.youtube.com/watch?v=hf6ssx5z8lw"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      className="youtube-logo"
                                      src={youtubeLogo}
                                      alt="youtubeLogo"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-lg-4 col-4">
                              <div className="box-right">
                                <a
                                  href="#"
                                  onClick={toggleVideoModal}
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#exampleModal"
                                >
                                  <div className="head-buttons youtube-button">
                                    <a href="#">
                                      <i className="fa fa-play"></i>
                                    </a>
                                  </div>
                                </a>
                              </div>
                            </div> */}
                            <span>
                              {translateWithCustomLang("home_menu_desc", "en")}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="row showp">
                            <div className="col-lg-12">
                              <div
                                className="box-left"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <h5 onClick={() => changeLang("ru")}>
                                  <Link
                                    to={"/registration"}
                                    style={
                                      darkMode
                                        ? {
                                            color: "#fff",
                                          }
                                        : {}
                                    }
                                  >
                                    {translateWithCustomLang(
                                      "home_menu_title",
                                      "ru"
                                    )}
                                  </Link>
                                </h5>
                                <div
                                  className="head-buttons"
                                  style={{ width: 60 }}
                                >
                                  {/* <i className="fa fa-play"></i> */}
                                  <a
                                    href="https://www.youtube.com/watch?v=fc7wYc9EC-0&t=1s"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      className="youtube-logo"
                                      src={youtubeLogo}
                                      alt="youtubeLogo"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-lg-4 col-4">
                              <div className="box-right">
                                <a
                                  href="#"
                                  onClick={toggleVideoModal}
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#exampleModal"
                                >
                                  <div className="head-buttons youtube-button">
                                    <a href="#">
                                      <i className="fa fa-play"></i>
                                    </a>
                                  </div>
                                </a>
                              </div>
                            </div> */}
                            <span>
                              {translateWithCustomLang("home_menu_desc", "ru")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="right-image wow fadeInRight"
                      data-wow-duration="1s"
                      data-wow-delay="0.5s"
                    >
                      <img
                        src="assets/images/slider-dec.png"
                        alt=""
                        className="img-back"
                      />
                      <img
                        src="assets/images/slider-dec.png"
                        alt=""
                        className="img-top"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div id="lz_overlay_wm" style="display: block; position: fixed; width: 57px; margin: 0px 40px 30px 0px; inset: auto 0px 0px auto; z-index: 20000000; height: 57px;">


<div id="livezilla_wm" className="lz_chat_unselectable lz_overlay_wm_button lz_overlay_wm_sh_act lz_anim_slide_in" style="background: rgb(101, 138, 179); height: 57px; width: 57px; z-index: 20000200; border-color: rgb(81, 121, 164); border-radius: 50%; right: 0px; position: relative; display: block; top: 0px; visibility: visible;" data-tid="wm">
    <svg className="lz_chat_unselectable lz_overlay_wm_icon" width="57" height="57" style="width:57px !important;height:57px !important;fill: white;" viewBox="-15 -13.799999999999999 57 57" xmlns="http://www.w3.org/2000/svg">
        <path transform="scale(.015)" style="transform: scale(0.015);color: aqua;" d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"></path></svg>
</div>
</div> */}
      </div>
      {/* <ChatWidget page="home" /> */}
    </Fragment>
  );
}

export default Home;
