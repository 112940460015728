import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import registrationReducer from "./slices/registrationSlice";
import chatReducer from "./chatStore";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    registration: registrationReducer,
    chat: chatReducer,
  },
});
