// import {
//   isPossiblePhoneNumber,
//   isValidPhoneNumber,
// } from "react-phone-number-input";
import countryListMulti from "../assets/countries.json";
import { getCurrentLang } from "../services/translation/translation.service";

export const validateEmail = (email) => {
  let regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let result = regex.exec(email);
  return result;
};

export const validateNumber = (phone) => {
  let regex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
  let result = regex.test(phone);
  return result;
};

// export const validatePhone = (value) => {
//   if (value && isPossiblePhoneNumber(value) && isValidPhoneNumber(value)) {
//     return true;
//   } else {
//     return false;
//   }
// };

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const getCountryNameByLang = (countryId) => {
  return countryListMulti.find((x) => x.COUNTRY_ID == countryId)[
    `NAME_${getCurrentLang().toUpperCase()}`
  ];
};
